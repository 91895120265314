export const state = {
  info: null,
  alerts: [],
  member: {},
  settings: {
    timeFormat: '24',
    language: 'english',
    themeDark: false,
    drawer: true,
    mini: false,
    tz: null,
    location: {
      lat: null,
      lng: null,
    },
    projects: {
      filtersShown: false,
      listView: true
    }
  }
}
