import { API, HOST } from '@/utils/http'
import firebase from 'firebase/compat/app'

export const setUser = ({commit}, user) => commit('SET_USER', user)

export const setMember = ({commit}, member) => commit('SET_MEMBER', member)

export const setLanguage = ({commit}, lang) => commit('SET_LANGUAGE', lang)

export const setMainMenuDrawer = ({commit}, isMini) => commit('SET_MAIN_MENU_DRAWER', isMini)

export const changeTheme = ({commit}, isDark) => commit('SET_THEME_COLOR', isDark)

export const setProjectsFilters = ({commit}, showFilters) => commit('SET_PROJECTS_FILTERS', showFilters)

export const updateAvatar = async ({commit}, payload) => {
  try {
    const response = await API({
      headers: {'content-type': 'multipart/form-data'},
      method: 'PATCH',
      url: `${HOST}/member/update-avatar`,
      data: {
        file: payload.file
      }
    })

    commit('SET_MEMBER', response.data)

    commit('SET_ALERT', {type: 'success', text: `Avatar updated`}, {root: true})
  } catch (e) {
    throw new Error("Can't update image. " + e)
  }
}

export const logoutFirebase = async ({commit}) => {
  try {
    await firebase.auth().signOut()
    commit('SET_USER', null)
  } catch (e) {
    throw new Error("Can't logout. " + e)
  }
}

export const update = async ({ commit }, payload) => {
  try {
    const response = await API({
      method: 'PATCH',
      url: `${HOST}/member/update`,
      data: {
        display_name: payload.displayName
      }
    })

    commit('SET_MEMBER', response.data)
    commit('SET_ALERT', {type: 'success', text: `User profile updated`}, {root: true})
  } catch (e) {
    throw new Error("Can't update member. " + e)
  }
}


export const setupSettings = ({ commit, state }) => {
  const storageSettings = localStorage.getItem('settings')
  if (storageSettings) {
    commit('SET_SETTINGS', JSON.parse(storageSettings))
  } else {
    const getLocation = () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (data) => resolve(data),
          () => reject(null)
        )
      })
    }

    getLocation()
      .then((data) => commit('SET_LOCATION', data))
      .catch(() => commit('SET_LOCATION', null))


    if (Intl) {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
      commit('SET_TZ', tz)
    }
  }

}

export const saveSettings = ({ state }) => {
  localStorage.setItem('settings', JSON.stringify(state.settings))
}

export const updateLang = ({ commit }, payload) => {
  commit('SET_LANG', payload.language)
}

export const updateTz = ({ commit }, payload) => {
  commit('SET_TZ', payload.tz)
}

export const updateTimeFormat = ({ commit }, payload) => {
  commit('SET_TIME_FORMAT', payload.timeFormat)
}
