import {createStore} from 'vuex'

import { state } from '@/vuex/state'
import { getters } from '@/vuex/getters'
import * as actions from '@/vuex/actions'
import { mutations } from '@/vuex/mutations'

import geo from './geo'
import user from './user'
import project from './project'
import org from './org'
import planet from './planet'
import billing from './billing'
import search from './search'

const store = createStore({
  modules: {
    user,
    project,
    geo,
    org,
    planet,
    billing,
    search
  },
  state,
  actions,
  mutations,
  getters,
  plugins: []
})


export default store
