export const mutations = {
  SET_ORGS(state, orgs) {
    state.orgs = orgs
  },
  SET_SELECTED_ORG(state, org) {
    state.selectedOrg = org
  },
  ADD_ORG(state, org) {
    state.orgs.push(org)
  },
  SET_CURRENT (state, current) {
    if (state.orgs.length === 1) {
      current = state.orgs[0]
    }
    state.current = current
  },
  SET_CURRENT_MEMBERS (state, members) {
    state.currentMembers = members
  },
  REMOVE_MEMBER (state, orgMemberId) {
    state.currentMembers = state.currentMembers.filter(m => m.organization_member_id !== orgMemberId)
  },
  UPDATE_MEMBER (state, member) {
    const index = state.currentMembers.findIndex(m => m.organization_member_id === member.organization_member_id)
    state.currentMembers.splice(index, 1, member)
  },
  SET_CURRENT_INVITES (state, invites) {
    state.currentInvites = invites
  },
  ADD_INVITE(state, invite) {
    state.currentInvites.push(invite)
  },

  RESET_CURRENT (state) {
    state.current = null
    state.currentMembers = []
    state.currentInvites = []
  },
  SET_APIKEYS (state, payload) {
    state.apikeys = payload
  },
  REMOVE_APIKEY (state, payload) {
    state.apikeys = state.apikeys.filter(a => a.uuid !== payload)
  },
  ADD_APIKEY (state, payload) {
    state.apikeys.push(payload)
  }
}
