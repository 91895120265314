<script setup>
import {watch, ref, computed, onBeforeMount, onBeforeUnmount} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'

import TemplatesList from './TemplatesList.vue'
import {Button} from '@/components/ui/button'
import {PlusCircle} from 'lucide-vue-next'
import CreateTemplateDialog from '@/pages/Project/Templates/CreateTemplateDialog.vue'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'

const route = useRoute()
const projectId = route.params.project_id

const store = useStore()
const project = computed(() => store.getters['project/current'])
const templates = computed(() => store.getters['geo/getProjectReportTemplates'])
const templatesLength = computed(() => templates.value ? templates.value.length : 0)
const changeKey = ref(0)

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId)
  await store.dispatch('geo/fetchProjectReportTemplates', projectId)
})

onBeforeUnmount(() => {
  store.commit('geo/SET_REPORT_TEMPLATES', null)
})

watch(templates, (newVal, oldVal) => {
  if (newVal && oldVal) {
    changeKey.value++
  }
}, {deep: true})
</script>

<template>
  <div class="mb-1 flex items-center">
    <div class="basis-1/2">
      <h3 class="text-base font-bold">Report Templates ({{templatesLength}})</h3>
    </div>
    <div class="basis-1/2 flex justify-end">
      <CreateTemplateDialog v-if="project" :project-id="project.project_id">
        <Button
          class="px-2 text-gray-500 max-w-fit"
          variant="ghost"
        ><PlusCircle /></Button>
      </CreateTemplateDialog>
    </div>
  </div>
  <TemplatesList v-if="templatesLength" :project="project" :templates="templates" :key="changeKey" />
</template>
