<!-- TODO: refactor to MapLayer and MapSource vue components -->
<script setup>
import {computed, onBeforeUnmount, ref, watchEffect} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { v4 as uuidv4 } from 'uuid'
import {bbox} from '@turf/turf'

import {Trash2, Eye} from 'lucide-vue-next'
import {useToast} from '@/components/ui/toast/use-toast'
import {Button} from '@/components/ui/button'
import {FileViewerFeature} from '@/pages/FileViewer'

import {parseKML, parseKMZ, parseGeoJSON, parseImage} from '@/composables/files'

const { toast } = useToast()
const router = useRouter()
const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const loadedFiles = computed(() => store.getters['geo/getFiles'])

const MAX_FILES = 30

const isUploading = ref(false)
const collection = ref({
  type: 'FeatureCollection',
  features: []
})

const activeLayers = []
const activeSources = []

const setCollection = (fileCollection, fileName) => {
  createLayers()

  for (let feature of fileCollection.features) {
    feature.properties.fileName = fileName
    feature.properties.name = fileName.split('.').shift()
    feature.properties.local_uuid = uuidv4()
    feature.properties.isEditing = false
    collection.value.features.push(feature)
  }

  const sourceNoActiveFeature = collection.value.features.filter(f => !f.properties.isEditing)
  map.value.getSource('temp_data').setData({
    type: 'FeatureCollection',
    features: sourceNoActiveFeature
  })
}

const splitFeature = (feature) => {
  collection.value.features = collection.value.features.filter(f => f.properties.local_uuid !== feature.properties.local_uuid)

  if (feature.geometry?.type === 'GeometryCollection') {
    for (let geometry of feature.geometry.geometries) {
      const geoFeature = {
        type: 'Feature',
        geometry: geometry,
        properties: {
          ...feature.properties ? feature.properties : {},
          fileName: feature.properties.fileName,
          name: feature.properties.fileName.split('.').shift(),
          local_uuid: uuidv4(),
          isEditing: false
        }
      }
      collection.value.features.push(geoFeature)
    }
  }
}

const handleFile = async (file) => {
  const ext = file.name.split('.').pop().toLowerCase()
  let collection, name

  switch (ext) {
    case 'kml':
      ({ collection, name } = await parseKML(file))
      break
    case 'kmz':
      ({ collection, name } = await parseKMZ(file))
      break
    case 'geojson':
      ({ collection, name } = await parseGeoJSON(file))
      break
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'heic':
    case 'heif':
    case 'avif':
    case 'webp':
      ({ collection, name } = await parseImage(file))
      break
    default:
      toast({ title: 'Invalid file type', status: 'error' })
      break
  }

  if (collection && name) {
    setCollection(collection, name)
  }
}

const handleFiles = async (files) => {
  if (!files) return
  if (files.length === 0) return

  if (files.length > MAX_FILES) {
    toast({title: `Only ${MAX_FILES} files can be uploaded at once`})
    return
  }

  for (let i = 0; i < files.length; i++) {
    if (i > MAX_FILES) break
    await handleFile(files[i])
  }
}

const createLayers = () => {
  if (!map.value.getSource('temp_data')) {
    map.value.addSource('temp_data', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      },
    });
    activeLayers.push('temp_data');
  }

  if (!map.value.getLayer('temp-geometry-layer-fill')) {
    map.value.addLayer({
      'id': 'temp-geometry-layer-fill',
      'type': 'line',
      'source': 'temp_data',
      'paint': {
        'line-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'line-width': 4
      },
      'filter': ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']]
    });
    activeLayers.push('temp-geometry-layer-fill');
  }

  if (!map.value.getLayer('temp-geometry-layer-lines')) {
    map.value.addLayer({
      'id': 'temp-geometry-layer-lines',
      'type': 'fill',
      'source': 'temp_data',
      'paint': {
        'fill-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'fill-opacity': 0.03
      },
      'filter': ['==', '$type', 'Polygon']
    });
    activeLayers.push('temp-geometry-layer-lines');
  }

  if (!map.value.getLayer('temp-geometry-layer-points')) {
    map.value.addLayer({
      id: 'temp-geometry-layer-points',
      type: 'circle',
      source: 'temp_data',
      paint: {
        'circle-color': ['case', ['has', 'color'], ['get', 'color'], '#0059ff'],
        'circle-radius': 8,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      },
      'filter': ['==', '$type', 'Point']
    });
    activeLayers.push('temp-geometry-layer-points');
  }

  if (!map.value.getSource('temp_data')) return
  map.value.getSource('temp_data').setData(collection.value)
  activeSources.push('temp_data')
}

const focusCollection = () => {
  const bounds = bbox(collection.value)
  map.value.fitBounds(bounds, { padding: 20 })
}

const removeFeature = (feature) => {
  collection.value.features = collection.value.features.filter(f => f !== feature)
  if (!map.value.getSource('temp_data')) return
  map.value.getSource('temp_data').setData(collection.value)

  if (collection.value.features.length === 0) {

    if (feature.properties?.code) {
      // router.push({name: 'ProjectHome', params: {project_id: feature.properties.project_id}})
      router.push({name: 'Feature', params: {project_id: feature.properties.project_id, featureCode: feature.properties.code}})
    }
  }
}

const clearCollection = () => {
  collection.value.features = []
  if (!map.value.getSource('temp_data')) return
  map.value.getSource('temp_data').setData(collection.value)
}

const startEditing = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.properties.isEditing = !f.properties.isEditing
    } else {
      f.properties.isEditing = false
    }
  }
  const collectionNoActive = {
    type: 'FeatureCollection',
    features: collection.value.features.filter(f => !f.properties.isEditing)
  }
  map.value.getSource('temp_data').setData(collectionNoActive)
}

const stopEditing = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.geomertry = feature.geometry
      f.properties.isEditing = false
    }
  }
  if (!map.value.getSource('temp_data')) return
  map.value.getSource('temp_data').setData(collection.value)
}

const updateFeature = (feature) => {
  for (let f of collection.value.features) {
    if (f.properties.local_uuid === feature.properties.local_uuid) {
      f.properties = feature.properties
    }
  }

  if (!map.value.getSource('temp_data')) return
  map.value.getSource('temp_data').setData(collection.value)
}

const reset = () => {
  for (let layer of activeLayers) {
    if (map.value.getLayer(layer)) map.value.removeLayer(layer)
  }

  for (let source of activeSources) {
    if (map.value.getSource(source)) map.value.removeSource(source)
  }

  collection.value.features = []
  store.commit('geo/SET_FILES', null)
}

const uploadAll = async () => {
  isUploading.value = true
  for (let feature of collection.value.features) {
    feature.properties.isUpload = true
  }

  setTimeout(() => {
    for (let feature of collection.value.features) {
      feature.properties.isUpload = false
    }

    isUploading.value = false
  }, 1000)
}

watchEffect(async () => {
  await handleFiles(loadedFiles.value)
})

onBeforeUnmount(() => {
  reset()
})
</script>

<template>
  <div class="bg-white p-3">
    <div>
      <div class="mb-2 flex items-center justify-between">
        <div class="flex items-center">
          <h2 class="font-bold">Shapes: {{ collection.features.length }}</h2>
        </div>
        <div class="flex items-center space-x-1">
          <Button v-if="collection.features.length" @click="focusCollection" size="sm" variant="secondary" class=" h-8">
            <Eye class="text-gray-500 w-4 h-4" />
            <span class="ml-2 text-gray-700">Zoom</span>
          </Button>
          <Button v-if="collection.features.length" @click="clearCollection" size="sm" variant="secondary" class=" h-8">
            <Trash2 class="text-gray-500 w-4 h-4" />
            <span class="ml-2 text-gray-700">Clear</span>
          </Button>
        </div>
      </div>

      <div class="flex justify-end mb-3" v-if="collection.features.length > 0">
        <Button @click="uploadAll" :disable="isUploading" size="sm">Upload all</Button>
      </div>

      <div class="mb-6" :key="collection.features.length">
        <FileViewerFeature
          v-for="(feature, index) in collection.features"
          :feature="feature"
          :index="index"
          :key="feature.properties.local_uuid"
          @removeFeature="(feature) => removeFeature(feature)"
          @startEditing="(feature) => startEditing(feature)"
          @stopEditing="(feature) => stopEditing(feature)"
          @updateFeature="(feature) => updateFeature(feature)"
          @split="(feature) => splitFeature(feature)"
        />
      </div>
    </div>
    <div v-if="collection.features.length === 0">
      <div xs12 class="flex items-center justify-center p-3">
        <div class="text-center">
          <h2 class="text-lg font-bold">No shapes loaded</h2>
          <p class="text-gray-500">Drag and drop or add in '+' button</p>
        </div>
      </div>
    </div>
  </div>
</template>
