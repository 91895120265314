import {createApp} from 'vue'
import * as Sentry from '@sentry/vue'

import { CONFIG, loadEnv } from '@/config'

import '@/style.css'

import App from '@/App.vue'
import store from '@/vuex/index.js'
import { router } from './router'
import { setupCalendar } from 'v-calendar'


loadEnv()
  .then((response: any) => {
    const app = createApp(App)

    if (CONFIG.env === 'prod') {
       Sentry.init({
        app,
        dsn: 'https://928261e1f5594d65830ec1a0f9606aab@o1180004.ingest.sentry.io/6292451',
        logErrors: true,
        environment: CONFIG.env,
        integrations: [
          Sentry.browserTracingIntegration({
            router: router
          }),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
      })
    }
    
    app.use(setupCalendar, {})
    app.use(router)
    app.use(store)
    app.mount('#app')
  })
  .catch((reason: any) => console.error('Error during loading app', reason))
