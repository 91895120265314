<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'

import {MoreHorizontal, Pencil} from 'lucide-vue-next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'

import type { ReportTemplate } from '@/types'
import CreateTemplateDialog from "@/pages/Project/Templates/CreateTemplateDialog.vue";

const store = useStore()

const props = defineProps<{
  project_id: number
  template: ReportTemplate
}>()

const remove = async (ev: any) => {
  store.dispatch('geo/deleteReportTemplate', {
    project_id: props.project_id,
    template: props.template
  })
}
</script>

<template>
  <div class="flex items-center">
    <CreateTemplateDialog :project-id="props.project_id" :template="props.template" :edit-mode="true">
      <Button variant="ghost" size="sm"><Pencil class="w-3.5 h-3.5" /></Button>
    </CreateTemplateDialog>
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button variant="ghost" class="w-8 h-8 p-0">
          <span class="sr-only">Open menu</span>
          <MoreHorizontal class="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem @click="remove" class="text-red-600">Delete</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </div>
</template>
