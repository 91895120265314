<script setup>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { PlusCircle } from 'lucide-vue-next'

const store = useStore()

const isOpen = ref(false)
const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255)
}))

const onSubmit = async (values) => {
  await store.dispatch('org/createOrg', values)

  toast({
    title: `Organization ${values.name} created`,
  })

  close()
}
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <PlusCircle class="text-gray-500 w-4 h-4" />
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Create organization</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button variant="ghost" @click="close">Close</Button>
          <Button type="submit">Create</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
