export const state = {
  map: null,
  draw: null,
  mapReady: false,
  features: {
    type: 'FeatureCollection',
    features: []
  },
  feedFeatures: {
    type: 'FeatureCollection',
    features: []
  },

  currentFeature: null,
  currentFeatureChilds: null,

  page: 1,
  perPage: 10,

  planetFeaturesEnabled: false,
  beacons: [],

  notes: [],

  tilesToken: null,
  tilesSettings: null,
  tilesMosaics: [],

  files: null,
  layers: null,
  reportTemplates: null,

  isFeatureCreated: false,
  totalOnMap: 0,
}
