<script setup lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { PlusCircle } from 'lucide-vue-next'

import { Org } from '@/types'
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";

const store = useStore()
const member = computed(() => store.getters['user/getMember'])

const props = defineProps<{
  org: Org
}>()

const isOpen = ref(false)
const defaultKeySettings = [
  {"name": "Skysat", "enabled": false},
  {"name": "Basemaps", "enabled": false},
  {"name": "Planetscope", "enabled": false}
]

const open = () => {
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  fullname: z.string().min(3).max(255),
  email: z.string().email(),
  role: z.string().default('org_member')
}))

const onSubmit = async (values: any) => {
  await store.dispatch('org/invite', {
    ...values,
    member_id: member.value.member_id,
    organization_id: props.org.organization_id,
    key_settings: defaultKeySettings
  })

  close()
}

defineExpose({
  open
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <PlusCircle class="text-gray-500" />
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Invite</DialogTitle>
        <DialogDescription>Invite user to organization</DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="fullname">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="role">
          <FormItem>
            <FormControl>
              <RadioGroup v-bind="componentField">
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="org_admin" />
                  </FormControl>
                  <FormLabel class="font-normal">Administator</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="org_member" />
                  </FormControl>
                  <FormLabel class="font-normal">Default</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="org_viewer" />
                  </FormControl>
                  <FormLabel class="font-normal">Viewer</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
          </FormItem>
          <FormMessage />
        </FormField>

        <DialogFooter>
          <Button variant="ghost" @click="close">Close</Button>
          <Button type="submit">Invite</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
