import {API} from "@/utils/http";

export const fetchAccounts = async ({commit}, payload) => {
  try {
    const orgId = payload.orgId
    const response = await API({
      method: 'GET',
      url: `/tulum/billing/accounts/${orgId}`,
    })

    // TODO: refactor
    const accounts = await Promise.all(response.data.map(async (a) => {
      const response = await API({
        method: 'GET',
        url: `/tulum/billing/accounts/${orgId}/${a.code}`,
      })
      a.transactions = response.data.transactions
      return a
    }))

    commit('SET_ACCOUNTS', accounts)
  } catch (e) {
    console.error(e)
    commit('SET_ALERT', {type: 'error', text: "Can't fetch accounts"}, {root: true})
  }
}
