import {kml} from '@tmcw/togeojson'
import ExifReader from 'exifreader'
import JSZip from 'jszip'

function getImageGeometry(gps) {
  let latitude = gps.Latitude || gps.GPSLatitude.description
  let longitude = gps.Longitude || gps.GPSLongitude.description

  if (gps.GPSLatitudeRef && gps.GPSLatitudeRef.value[0] === 'S') {
    latitude = -latitude
  }

  if (gps.GPSLongitudeRef && gps.GPSLongitudeRef.value[0] === 'W') {
    longitude = -longitude
  }

  latitude = Math.max(-90, Math.min(90, latitude))
  longitude = Math.max(-180, Math.min(180, longitude))

  const geometry = {
    type: 'Point',
    coordinates: [
      longitude,
      latitude
    ]
  }

  return geometry
}

function parseDate(s) {
  const b = s.split(/\D/)
  return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5])
}

function parseXML(data) {
  try {
    const cleanData = data.replace(/xsi:schemaLocation=["'][^"']*["']\s*/g, '')

    const parser = new DOMParser()
    return parser.parseFromString(cleanData, 'application/xml')
  } catch (e) {
    console.error('Error parsing XML data:', e)
    return null
  }
}

export async function readFile(file, readAsBuffer = false) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      resolve(event.target.result)
    }

    reader.onerror = (event) => {
      reject(event.target.error)
    }

    if (readAsBuffer) {
      return reader.readAsText(file)
    }
    return reader.readAsArrayBuffer(file)
  })
}

export const parseKML = async (file) => {
  const contents = await readFile(file, true)
  const xml = parseXML(contents)

  try {
    const featureCollection = kml(xml)
    const features = featureCollection.features.map(feature => {
      feature.properties = {
        name: feature.properties?.name ? feature.properties.name : file.name
      }
      return feature
    })
    return {
      collection: {
        type: 'FeatureCollection',
        features: features
      },
      name: file.name
    }
  } catch (error) {
    console.error("Error parsing .kml file:", error)
  }
}

export const parseKMZ = async (file) => {
  try {
    const zip = new JSZip()
    const archive = await zip.loadAsync(file)
    const kmlFiles = Object.keys(archive.files).filter(f => f.endsWith('.kml'))

    for (let file of kmlFiles) {
      const kmlFile = archive.files[file]
      const kmlContents = await kmlFile.async('string')
      const xml = parseXML(kmlContents)

        const featureCollection = kml(xml)
        const features = featureCollection.features.map(feature => {
          feature.properties = {
            name: feature.properties?.name ? feature.properties.name : file
          }
          return feature
        })

        return {
          collection: {
            type: 'FeatureCollection',
            features: features
          },
          name: file
        }
    }
  } catch (error) {
    console.error("Error parsing .kml file:", error)
  }
}

export const parseGeoJSON = async (file) => {
  const contents = await readFile(file, true)
  try {
    const featureCollection = JSON.parse(contents)
    const features = featureCollection.features.map(feature => {
      feature.properties = {
        name: feature.properties?.name ? feature.properties.name : file.name
      }
      return feature
    })

    return {
      collection: {
        type: 'FeatureCollection',
        features: features
      },
      name: file.name
    }
  } catch (error) {
    console.error("Error parsing .geojson file:", error)
  }
}

export const parseImage = async (file) => {
  try {
    const tags = await ExifReader.load(file, { async: true })
    const created_origin = tags.DateTimeOriginal ? parseDate(tags.DateTimeOriginal.value[0]).getTime() : file.lastModified
    const geometry = tags.GPSLatitude && tags.GPSLongitude
      ? getImageGeometry({
          GPSLatitude: tags.GPSLatitude,
          GPSLongitude: tags.GPSLongitude,
          GPSLatitudeRef: tags.GPSLatitudeRef,
          GPSLongitudeRef: tags.GPSLongitudeRef
        })
      : null

    const feature = {
      type: 'Feature',
      properties: {
        name: file.name,
        created_origin: created_origin,
        type: 'Point',
        resource: file
      },
      geometry: geometry
    }

    return {
      collection: {
        type: 'FeatureCollection',
        features: [feature]
      },
      name: file.name
    }
  } catch (error) {
    console.error("Error parsing image file:", error)
  }
}
