<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import { Button } from '@/components/ui/button'
import { ChevronLeft, ChevronRight, Folders } from 'lucide-vue-next'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)

const savedWidth = localStorage.getItem('leftPanelWidth')
const width = ref(savedWidth ? parseInt(savedWidth, 10) : 330)
const isDragging = ref(false)
const initialWidth = ref(width.value)
const startX = ref(0)

const isMobileOrTablet = ref(window.innerWidth <= 768)
const isLeftCollapsed = ref(isMobileOrTablet.value || width.value === 0)
const lastLeftWidth = ref(isLeftCollapsed.value ? 330 : width.value)

const onMouseMove = (event) => {
  if (isDragging.value) {
    const newWidth = initialWidth.value + (event.clientX - startX.value)
    width.value = Math.max(10, newWidth)
    localStorage.setItem('leftPanelWidth', width.value)
  }
}

const onMouseUp = () => {
  isDragging.value = false
  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', onMouseUp)
}

const onMouseDown = (event) => {
  isDragging.value = true
  initialWidth.value = width.value
  startX.value = event.clientX

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
}

const toggleLeftCollapse = () => {
  isLeftCollapsed.value = !isLeftCollapsed.value
  if (isLeftCollapsed.value) {
    lastLeftWidth.value = width.value
    width.value = 0
  } else {
    // If mobile or tablet, set width to 80% of the window width
    width.value = isMobileOrTablet.value ? window.innerWidth * 0.8 : lastLeftWidth.value || 330
  }
  localStorage.setItem('leftPanelWidth', width.value)

  if (map.value) {
    setTimeout(() => {
      map.value.resize()
    }, 300)
  }
}

const closeLeftPanelOnClickRight = () => {
  if (isMobileOrTablet.value && !isLeftCollapsed.value) {
    toggleLeftCollapse()
  }
}

onMounted(() => {
  if (window.innerWidth <= 768) {
    isLeftCollapsed.value = true
    width.value = 0
  }

  document.addEventListener('mouseup', onMouseUp)

  window.addEventListener('resize', () => {
    isMobileOrTablet.value = window.innerWidth <= 768
    if (isMobileOrTablet.value && !isLeftCollapsed.value) {
      toggleLeftCollapse()
    }
  })
})
</script>

<template>
  <div class="flex w-full split-section">
    <div
      v-if="isLoggedIn"
      :style="{ width: `${width}px` }"
      class="relative z-20"
      :class="{'transition-width duration-300 ease-in-out': !isDragging}"
    >
      <div class="overflow-hidden w-full h-full">
        <div class="overflow-x-auto w-full h-full">
          <slot name="left"></slot>
        </div>
      </div>
      <div
        class="w-1 h-full cursor-col-resize absolute right-0 top-0"
        :class="{'bg-gray-200': isDragging}"
        @mousedown="onMouseDown"
      ></div>
      <Button
        class="shadow-lg absolute -top-6 lg:top-1/2 transform -translate-y-1/2 z-30 w-30 h-8 md:h-10 px-2 lg:px-0 py-0 rounded-none -right-30 lg:-right-5 rounded-r-md"
        size="sm"
        @click="toggleLeftCollapse"
      >
        <ChevronRight class="w-5 h-5 hidden lg:block" v-if="isLeftCollapsed" />
        <ChevronLeft class="w-5 h-5 hidden lg:block" v-else />
        <div v-if="isLeftCollapsed" class="flex items-center lg:hidden">
          <Folders class="w-5 h-5 mr-2" />
          Projects
        </div>
      </Button>
    </div>

    <div
      class="flex-1 z-10 shadow-lg relative min-w-full lg:min-w-0"
      @click="closeLeftPanelOnClickRight"
    >
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-50"  v-if="isMobileOrTablet && !isLeftCollapsed"></div>
      <div class="overflow-hidden w-full h-full">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<style>
.split-section {
  max-height: calc(var(--app-height) - 50px);
  min-height: calc(var(--app-height) - 50px);

  @media (min-width: 1024px) {
    max-height: calc(var(--app-height) - 100px);
    min-height: calc(var(--app-height) - 100px);
  }
}
</style>
