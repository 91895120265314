<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chart from 'chart.js/auto'
import {formatTime} from '@/utils/datetime'


const emit = defineEmits(['update:activeResult'])
const props = defineProps({
  layer: {
    type: Object,
    required: true
  },
  feature: {
    type: Object,
    required: true
  },
  results: {
    type: Array,
    required: true
  }
})

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const isChartRendered = ref(false)

const results = ref(props.results)

const viridis = [
  {index: 0, rgb: [253, 231, 37]},
  {index: 0.1, rgb: [181, 222, 43]},
  {index: 0.2, rgb: [110, 206, 88]},
  {index: 0.3, rgb: [53, 183, 121]},
  {index: 0.4, rgb: [31, 158, 137]},
  {index: 0.5, rgb: [38, 130, 142]},
  {index: 0.6, rgb: [49, 104, 142]},
  {index: 0.7, rgb: [62, 73, 137]},
  {index: 0.8, rgb: [72, 40, 120]},
  {index: 0.9, rgb: [68, 1, 84]},
]

const ylgn = [
  // {index: 0, rgb: [0, 68, 28]},
  {index: 0, rgb: [0, 109, 44]},
  {index: 0.1, rgb: [35, 139, 69]},
  {index: 0.2, rgb: [65, 171, 89]},
  {index: 0.3, rgb: [120, 198, 121]},
  {index: 0.4, rgb: [173, 221, 142]},
  {index: 0.5, rgb: [217, 239, 163]},
  {index: 0.6, rgb: [247, 252, 185]},
  {index: 0.7, rgb: [255, 255, 229]},
]

const greens = [
  {index: 0, rgb: [0, 109, 44]},
  {index: 0.1, rgb: [35, 139, 69]},
  {index: 0.2, rgb: [65, 171, 93]},
  {index: 0.3, rgb: [116, 196, 118]},
  {index: 0.4, rgb: [161, 217, 155]},
  {index: 0.5, rgb: [199, 233, 192]},
  {index: 0.6, rgb: [229, 245, 224]},
  {index: 0.7, rgb: [247, 252, 245]}
]

const chartData = ref(null)

const idToLabel = {
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': 'ACD (Mg C⋅ha)',
  'CANOPY_HEIGHT_v1.0.0_30': 'm',
  'CANOPY_COVER_v1.0.0_30': '%',
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_3': 'ACD (Mg C⋅ha)',
  'CANOPY_HEIGHT_v1.0.0_3': 'm',
  'CANOPY_COVER_v1.0.0_3': '%'
}

const idToColormap = {
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': viridis,
  'CANOPY_HEIGHT_v1.0.0_30': greens,
  'CANOPY_COVER_v1.0.0_30': ylgn,
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_3': viridis,
  'CANOPY_HEIGHT_v1.0.0_3': greens,
  'CANOPY_COVER_v1.0.0_3': ylgn
}

const generateGradient = (ctx, chartArea, colormap, opacity=1) => {
  const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
  for (let color of colormap) {
    gradient.addColorStop(color.index, `rgb(${color.rgb.join(',')}, ${opacity})`)
  }
  return gradient
}

onMounted(async () => {
  const ctx = document.getElementById(`layer-chart-${props.layer.uuid}`).getContext('2d')
  if (!results.value) return

  const labels = results.value.map((i) => formatTime(i.item_datetime, settings.value.tz, 'DD MMM YY'))
  const data = results.value.map((i) => i.properties.statistics[0].value)
  const datasets = [
    {
      label: idToLabel[props.layer.source_id],
      data: results.value.map((i) => i.properties.statistics[0].value),
      borderWidth: 2,
      borderColor: (context) => {
        const chartArea = context.chart.chartArea
        if (!chartArea) return null
        return generateGradient(ctx, chartArea, idToColormap[props.layer.source_id])
      },
      backgroundColor: (context) => {
        const chartArea = context.chart.chartArea
        if (!chartArea) return null
        return generateGradient(ctx, chartArea, idToColormap[props.layer.source_id], 0.75)
      },
      fill: true
    }
  ]

  chartData.value = {
    labels: results.value.map((i) => formatTime(i.item_datetime, settings.value.tz, 'DD MMM YY')),
    datasets: datasets
  }

  const chart = new Chart(ctx, {
    type: 'line',
    data: chartData.value,
    options: {
      animation: {
        onComplete: () => {
          isChartRendered.value = true
        }
      },
      onHover: function(event, chartElement) {
        if (chartElement.length > 0) {
          const index = chartElement[0].index
          emit('update:activeResult', results.value[index])
        }
      },
      scales: {
        x: {
          ticks: {
            minRotation: 30,
            maxRotation: 90
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: idToLabel[props.layer.source_id],
            position: 'top'
          }
        }
      },
      responsive: true,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          mode: 'nearest',
          intersect: false
        }
      }
    }
  })
})
</script>

<template>
  <div class="py-1">
    <canvas v-show="chartData" :id="`layer-chart-${layer.uuid}`" width="100%" height="60px"></canvas>
  </div>
</template>
