<script setup lang="ts">
import {Member, Org, OrgMember} from '@/types'
import {h} from 'vue'

import {ColumnDef} from "@tanstack/vue-table"
import {ArrowUpDown} from "lucide-vue-next"
import {Button} from '@/components/ui/button'
import {MemberAvatar} from '@/components/ui/avatar'
import {DataTable} from '@/components/ui/data-table'
import MembersListDropdown from './MembersListDropdown.vue'

defineProps<{
  org: Org,
  members: OrgMember[]
}>()

const columns: ColumnDef<OrgMember>[] = [
  {
    accessorKey: 'display_name',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => {
      const member = row.original
      return h(MemberAvatar, {avatar_url: member.avatar_url, display_name: member.display_name})
    },
  },
  {
    accessorKey: 'email',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Email', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => h('div', {class: 'lowercase'}, row.getValue('email')),
  },
  {
    accessorKey: 'role',
    header: 'Role',
    cell: ({row}) => {
      return h('div', {class: 'text-gray-500'}, row.getValue('role'))
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const member = row.original
      return h('div', { class: 'relative' }, h(MembersListDropdown, {
        member,
      }))
    },
  }
]
</script>

<template>
  <DataTable :columns="columns" :data="members" />
</template>
