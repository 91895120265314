<template>
  <footer class="bg-white flex items-center px-2 text-sm h-full shadow-lg">
    <div class="basis-1/3">
      <h4 class="max-w-[140px] lg:max-w-max">© {{ year }}  Earth PBC. All Rights Reserved.</h4>
    </div>
    <div class="basis-1/3">
      <div class="flex justify-center">

      </div>
    </div>
    <div class="basis-1/3">
      <div class="flex items-center justify-end">
        <ul class="flex items-center pr-32 mr-3">
          <li><a target="_blank" href="https://earthpbc.com/" class="hover:opacity-75 mx-2">Earth</a></li>
          <li><a target="_blank" href="https://earthpbc.com/privacy-policy" class="hover:opacity-75 mx-2">Privacy Policy</a></li>
          <li><a target="_blank" href="https://earthpbc.com/aboutearth" class="hover:opacity-75 mx-2">About</a></li>
        </ul>

        <!-- <a target="_blank" href="https://play.google.com/store/apps/details?id=com.earthpbc.explorer" class="flex items-center">
          <img src="@/assets/img/google-play-badge.png" alt="Get in Google play" style="height: 58px;">
        </a>

        <a target="_blank" href="https://apps.apple.com/us/app/earth-pbc/id1590563602" class="flex items-center">
          <img src="@/assets/img/app-store-badge.svg" alt="Download on the App Store">
        </a> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    year () {
      return new Date().getFullYear()
    }
  }
}
</script>
