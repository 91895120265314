<script setup>
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import {bbox} from '@turf/turf'
import { MapSource, MapLayer } from '@/components/map'
import { Slider } from '@/components/ui/slider'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const props = defineProps({
  results: {
    type: Array,
    required: true
  },
  feature: {
    type: Object,
    required: true
  },
  activeResult: {
    type: Object,
    required: true
  }
})

const opacity = ref(100)
const geometry = props.feature
const box = bbox(geometry)
const topLeft = [box[0], box[3]]
const topRight = [box[2], box[3]]
const bottomRight = [box[2], box[1]]
const bottomLeft = [box[0], box[1]]
const corners = [
  topLeft,
  topRight,
  bottomRight,
  bottomLeft
]

const onOpacityChange = (value) => {
  opacity.value = value[0]
  map.value.setPaintProperty(
    `result-layer-${props.activeResult.id}`,
    'raster-opacity',
    value[0] / 100
  )
}
</script>

<template>
  <div>
    <h4>Opacity</h4>
    <div class="flex items-center justify-between">
      <Slider
        :default-value="[opacity]"
        :max="100"
        :step="1"
        @update:model-value="onOpacityChange($event)"
      />
      <span class="w-7 ml-1 flex">
        {{opacity}}
      </span>
    </div>
  </div>

  <MapSource
    v-if="map"
    v-for="result in results"
    :map="map"
    type="image"
    :id="`result-source-${result.id}`"
    :url="result.s3_url"
    :coordinates="corners"
  >
    <MapLayer
      v-if="result.id === activeResult.id"
      :map="map"
      :id="`result-layer-${result.id}`"
      :source="`result-source-${result.id}`"
      type="raster"
      :options="{
        paint: {
          'raster-fade-duration': 0,
          'raster-resampling': 'nearest'
        }
      }"></MapLayer>
  </MapSource>
</template>
