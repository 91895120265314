export const getters = {
  getOrgs: (state) => {
    return state.orgs
  },
  getSelected: (state) => {
    return state.selectedOrg
  },
  getCurrent: (state) => {
    return state.current
  },
  getCurrentMembers: (state) => {
    return state.currentMembers
  },
  getCurrentInvites: (state) => {
    return state.currentInvites
  },
  getApikeys: (state) => {
    return state.apikeys
  }
}
