<script setup lang="ts">
import {useStore} from 'vuex'
import {computed, h} from 'vue'

import {ColumnDef} from "@tanstack/vue-table"
import {ArrowUpDown} from "lucide-vue-next"
import {Button} from '@/components/ui/button'
import {MemberAvatar} from '@/components/ui/avatar'
import {DataTable} from '@/components/ui/data-table'
import {Org} from '@/types'
import {formatTime} from '@/utils/datetime.js'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

interface OrgInvite {
  id: string
  role: string
  email: string
  fullname: string
  member_id: number
  is_accepted: boolean
  created_at: string
  updated_at: string
  organization_id: number
}

defineProps<{
  org: Org,
  invites: OrgInvite[]
}>()

const columns: ColumnDef<OrgInvite>[] = [
  {
    accessorKey: 'fullname',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => {
      const invite = row.original
      return h(MemberAvatar, {avatar_url: null, display_name: invite.fullname})
    },
  },
  {
    accessorKey: 'email',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Email', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => h('div', {class: 'lowercase'}, row.getValue('email')),
  },
  {
    accessorKey: 'role',
    header: 'Role',
    cell: ({row}) => {
      return h('div', {class: 'text-gray-500'}, row.getValue('role'))
    },
  },
  {
    accessorKey: 'create_dttm',
    header: 'Member since',
    cell: ({row}) => {
      const date = formatTime(row.getValue('create_dttm'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  }
]
</script>

<template>
  <DataTable :columns="columns" :data="invites" />
</template>
