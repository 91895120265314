export const mutations = {
  SET_LAYERS: (state, payload) => {
    state.layers = payload
  },
  ADD_LAYER: (state, payload) => {
    state.layers.unshift(payload)
  },
  DELETE_LAYER: (state, payload) => {
    state.layers = state.layers.filter(layer => layer.id !== payload)
  },
  UPDATE_ORDER_STATUS: (state, payload) => {
    if (!state.layers) return
    const order = state.layers.find(order => order.id === payload.id)
    order.status = payload.status
  }
}
