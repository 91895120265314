<script setup>
import { Check, ChevronsUpDown } from 'lucide-vue-next'

import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {computed, onBeforeMount, ref, watchEffect} from 'vue'
import { cn } from '@/lib/utils.ts'

import { Building2, Settings, X } from 'lucide-vue-next'

import { Button } from '@/components/ui/button/index.ts'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command/index.ts'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover/index.ts'


const store = useStore()
const route = useRoute()
const router = useRouter()
const routeOrgId = parseInt(route.params.org_id)

const org = computed(() => store.getters['org/getCurrent'])
const member = computed(() => store.getters['user/getMember'])
const orgs = computed(() => store.getters['org/getOrgs'])
const params = computed(() => store.getters['search/getParams'])
const orgIdParam = computed(() => params.value.find(i => i.key === 'organization_id')?.value || null)

const orgRoutes = ['OrgMembers', 'OrgSettings', 'Organization']
const open = ref(false)

const selected = computed({
  get () {
    return store.state.org.current
  },
  set (org) {
    store.commit('org/SET_CURRENT', org)
    store.dispatch('project/fetchProjects')

    if (orgRoutes.includes(route.name)) {
      router.push({ name: route.name, params: { id: org.organization_id } })
    }

    store.commit('project/SET_CURRENT', null)
  },
  immediate: true
})

watchEffect(async () => {
  if (orgIdParam.value) {
    await store.dispatch('org/fetchOrg', orgIdParam.value || routeOrgId)
  } else {
    store.commit('org/SET_CURRENT', null)
  }
})

const resetSelected = () => {
  store.commit('org/SET_CURRENT', null)
  store.commit('project/SET_CURRENT', null)
  store.commit('search/REMOVE_PARAM', { key: 'organization_id' })
  store.commit('search/REMOVE_PARAM', { key: 'project_id' })
  open.value = false
}

const filterOrgs = (list, search) => {
  return list.filter(i => i.organization_name.toLowerCase().includes(search.toLowerCase()))
}
</script>

<template>
  <Command :filter-function="filterOrgs" class="border border-gray-200">
    <CommandInput placeholder="Search organization..." />
    <CommandList>
      <CommandGroup>
        <CommandItem
          v-for="org in orgs" :key="org.organization_id"
          class="flex-1"
          :value="org"
          @select="(ev) => {
            selected = ev.detail.value
            open = false
          }"
        >
        <div class="flex items-center justify-between w-full">
          <span>{{ org.organization_name }}</span>

          <router-link :to="{ name: 'OrgMembers', params: { org_id: org.organization_id }}">
            <Settings class="w-4 h-4 text-gray-600" />
          </router-link>
        </div>
        </CommandItem>
      </CommandGroup>
    </CommandList>
  </Command>
</template>
