<script setup>
import { ref } from 'vue'
import {useStore} from 'vuex'
import {API, HOST} from '@/utils/http'
import {useToast} from '@/components/ui/toast/use-toast'
import { readAndCompressImage } from 'browser-image-resizer'

import {Avatar, AvatarImage} from '@/components/ui/avatar'
import {Button} from '@/components/ui/button'
import {Spinner} from '@/components/ui/spinner'

const { toast } = useToast()
const store = useStore()
const fileInput = ref(null)
const imagePreview = ref(null)
const selectedFile = ref(null)
const inProgress = ref(false)

const props = defineProps({
  org: {
    type: Object,
    required: true
  }
})

const updateAvatar = async () => {
  const file = fileInput.value.files[0]
  const resizeConfig = {
    quality: 0.8,
    maxWidth: 400,
    maxHeight: 400,
    debug: true
  }
  const resizedFile = await readAndCompressImage(file, resizeConfig)
  const fileSize = resizedFile.size / 1024 / 1024

  if (fileSize > 1) {
    toast({title: 'File size must be less than 1MB'})
    return
  }

  imagePreview.value = URL.createObjectURL(resizedFile)
  selectedFile.value = resizedFile
}

const saveAvatar = async () => {
  inProgress.value = true
  try {
    const response = await API({
      headers: {'Content-Type': 'multipart/form-data'},
      method: 'PATCH',
      url: `${HOST}/organization/${props.org.organization_id}/update-avatar`,
      data: {
        file: selectedFile.value
      }
    })

    if (response.status === 200) {
      toast({title: 'Avatar updated successfully'})

      // Reset the file input
      fileInput.value.value = ''
      store.dispatch('org/fetchOrgs')
    }
  } catch (error) {
    console.error(error)
    toast({title: 'Failed to upload avatar'})
  }
  inProgress.value = false
}
</script>

<template>
  <div class="mb-3">
    <Avatar v-if="org.avatar_url || selectedFile" class="w-28 h-28 cursor-pointer hover:opacity-80 rounded-lg">
      <AvatarImage :src="imagePreview ? imagePreview : org.avatar_url" :alt="org.organization_name" />
    </Avatar>

    <div class="max-w-[250px] mt-2">
      <label for="orgAvatarFileInput">
        <!-- Choose a new avatar -->
        <input id="orgAvatarFileInput" ref="fileInput" type="file" accept="image/jpeg, image/png, image/webp" @change="updateAvatar">
      </label>
    </div>
  </div>

  <Button :disabled="!imagePreview || inProgress" @click="saveAvatar">
    <Spinner v-if="inProgress" class="w-4 h-4" />
    <span v-else>Save Avatar</span>
  </Button>
</template>
