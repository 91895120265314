<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'

const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: (value) =>
      ['geojson', 'vector', 'raster', 'image', 'video'].includes(value),
  },
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  options: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  url: {
    type: String,
    required: false,
    default: '',
  },
  coordinates: {
    type: Array,
    required: false,
    default: () => [],
  },
})

const isAdded = ref(false)

const addSource = () => {
  if (!props.map.getSource(props.id)) {
    const source = {
      type: props.type,
      ...props.options,
    }

    if (props.type === 'geojson') {
      source.data = props.data
      source.promoteId = 'code'
    }

    if (props.type === 'raster' || props.type === 'image') {
      source.url = props.url
      source.coordinates = props.coordinates
    }
    props.map.addSource(props.id, source)
    isAdded.value = true
  }
}

const removeSource = () => {
  if (props.map.getSource(props.id)) {
    props.map.removeSource(props.id)
  }
}

onMounted(() => {
  addSource()
})

watch(() => props.data, (newValue, oldValue) => {
  if (isAdded.value && newValue.features.length !== oldValue.features.length) {
    props.map.getSource(props.id).setData(props.data)
  }
})

onUnmounted(() => {
  removeSource()
})
</script>

<template>
  <slot v-if="isAdded"></slot>
</template>

