<script setup lang="ts">
import {useStore} from 'vuex'
import {toTypedSchema} from '@vee-validate/zod'
import * as z from 'zod'

import {toast} from '@/components/ui/toast'
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form'
import {Input} from '@/components/ui/input'
import {Button} from '@/components/ui/button'

import {Org} from '@/types'

const store = useStore()
const props = defineProps<{
  org: Org
}>()

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default(props.org.organization_name)
}))

const onSubmit = async (values: any) => {
  console.log('ORG SAVE: not implemented yet')
  toast({title: `Saved`})
}
</script>

<template>
<Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
  <FormField v-slot="{ componentField }" name="name">
    <FormItem>
      <FormLabel>Name</FormLabel>
      <FormControl>
        <Input v-bind="componentField" />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <Button type="submit">Save</Button>
</Form>
</template>
