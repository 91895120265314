export const mutations = {
  SHOW_SNACKBAR(state, payload) {
    state.snackbar.text = payload.text
    state.snackbar.multiline = (payload.text.length > 50) ? true : false

    if(payload.multiline){
      state.snackbar.multiline = payload.multiline
    }

    if(payload.timeout){
      state.snackbar.timeout = payload.timeout
    }

    state.snackbar.type = payload.type

    state.snackbar.visible = true
  },
  HIDE_SNACKBAR(state) {
    state.snackbar.visible = false
    state.snackbar.multiline = false
    state.snackbar.timeout = 6000
    state.snackbar.type = null
    state.snackbar.text = null
  },

  SET_MEMBER(state, payload) {
    state.member = payload
  },
  SET_IS_LOGGEDIN(state, payload) {
    state.isLoggedIn = payload
  },
  SET_USER(state, user) {
    state.user = user
  },
  API_AUTH(state, creds) {
    state.apiAuth = creds
  },
  IS_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_LOADER_COLOR(state, color) {
    state.appSettings.loader.color = color
  },
  SET_LOADER_SIZE(state, size) {
    state.appSettings.loader.size = size
  },
  SET_LOADER_TYPE(state, type) {
    state.appSettings.loader.type = type
  },

  SET_ALERT(state, payload) {
    state.alerts.push(payload)
  },
  REMOVE_ALERT(state, payload) {
    state.alerts = state.alerts.filter(alert => alert !== payload)
  }
}
