<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import mapboxgl from 'mapbox-gl'

const emit = defineEmits(['click'])
const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  feature: {
    type: Object,
    required: true,
  },
  url: {
    type: String,
    required: true
  },
  ping: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: false,
    default: null,
  },
})

const marker = ref(null)
const markerWrap = ref(null)
const coordinates = computed(() => {
  return props.feature.geometry.coordinates
})

onMounted(() => {
  if (!coordinates.value) return
  marker.value = new mapboxgl.Marker({
    anchor: 'center',
    element: markerWrap.value,
  }).setLngLat(coordinates.value).addTo(props.map)
})

onBeforeUnmount(() => {
  markerWrap.value.remove()
})
</script>

<template>
  <img v-if="!ping" ref="markerWrap" :src="url" alt="marker" class="h-9 w-9 rounded-full bg-gray-300 border-2 border-white cursor-pointer z-20" />
  <div v-else ref="markerWrap" @click="() => emit('click')" class="flex flex-col items-center justify-center">
    <div class="relative w-9 h-9 border border-gray-400 rounded-full">
      <div class="animate-ping bg-primary absolute top-0 left-0 inline-flex h-full w-full rounded-full opacity-75 z-10"></div>
      <img :src="url" alt="marker" class="h-full w-full rounded-full bg-white border-2 border-white cursor-pointer bg-contain z-20" />
    </div>
    <div v-if="label" class="text-xs whitespace-nowrap backdrop-blur-sm bg-white/40 text-center mt-1 rounded px-1 py-0.5">{{label}}</div>
  </div>
</template>
