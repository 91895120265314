<script setup>
import {Badge} from '@/components/ui/badge'
import {Button} from '@/components/ui/button'
import OrderDialogForm from "@/components/orders/OrderDialogForm.vue";
</script>

<template>
  <div class="bg-white rounded-lg shadow mb-3">
    <div @click="showPreview" class="grey lighten-2 px-2 d-flex align-center justify-end">
      <img :src="previewUrl" :alt="planetFeature.id" class="w-100 preview-img">
    </div>

    <div class="mb-3 px-3 pt-3">
      <h4 class="mb-1">Acquired at: {{acquiredAt}}</h4>
      <h4 class="mb-1">Published at: {{publishedAt}}</h4>
      <h4 class="mb-2">Updated at: {{updatedAt}}</h4>
      <Badge class="mx-0 my-0 mb-2">{{ planetFeature.properties.item_type }}</Badge>

      <ul v-if="planetFeature.properties" class="list-reset data-list">
        <li>
          <span>Clear Percent</span>
          <span>{{planetFeature.properties.clear_percent}}%</span>
        </li>
        <li>
          <span>Cloud Cover</span>
          <span>{{planetFeature.properties.cloud_cover}}%</span>
        </li>
        <li>
          <span>Cloud Percent</span>
          <span>{{planetFeature.properties.cloud_percent}}%</span>
        </li>
        <li>
          <span>Heavy Haze Percent</span>
          <span>{{planetFeature.properties.heavy_haze_percent}}%</span>
        </li>
        <li>
          <span>Light Haze Percent</span>
          <span>{{planetFeature.properties.light_haze_percent}}%</span>
        </li>
        <li>
          <span>Shadow Percent</span>
          <span>{{planetFeature.properties.shadow_percent}}%</span>
        </li>
        <li>
          <span>Snow/Ice Percent</span>
          <span>{{planetFeature.properties.snow_ice_percent}}%</span>
        </li>
        <li>
          <span>Sun Azimuth</span>
          <span>{{planetFeature.properties.sun_azimuth}}°</span>
        </li>
        <li>
          <span>Sun Elevation</span>
          <span>{{planetFeature.properties.sun_elevation}}°</span>
        </li>
        <li>
          <span>Visible Percent</span>
          <span>{{planetFeature.properties.visible_percent}}%</span>
        </li>
        <li>
          <span>Visible Confidence Percent</span>
          <span>{{planetFeature.properties.visible_confidence_percent}}%</span>
        </li>
        <li>
          <span>pixel_resolution</span>
          <span>{{ planetFeature.properties.pixel_resolution }}</span>
        </li>
      </ul>
    </div>

    <div class="flex justify-end px-3 pb-3">
      <Button small variant="ghost" size="sm" @click="showPreview">Preview</Button>
      <OrderDialogForm v-if="orderPayload" :order="orderPayload" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

import {formatTime} from "@/utils/datetime"


export default {
  props: {
    planetFeature: {
      type: Object,
      required: true
    },
    featureCode: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      assetType: null
    }
  },
  computed: {
    ...mapGetters({
      settings: 'user/getUserSettings',
      project: 'project/current',
    }),
    acquiredAt () {
      return this.formatTime(this.planetFeature.properties.acquired, this.settings.tz, 'D MMMM YYYY HH:mm')
    },
    updatedAt () {
      return this.formatTime(this.planetFeature.properties.updated, this.settings.tz, 'D MMMM YYYY HH:mm')
    },
    publishedAt () {
      return this.formatTime(this.planetFeature.properties.published, this.settings.tz, 'D MMMM YYYY HH:mm')
    },
    previewUrl () {
      return this.planetFeature._links.thumbnail + '?api_key=ab8256b490b044c4876d7637b696a823';
    },
    previewUrlHigh () {
      return this.planetFeature._links.thumbnail + '?width=2048&api_key=ab8256b490b044c4876d7637b696a823';
    },
    orderPayload () {
      return {
        projectId: this.projectId,
        source: 1,
        featureUuid: this.featureCode,
        itemType: this.planetFeature.properties.item_type,
        itemId: this.planetFeature.id,
        assetType: 'ortho_visual'
      }
    }
  },
  methods: {
    formatTime,
    showPreview() {
      // const map = this.$store.getters['geo/getMap']
      // const planetLayer = this.planetLayer
      // planetLayer.clearLayers()

      // const geometry = L.geoJson(this.planetFeature.geometry, {
      //   fillOpacity: 0,
      //   color: "#009DB1"
      // }).addTo(planetLayer)

      // const bounds = geometry.getBounds()
      // const center = bounds.getCenter()
      // const isTall = bounds.getSouthWest().distanceTo(bounds.getSouthEast()) < bounds.getSouthEast().distanceTo(bounds.getNorthEast())
      // let radius = 0
      // if (isTall) {
      //   radius = bounds.getSouthEast().distanceTo(bounds.getNorthEast()) / 2
      // } else {
      //   radius = bounds.getSouthWest().distanceTo(bounds.getSouthEast()) / 2
      // }

      // const bbox = L.circle(center, {
      //   radius: radius,
      //   opacity: 0,
      //   fillOpacity: 0
      // })

      // bbox.addTo(planetLayer)

      // const imageOverlay = L.imageOverlay(this.previewUrlHigh, bbox.getBounds())

      // imageOverlay.addTo(planetLayer)
      // planetLayer.addTo(map)
      // map.fitBounds(bounds)
    }
  }
}
</script>

<style scoped>
  .preview-img {
    max-height: 230px;
    min-height: 230px;
    object-fit: cover;
  }

  .data-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
