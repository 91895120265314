<script setup>
import {ref} from 'vue'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {Button} from '@/components/ui/button'

const isOpen = ref(false)
const emit = defineEmits(['confirm'])
const props = defineProps({
  title: {
    type: String,
    default: 'Confirm changes',
  },
  description: {
    type: String,
    default: 'Are you sure you want to confirm these changes?',
  },
})

const open = () => {
  isOpen.value = true
}

const cancel = () => {
  isOpen.value = false
}

const confirm = () => {
  isOpen.value = false
  emit('confirm')
}


defineExpose({
  open,
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger as-child>
      <slot>
        <Button variant="ghost" class="w-full h-8 px-2 flex justify-start">Action</Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>{{props.title}}</DialogTitle>
        <DialogDescription>{{props.description}}</DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button @click="cancel" variant="ghost">Cancel</Button>
        <Button @click="confirm">Confirm</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
