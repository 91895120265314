<script setup lang="ts">
import {onBeforeMount, computed, ref} from 'vue'
import {useStore} from 'vuex'
import {formatTime} from '@/utils/datetime.js'
import * as z from "zod";
import {toTypedSchema} from "@vee-validate/zod";

import { MessageSquarePlus } from 'lucide-vue-next'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'

const props = defineProps<{
  featureCode: string
}>()

const isFormOpen = ref(false)

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const notes = computed(() => store.getters['geo/getNotes'])
const notesLength = computed(() => notes.value.length)

onBeforeMount(() => {
  store.dispatch('geo/fetchNotes', props.featureCode)
})

const formSchema = toTypedSchema(z.object({
  text: z.string().min(3).max(500)
}))

const onSubmit = async (values: any) => {
  await store.dispatch('geo/addNote', {
    featureCode: props.featureCode,
    text: values.text
  })
  isFormOpen.value = false
}

const openForm = () => {
  isFormOpen.value = true
}

const closeForm = () => {
  isFormOpen.value = false
}
</script>

<template>
  <div>
    <div class="flex items-center justify-between">
      <h3 class="text-base font-bold mb-2">Notes ({{notesLength}})</h3>
      <Button variant="ghost" class="px-2" @click="openForm">
        <MessageSquarePlus class="w-5 h-5 text-gray-500" />
      </Button>
    </div>

    <Form v-if="isFormOpen" class="space-y-3 mb-4 px-3" :validation-schema="formSchema" @submit="onSubmit">
      <FormField v-slot="{ componentField }" name="text">
        <FormItem>
          <FormLabel>Message</FormLabel>
          <FormControl>
            <Textarea v-bind="componentField" />
          </FormControl>
          <FormMessage />
        </FormItem>
      </FormField>

      <div class="flex justify-end space-x-1">
        <Button size="sm" variant="ghost" @click="closeForm">Close</Button>
        <Button size="sm" type="submit">Send</Button>
      </div>
    </Form>

    <div v-if="notesLength">
      <div v-for="note of notes" class="flex mb-2">
        <div>
          <Avatar class="w-9 h-9">
            <AvatarImage
              v-if="note.avatar_url"
              :src="note.avatar_url"
              :alt="note.display_name"
              :key="note.id"
            />
            <AvatarFallback></AvatarFallback>
          </Avatar>
        </div>
        <div class="ml-2">
          <h4 class="font-bold">{{note.display_name}}</h4>
          <div class="text-xs text-gray-400">{{ formatTime(note.created_at, settings.tz, 'D MMMM YYYY hh:mm') }}</div>
          <div class="mt-1">{{note.text}}</div>
        </div>
      </div>
    </div>
    <div v-else>No notes</div>
  </div>
</template>
