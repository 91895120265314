<script setup>
import {ref, computed, onBeforeMount} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from 'vuex'
import EditForm from './EditForm.vue'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'
import {Button} from '@/components/ui/button'

const store = useStore()
const route = useRoute()
const router = useRouter()

const projectId = route.params.project_id
const project = computed(() => store.getters['project/current'])
const canRead = ref(false)

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId)
  canRead.value = useCheckProjectAccess(project.value, 'project_settings', ['read'])

  if (!canRead.value) {
    router.push({name: 'ProjectHome', params: {id: projectId}})
  }
})
</script>

<template>
  <div class="max-w-[60%] space-y-3" v-if="canRead">
    <div>
      <EditForm v-if="project.project_id" :project="project" />
    </div>
    <hr>
  </div>
</template>
