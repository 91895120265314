<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import { Button } from '@/components/ui/button'
import { ProjectBreadcrumbsItem } from '@/components/breadcrumbs'
import { ChevronRight, House } from 'lucide-vue-next'

const store = useStore()
const projects = computed(() => store.getters['project/getProjects'])
const flatedProjects = computed(() => store.getters['project/getFlatedProjects'])
const projectId = computed(() => parseInt(route.params.project_id))
const featureCode = computed(() => route.params.featureCode)
const feature = computed(() => store.getters['geo/getCurrentFeature'])

const selectedProjectTree = computed(() => {
  if (!projectId || projects.length === 0) {
    return []
  }

  const selectedProject = flatedProjects.value.find(i => i.project_id === projectId.value)
  if (!selectedProject) {
    return []
  }

  const tree = []
  let current = selectedProject
  while (current) {
    tree.unshift(current)
    current = flatedProjects.value.find(i => i.project_id === current.parent_id)
  }

  return tree
})

const router = useRouter()
const route = useRoute()

const resetSelected = () => {
  store.commit('project/SET_CURRENT', null)
  store.commit('search/REMOVE_PARAM', { key: 'project_id' })

  router.push({ name: 'Dashboard' })
}
</script>

<template>
  <div v-if="projectId && selectedProjectTree.length > 0" class="bg-white rounded-full pr-2 inline-flex items-center flex-wrap shadow-md">
    <Button variant="ghost" size="sm" class="rounded-full w-7 h-7 p-1 text-gray-400 hover:text-primary" @click="resetSelected">
      <House class="w-4 h-4" />
    </Button>

    <!-- <div v-for="(matched, idx) in route.matched" :key="idx" class="flex items-center">
      <a :href="matched.path" class="text-gray-400">{{ matched.name }}</a>
      <ChevronRight v-if="idx != Object.keys(route.matched).length - 1" class="w-4 h-4 text-gray-400" />
    </div> -->

    <ProjectBreadcrumbsItem
      v-for="project in selectedProjectTree"
      :project="project"
      :current-project-id="projectId"
    />

    <div v-if="featureCode && feature" class="flex items-center">
      <ChevronRight class="w-4 h-4 text-gray-400" />
      <Button variant="ghost" size="sm" disabled>
        <span class="max-w-[200px] truncate">{{ feature.properties.name }}</span>
      </Button>
    </div>
  </div>
</template>
