<script setup>
import { Button } from '@/components/ui/button'
import {Spinner} from "@/components/ui/spinner"
import {
  CheckCircle2,
  XCircle
} from 'lucide-vue-next'
</script>

<template>
  <div class="bg-white rounded-lg shadow mb-3 px-3 py-3">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="mr-2">
          <CheckCircle2 v-if="order.status === 0" class="text-green-600 w-5 h-5" />
          <XCircle v-else-if="order.status === -1 || order.status === -2" class="text-red-400 w-5 h-5" />
          <Spinner v-else class="w-5 h-5" />
        </div>
        <h3 class="text-base font-bold">{{statusToLabel[order.status]}}</h3>
      </div>
      <div>
        <Button v-if="order.status === 0" size="sm" variant="ghost" @click="showImage">Show image</Button>
      </div>
    </div>
    <div class="mt-1">
      <span class="text-gray-500">{{formatTime(order.created_at, settings.tz, 'D MMMM YYYY HH:mm')}}</span>
    </div>
  </div>
</template>

<script>
import {formatTime} from "@/utils/datetime"
import {mapGetters} from "vuex";

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    feature: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      settings: 'user/getUserSettings',
    })
  },
  data () {
    return {
      statusToLabel: {
        '-4': 'Failed to resize',
        '-3': 'Failed to download',
        '-2': 'Failed to activated',
        '-1': 'Failed',
        0: 'Done',
        1: 'Starting',
        2: 'Activating',
        3: 'Downloading',
        4: 'Resizing'
      }
    }
  },
  methods: {
    formatTime,
    showImage () {
      const map = this.$store.getters['geo/getMap']
      map.fitBounds(geometry.getBounds())
    },
  }
}
</script>
