<script setup>
import {computed, onMounted, onUnmounted, watch} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

import { MapSource, MapLayer } from '@/components/map'
// import FilteringPoints from './FilteringPoints.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const member = computed(() => store.getters['user/getMember'])

const props = defineProps({
  feature: Object,
  map: Object,
  draw: Object
})

const isEdit = computed(() => route.name === 'FeatureEdit')

const originCollection = computed(() => {
  return {
    type: 'FeatureCollection',
    features: [props.feature]
  }
})

onMounted(() => {
  if (isEdit.value) {
   props.draw.add(props.feature)
  }
})

onUnmounted(() => {
  if (isEdit.value) {
    props.draw.delete(props.feature)
  }
})

watch(() => isEdit.value, (value) => {
  if (value) {
    props.draw.add(props.feature)
  } else {
    props.draw.deleteAll()
  }
})

const onLayerClick = (feature) => {
  console.log('feature', feature)
}
</script>

<template>
  <MapSource
    v-if="feature.geometry && !isEdit"
    :map="map"
    type="geojson"
    :id="`page-feature-source-${feature.properties.code}`"
    :data="originCollection"
  >
    <MapLayer
      :map="map"
      :id="`page-feature-layer-fill-${feature.properties.code}`"
      :source="`page-feature-source-${feature.properties.code}`"
      type="fill"
      :options="{
        paint: {
          'fill-color': ['coalesce', ['get', 'color'], '#0069FF'],
          'fill-opacity': 0.1
        },
        filter: ['all', ['!=', '$type', 'Point'], ['!=', '$type', 'LineString']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
    <MapLayer
      :map="map"
      :id="`page-feature-layer-lines-${feature.properties.code}`"
      :source="`page-feature-source-${feature.properties.code}`"
      type="line"
      :options="{
        paint: {
          'line-color': ['coalesce', ['get', 'color'], '#0069FF'],
          'line-width': 2,
          'line-opacity': 1,
        },
        filter: ['!=', '$type', 'Point'],
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>

    <MapLayer
      :map="map"
      :id="`page-feature-layer-point-${feature.properties.code}`"
      :source="`page-feature-source-${feature.properties.code}`"
      type="circle"
      :options="{
        paint: {
          'circle-color': ['coalesce', ['get', 'color'], '#0069FF'],
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff'
        },
        filter: ['all', ['!has', 'point_count'], ['!has', 'thumbUrl'], ['==', '$type', 'Point']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>

    <!-- Image points -->
    <MapLayer
      :map="map"
      :id="`page-feature-layer-image-${feature.properties.code}`"
      :source="`page-feature-source-${feature.properties.code}`"
      type="symbol"
      :options="{
        layout: {
          'icon-image': ['get', 'thumbUrl'],
          'icon-size': 0.1,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'center'
        },
        filter: ['all', ['!has', 'point_count'], ['has', 'thumbUrl']]
      }"
      @click="(e) => onLayerClick(e.features[0])"
    ></MapLayer>
  </MapSource>

  <!-- <FilteringPoints
    v-if="feature.geometry.type === 'LineString' && member?.is_super_user"
    :key="feature.properties.code"
    :feature="feature"
    :map="map"
  /> -->
</template>
