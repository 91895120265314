<script setup>
import { onBeforeMount, computed, ref } from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { firebase } from '@/router'
import { HOST, API } from '@/utils/http'
import axios from 'axios'
import * as Sentry from '@sentry/vue'

import { Spinner } from '@/components/ui/spinner'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'

const router = useRouter()
const store = useStore()
const user = computed(() => store.getters.getUser)

const inProgress = ref(false)
const step = ref(1)
const lastStep = 2

const formSchema = toTypedSchema(z.object({
  orgName: z.string().min(3).max(255),
  projectName: z.string().min(3).max(255)
}))

const fetchMember = async () => {
  try {
    const result = await API({
      method: 'GET',
      url: `${HOST}/member`
    })
    return result.data
  } catch (e) {
    console.log(e)
  }
}

onBeforeMount(async () => {
  const member = await fetchMember()
  if (member) {
    router.push({name: 'Dashboard'})
  } 
})

const fetchInitialData = async () => {
  await store.dispatch('org/fetchOrgs')
  await store.dispatch('project/fetchProjects')
  await store.dispatch('project/fetchAllMembers')
  await store.dispatch('project/fetchAllTags')
}

const onSubmit = async (values) => {
  inProgress.value = true
  const firebaseToken = await firebase.auth.currentUser.getIdToken(true)
  try {
    const response = await axios({
      method: 'POST',
      url: `${HOST}/sign-up`,
      data: {
        email: user.value.email,
        display_name: user.value.displayName ? user.value.displayName : user.value.email,
        org_name: values.orgName,
        project_name: values.projectName,
        firebase_token: firebaseToken
      }
    })

    const tokens = response.data

    localStorage.setItem('accessToken', tokens.access_token)
    localStorage.setItem('refreshToken', tokens.refresh_token)

    const member = await fetchMember()

    if (member) {
      store.dispatch('user/setMember', member)
      store.commit('SET_IS_LOGGEDIN', true)
      Sentry.setUser({email: member.email})
      await fetchInitialData()
      router.push({name: 'Dashboard'})
    }

  } catch (e) {
    inProgress.value = false
    console.log(e)
  }
}

const logoutFirebase = async () => {
  await store.dispatch('user/logoutFirebase')
}
</script>

<template>
  <div class="w-full px-10 ml-3">
    <div class="mb-4">
      <h1 class="font-black text-xl md:text-3xl">Sign Up</h1>
    </div>
    <div class="flex items-center justify-between">
      <div v-if="user" class="flex items-center">
        <Avatar class="w-10 h-10">
          <AvatarImage :src="user.photoURL" :key="user.photoURL"></AvatarImage>
          <AvatarFallback></AvatarFallback>
        </Avatar>
        <div class="ml-2">
          <h3 class="mb-0">Hi, {{ user.displayName }}!</h3>
          <p class="text-sm text-gray-500">{{ user.email }}</p>
        </div>
      </div>
      <Button variant="ghost" size="sm" class="text-gray-500" @click="logoutFirebase">Logout</Button>
    </div>
    
    <div class="w-full mt-10">
      <Form :validation-schema="formSchema" @submit="onSubmit">
        <div v-show="step === 1">
          <FormField v-slot="{ componentField }" name="orgName">
            <FormItem>
              <FormLabel>Organization Name</FormLabel>
              <FormControl>
                <Input v-bind="componentField" :disabled="inProgress" />
              </FormControl>
              <FormDescription>
                The name of your organization.
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div v-show="step === 2">
          <FormField v-slot="{ componentField }" name="projectName">
            <FormItem>
              <FormLabel>Project Name</FormLabel>
              <FormControl>
                <Input v-bind="componentField" :disabled="inProgress" />
              </FormControl>
              <FormDescription>
                The name of your project.
              </FormDescription>
              <FormMessage />
            </FormItem>
          </FormField>
        </div>
        <div class="mt-5 flex justify-between items-center">
          <div>
            <p class="text-sm text-gray-500">{{ step }} / {{ lastStep }}</p>
          </div>
          <div class="flex space-x-2">
            <Button v-if="step !== 1" type="button" variant="secondary" @click="step--">Back</Button>
            <Button v-if="step !== lastStep" type="button" variant="secondary" @click="step++">Next</Button>
            <Button v-if="step === lastStep" type="submit" :disabled="inProgress">
              <span v-if="!inProgress">Sign Up</span>
              <Spinner v-else class="w-3 h-3" />
            </Button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
