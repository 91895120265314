<script setup>
import {ref, watch, computed} from 'vue'
import {useMagicKeys} from '@vueuse/core'
import axios from 'axios'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {debounce} from 'lodash'
import {bbox} from '@turf/turf'
import {API, HOST} from '@/utils/http'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command'
import { Button } from '@/components/ui/button'
import { Search, MapPin, FolderDot, Building2, Tag, Navigation } from 'lucide-vue-next'

const access_token = 'pk.eyJ1IjoibW5ybG1uc3RyIiwiYSI6ImNsc2FibmNrODAyNDMycW9jbDg1a2JoczAifQ.EJdbYQ9kSL1KSymYP0v3Bg'

const router = useRouter()
const store = useStore()
const params = computed(() => store.getters['search/getParams'])
const member = computed(() => store.getters['user/getMember'])
const map = computed(() => store.getters['geo/getMap'])

const open = ref(false)
const keys = useMagicKeys()
const esc = keys['escape']

const suggestions = ref([])
const sortedSuggestions = computed(() => {
  return suggestions.value.sort((a, b) => {
    if (a.name === null) return -1
    return a.name.localeCompare(b.name)
  })
})


const handleOpenChange = () => (open.value = !open.value)

const searchMapbox = async (value) => {
  cleanMapboxSuggestions()

  try {
    const response = await axios.get('https://api.mapbox.com/search/searchbox/v1/suggest', {
      params: {
        q: value,
        access_token: access_token,
        session_token: member.value.code,
        language: 'en',
        limit: 2,
      }
    })

    for (const suggestion of response.data.suggestions) {
      suggestions.value.push({
        suggestion_type: 'mapbox',
        ...suggestion
      })
    }

  } catch (error) {
    cleanMapboxSuggestions()
  }
}

const cleanMapboxSuggestions = () => {
  suggestions.value = suggestions.value.filter(suggestion => suggestion.suggestion_type !== 'mapbox')
}

const searchApi = async (value) => {
  try {
    const response = await API({
      url: `${HOST}/search`,
      method: 'GET',
      params: {
        q: value,
      }
    })

    for (const suggestion of response.data.suggestions) {
      suggestions.value.push({
        suggestion_type: 'api',
        ...suggestion
      })
    }
  } catch (error) {
    console.error(error)
  }
}

const search = debounce(async (e) => {
  suggestions.value = []
  const value = e.target.value

  if (value.length === 0) {
    return
  }

  await searchMapbox(value)
  await searchApi(value)
}, 300)

const focusOnMapboxResult = async (suggestion) => {
  try {
    const response = await axios.get(`https://api.mapbox.com/search/searchbox/v1/retrieve/${suggestion.mapbox_id}`, {
      params: {
        access_token: access_token,
        session_token: member.value.code,
      }
    })

    const feature = response.data.features[0]
    const bounds = bbox(feature)
    map.value.fitBounds(bounds, {
      padding: 100,
      duration: 100
    })
    open.value = false
  } catch (error) {
    console.error(error)
  }
}

const focusOnFeature = (suggestion) => {
  const bounds = bbox(suggestion.geometry)
  map.value.fitBounds(bounds, {
    padding: 100,
    duration: 100
  })
}

const focusOnResult = async (suggestion) => {
  if (suggestion.suggestion_type === 'mapbox') {
    await focusOnMapboxResult(suggestion)
  } else {
    if (suggestion.type === 'feature') {
      focusOnFeature(suggestion)
      console.log('feature', suggestion)
    } else if (suggestion.type === 'project') {
      router.push({
        name: 'Project',
        params: {
          project_id: suggestion.id
        }
      })
    } else if (suggestion.type === 'organization') {
      console.log('organization', suggestion)
    } else if (suggestion.type === 'tag') {
      store.commit('search/ADD_PARAM', {
        key: 'tag_ids',
        value: suggestion.id
      })
    } else if (suggestion.type === 'member') {
      store.commit('search/ADD_PARAM', {
        key: 'member_ids',
        value: suggestion.id
      })
    }
  }

  suggestions.value = []
  open.value = false
}

watch(esc, (v) => {
  if (v) open.value = false
})
</script>

<template>
  <div>
    <Button
      class="md:pr-14 h-full rounded-lg"
      variant="outline"
      :aria-expanded="open"
      @click="handleOpenChange"
    >
      <Search class="h-4 w-4 opacity-50" />
      <span class="hidden md:flex ml-3 text-gray-600">Search</span>
    </Button>
    <CommandDialog :open="open" @update:open="(v) => (open = v)">
      <CommandInput placeholder="Search" @input="search" />
      <CommandList>
        <CommandGroup v-if="sortedSuggestions?.length">
          <CommandItem v-for="(item, index) in sortedSuggestions" :key="index" :value="item" @click="focusOnResult(item)">
            <div class="w-5 h-5 flex items-center justify-center mr-1">
              <MapPin v-if="item.suggestion_type === 'mapbox'" class="h-4 w-4 text-gray-400" />
              <FolderDot v-if="item.type === 'project'" class="h-4 w-4 text-gray-400" />
              <Building2 v-if="item.type === 'organization'" class="h-4 w-4 text-gray-400" />
              <Tag v-if="item.type === 'tag'" class="h-4 w-4 text-gray-400" />
              <Navigation v-if="item.type === 'feature'" class="h-4 w-4 text-gray-400" />
              <Avatar class="w-5 h-5" v-if="item.type === 'member'">
                <AvatarImage v-if="item.avatar_url" :src="item.avatar_url" alt="member" />
                <AvatarFallback></AvatarFallback>
              </Avatar>
            </div>

            <span>{{ item.name }}</span>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </CommandDialog>
  </div>
</template>
