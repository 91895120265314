<script setup >
import {ref, computed, onMounted} from 'vue'
import {useStore} from 'vuex'

import {Basemaps} from '@/components/map'

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])


onMounted(() => {
})
</script>

<template>
  <div class="flex justify-between min-w-full">
    <Basemaps />
  </div>
</template>
