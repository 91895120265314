<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { API, HOST } from '@/utils/http'
import {bbox} from '@turf/turf'

import { toast } from '@/components/ui/toast'
import { FeaturesList } from '@/components/features'
import { Spinner } from '@/components/ui/spinner'
import {
  Pagination,
  PaginationEllipsis,
  PaginationFirst,
  PaginationLast,
  PaginationList,
  PaginationListItem,
  PaginationNext,
  PaginationPrev,
} from '@/components/ui/pagination'
import { Button } from '@/components/ui/button'

const total = ref(0)
const page = ref(1)
const limit = ref(15)
const offset = computed(() => (page.value - 1) * limit.value)

const store = useStore()
const params = computed(() => store.getters['search/getParams'])
const totalInFocus = computed(() => store.getters['geo/getTotalOnMap'])
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const map = computed(() => store.getters['geo/getMap'])
const features = computed(() => store.getters['geo/getFeedFeatures'])

const viewportOnly = ref(localStorage.getItem('viewportOnly') === 'true')
const isFetchingBoundary = ref(false)

const router = useRouter()
const route = useRoute()
const projectId = computed(() => parseInt(route.params.project_id))

const featuresLength = computed(() => features.value.features?.length)
const isFeatureCreated = computed(() => store.getters['geo/getIsFeatureCreated'])
const isLoading = ref(false)

const fetchFeatures = async () => {
  if (!projectId.value) return

  isLoading.value = true
  try {
    const paramsMap = params.value.reduce((acc, param) => {
      acc[param.key] = param.value;
      return acc
    }, {})

    const payload = {
      shared: paramsMap['shared'],
      has_resources: paramsMap['has_resources'],
      start_date: paramsMap['start_date'],
      end_date: paramsMap['end_date']
    }

    const fullPayload = {
      ...payload,
      isMeta: true,
      project_id: projectId.value,
      south: viewportOnly.value ? paramsMap['south'] : null,
      north: viewportOnly.value ? paramsMap['north'] : null,
      east: viewportOnly.value ? paramsMap['east'] : null,
      west: viewportOnly.value ? paramsMap['west'] : null,
      limit: limit.value,
      offset: offset.value
    }

    await store.dispatch('geo/fetchFeatures', fullPayload)

    const response_total = await API({
      method: 'GET',
      url: `${HOST}/geo/features/${projectId.value}/total`,
      params: payload,
    })

    total.value = response_total.data
  } catch (e) {
    console.log(e)
    if (e.response?.status === 403) {
      toast({title: `You don't have access to this data`})
    } else if (e.response?.status === 404) {
      toast({title: `Data not found`})
    } else {
      throw e
    }
  }

  isLoading.value = false
}

onMounted(async () => {
  if (isLoggedIn.value) {
    await fetchFeatures()
  }
})

watch(
  () => isLoggedIn.value,
  async (newValue, oldValue) => {
    if (newValue && !oldValue) {
      await fetchFeatures()
    }
  }
)

watch(
  () => projectId.value,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchFeatures()
    }
  }
)

watch(
  () => params.value,
  async (newValue, oldValue) => {
    if (!oldValue) return
    await fetchFeatures()
  },
  { deep: true }
)

watch(
  () => page.value,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchFeatures()
    }
  }
)

watch(
  () => isFeatureCreated.value,
  async (value) => {
    if (value) {
      await fetchFeatures()
      store.commit('geo/SET_FEATURE_CREATED', false)
    }
  }
)

const paginationUpdated = async (pageCount) => {
  page.value = pageCount
}

const focusOnFilteredData = async () => {
  isFetchingBoundary.value = true
  const boundary = await store.dispatch('geo/fetchProjectBoundary', projectId.value)

  if (!boundary) {
    isFetchingBoundary.value = false
    return
  }

  const geometry = boundary.geometry

  if (!geometry || !Array.isArray(geometry.coordinates) || geometry.coordinates.length === 0) {
    isFetchingBoundary.value = false
  }

  const bounds = bbox(geometry)
  if (!bounds || bounds.length !== 4) {
    isFetchingBoundary.value = false
  }
  map.value.fitBounds(bounds)
  isFetchingBoundary.value = false
}

const toggleViewportOnly = async (value) => {
  isLoading.value = true
  viewportOnly.value = value
  localStorage.setItem('viewportOnly', value)
  await fetchFeatures()

  if (!value) {
    await focusOnFilteredData()
  }
  isLoading.value = false
}
</script>

<template>
  <div class="px-1 py-1 sticky top-0 bg-gray-100">
    <div class="flex items-center space-x-1">
      <Button
        @click="toggleViewportOnly(false)"
        class="w-full font-semibold"
        :class="{'text-primary shadow-sm hover:text-primary hover:bg-white': !viewportOnly}"
        :variant="!viewportOnly ? 'white' : 'ghost'"
        size="sm"
      >
        All ({{ total }})
      </Button>
      <Button
        @click="toggleViewportOnly(true)"
        class="w-full font-semibold"
        :class="{'text-primary shadow-sm hover:text-primary hover:bg-white': viewportOnly}"
        :variant="viewportOnly ? 'white' : 'ghost'"
        size="sm"
      >
        Map ({{ totalInFocus }})
      </Button>
    </div>
  </div>
  <div v-if="featuresLength > 0">
    <FeaturesList v-if="!isLoading" :features="features.features" />
    <div v-if="isLoading" class="py-7 flex items-center justify-center">
      <Spinner class="w-12 h-12" />
    </div>

    <div v-if="totalInFocus > limit && !isLoading" class="p-3">
      <Pagination
        v-slot="{ page }"
        :total="totalInFocus"
        :sibling-count="1"
        show-edges
        :default-page="page"
        :items-per-page="limit"
        @update:page="paginationUpdated"
      >
        <PaginationList v-slot="{ items }" class="flex items-center gap-1">
          <PaginationFirst />
          <PaginationPrev />

          <template v-for="(item, index) in items">
            <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
              <Button class="w-10 h-10 p-0" :variant="item.value === page ? 'default' : 'outline'">
                {{ item.value }}
              </Button>
            </PaginationListItem>
            <PaginationEllipsis v-else :key="item.type" :index="index" />
          </template>

          <PaginationNext />
          <PaginationLast />
        </PaginationList>
      </Pagination>
    </div>
  </div>
  <div v-else class="p-3 w-full h-full flex items-center justify-center">
    <div v-if="!isLoading && featuresLength === 0" class="mt-6 text-center">
      <div v-if="viewportOnly">
        <h3 class="text-base font-bold">No data in view</h3>
        <p class="text-gray-500">Try panning or zooming out to find data.</p>
        <Button
          @click="toggleViewportOnly(false)"
          class="text-primary mt-3"
          variant="secondary"
          size="sm"
        >
          Switch to all data
        </Button>
      </div>
      <div v-else>
        <h3 class="text-base font-bold">No data available</h3>
        <p class="text-gray-500">Adjust filters or select a different project.</p>
      </div>
    </div>
  </div>
</template>
