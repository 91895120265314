import { HOST, API } from '@/utils/http'
import { v4 as uuidv4 } from 'uuid'


export const fetchFeatures = async ({commit}, payload) => {
  // ugly one: isMeta is used to fetch features for the feed, it's mean to load more data
  try {
    if (payload.isMeta) {
      const response = await API({
        method: 'GET',
        url: `${HOST}/geo/features/${payload.project_id}`,
        params: payload
      })
      commit('SET_FEED_FEATURES', {
        features: response.data?.features
      })
    } else {
      const response = await API({
        method: 'GET',
        url: `${HOST}/geo/features-v2/${payload.project_id}`,
        params: payload
      })
      commit('SET_FEATURES', {
        features: response.data?.features,
        isMerge: false
      })
      commit('SET_TOTAL_ONMAP', response.data?.total)
    }
  } catch (e) {
    if (!e.response) {
      commit('SET_ALERT', {type: 'error', text: `Can't fetch features`}, {root: true})
      return
    }

    if (e.response.status === 403) {
      commit('SET_ALERT', {type: 'error', text: `You don't have permission to access this project`}, {root: true})
    } else {
      throw new Error(e)
    }
  }
}

export const fetchFeature = async ({commit}, code) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/feature/${code}`
    })

    const feature = response.data

    commit('SET_CURRENT_FEATURE', {feature: feature})
  } catch (e) {
    if (!e.response) {
      commit('SET_ALERT', {type: 'error', text: `Can't fetch report`}, {root: true})
      return
    }

    if (e.response.status === 403) {
      commit('SET_ALERT', {type: 'error', text: `You don't have permission to access this report`}, {root: true})
    }

    if (e.response.status === 404) {
      commit('SET_ALERT', {type: 'error', text: `Report not found`}, {root: true})
    }
  }
}

export const saveResource = async ({commit}, payload) => {
  try {
    const response = await API({
      headers: {'content-type': 'multipart/form-data'},
      method: 'POST',
      url: `${HOST}/geo/feature/${payload.code}/resource`,
      params: {
        uuid: payload.uuid
      },
      data: {
        file: payload.file
      }
    })
  } catch (e) {
    throw new Error(e)
  }
}

export const saveFeature = async ({commit, dispatch, state}, request) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/geo/feature?project_id=${request.project_id}`,
      data: request.feature
    })

    const feature = response.data
    const projectStr = `project-${request.project_id}-`

    if (state.map.getSource(`${projectStr}reports_points`)) {
      if (feature.geometry.type === 'Point') {
        state.map.getSource(`${projectStr}reports_points`).setData(feature)
      } else {
        state.map.getSource(`${projectStr}reports_mixed`).setData(feature)
      }
    }

    if (request.files) {
      for (const file of request.files) {
        await dispatch('saveResource', {
          code: feature.properties.code,
          file: file,
          uuid: uuidv4()
        })
      }
    }

    return feature
  } catch (e) {
    throw new Error(e)
  }
}

export const updateFeature = async ({commit, state}, payload) => {
  try {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/geo/feature/${payload.feature.properties.code}`,
      data: payload.feature
    })

    const feature = response.data
    const projectStr = `project-${payload.project_id}-`

    if (state.map.getSource(`${projectStr}reports_points`)) {
      if (feature.geometry.type === 'Point') {
        state.map.getSource(`${projectStr}reports_points`).setData(feature)
      } else {
        state.map.getSource(`${projectStr}reports_mixed`).setData(feature)
      }
    }

    commit('SET_CURRENT_FEATURE', {feature: feature})
    return feature
  } catch (e) {
    throw new Error(e)
  }
}

export const pairToProject = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/geo/feature/${payload.properties.code}/pair`
    })

    console.log(response.data)
  } catch (e) {
    throw new Error(e)
  }
}

export const updateFeatureGeometry = async ({commit}, feature) => {
  try {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/geo/feature/${feature.properties.code}/geometry`,
      data: feature.geometry
    })
  } catch (e) {
    throw new Error(e)
  }
}


export const deleteFeature = async ({ commit }, code) => {
  try {
    await API({
      method: 'DELETE',
      url: `${HOST}/geo/feature/${code}`
    })

    commit('DELETE_FEATURE', code)
  } catch(e) {
    throw new Error(e)
  }
}

// Beacons
export const fetchLatestBeacons = async ({commit}, projectId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/geo/beacons/${projectId}`
    })

    for (const beacon of response.data) {
      commit('NEXT_BEACON', beacon)
    }
  } catch (e) {
    throw new Error(e)
  }
}

// Notes
export const fetchNotes = async ({commit}, featureCode) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/notes/${featureCode}`
    })

    commit('SET_NOTES', response.data)
  } catch (e) {
    throw new Error(e)
  }
}

export const addNote = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/notes/${payload.featureCode}`,
      data: {
        text: payload.text
      }
    })

    commit('ADD_NOTE', response.data)
  } catch (e) {
    throw new Error(e)
  }
}

export const deleteNote = async ({commit}, noteId) => {
  try {
    await API({
      method: 'DELETE',
      url: `${HOST}/notes/${noteId}`
    })

    commit('DELETE_NOTE', noteId)
  } catch (e) {
    throw new Error(e)
  }
}

export const fetchLayersToken = async ({commit}, payload) => {
  try {
    const response = await API({
      methods: 'GET',
      url: `${HOST}/token/geo/1`,
    })

    commit('SET_TILES_TOKEN', response.data.token)
    commit('SET_TILES_SETTINGS', response.data.settings)
  } catch (error) {
    commit('SET_TILES_TOKEN', null)
    commit('SET_TILES_SETTINGS', null)
  }
}

export const fetchLayers = async ({commit}, payload) => {
  try {
    const response = await API({
      methods: 'GET',
      url: `/tulum/tiles/mosaics/1`,
    })
    const mosaics = response.data
    mosaics.globals.reverse()
    commit('SET_LAYERS', mosaics)
  } catch (e) {
    throw new Error(e)
  }
}

export const mapReady = async ({commit}, payload) => {
  commit('SET_MAP_READY', true)
}

// Report Templates

export const createReportTemplate = async ({commit}, payload) => {
  try {
    const { template, icon } = payload
    const response = await API({
      method: 'POST',
      url: `${HOST}/project/${template.project_id}/templates`,
      data: template
    })

    const newTemplate = response.data

    if (icon) {
      const formData = new FormData()
      formData.append('icon', icon)
      const response = await API({
        method: 'PUT',
        url: `${HOST}/project/${template.project_id}/templates/${newTemplate.id}/upload-icon`,
        data: formData
      })

      commit('ADD_REPORT_TEMPLATE', response.data)
    } else {
      commit('ADD_REPORT_TEMPLATE', newTemplate)
    }

  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't create report template`}, {root: true})
  }
}

// headers: {'content-type': 'multipart/form-data'},


export const updateReportTemplate = async ({commit}, payload) => {
  try {
    const { project_id, template, icon } = payload
    const response = await API({
      method: 'PUT',
      url: `${HOST}/project/${project_id}/templates/${template.id}`,
      data: template
    })

    const newTemplate = response.data

    if (icon) {
      const formData = new FormData()
      formData.append('icon', icon)
      const response = await API({
        method: 'PUT',
        url: `${HOST}/project/${project_id}/templates/${newTemplate.id}/upload-icon`,
        data: formData
      })

      commit('UPDATE_REPORT_TEMPLATE', response.data)
    } else {
      commit('UPDATE_REPORT_TEMPLATE', newTemplate)
    }
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't update report template`}, {root: true})
  }
}

export const fetchProjectReportTemplates = async ({commit}, projectId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project/${projectId}/templates`
    })

    commit('SET_REPORT_TEMPLATES', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't fetch report templates`}, {root: true})
  }
}


export const deleteReportTemplate = async ({commit}, payload) => {
  try {
    const { project_id, template } = payload
    await API({
      method: 'DELETE',
      url: `${HOST}/project/${project_id}/templates/${template.id}`
    })

    commit('DELETE_REPORT_TEMPLATE', template)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't delete report template`}, {root: true})
  }
}

export const fetchProjectBoundary = async ({commit}, projectId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project/${projectId}/boundary`
    })

    return response.data
  } catch (e) {
    if (!e.response) {
      commit('SET_ALERT', {type: 'error', text: `Can't fetch project boundary`}, {root: true})
      return
    }

    if (e.response.status === 403) {
      commit('SET_ALERT', {type: 'error', text: `You don't have permission to access this project boundary`}, {root: true})
    } else {
      throw new Error(e)
    }
  }
}
