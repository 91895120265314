<script setup>
import {ref, watch, computed} from 'vue'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { Spinner } from '@/components/ui/spinner'

import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import {NotepadTextDashed, CircleMinus} from 'lucide-vue-next'

const store = useStore()

const props = defineProps({
  editMode: Boolean,
  template: Object,
  projectId: Number
})

const isOpen = ref(false)
const isProcessing = ref(false)

const close = () => {
  isOpen.value = false
}

const MAX_FILE_SIZE = 100 * 100 * 5
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png", "image/webp"]
const ACCEPTED_IMAGE_RESOLUTION = { width: 200, height: 200 }

const units = ['mi', 'in', 'ft', 'lb', 'm', 'km', 'kg', 'm/s']
const geometryChoices = ['Point', 'LineString', 'Polygon']
const defaultTypes = [
  { name: 'Text', value: 0 },
  { name: 'Number', value: 1 },
  { name: 'Boolean', value: 2 },
  { name: 'Textarea', value: 3 },
  { name: 'Radiogroup', value: 4 },
]


const schema = z.object({
  name: z.string().default(props.editMode ? props.template.name : ''),
  color: z.string().default(props.editMode ? props.template.color : '#0059ff'),
  geometry: z.string().default(props.editMode ? props.template.geometry_type : geometryChoices[0]),
  shared: z.boolean().default(props.editMode ? props.template.shared : false),
  geofence: z.boolean().default(props.editMode ? props.template.geofence : false),
  geofenceEvents: z.string().default(props.editMode ? props.template.geofence_events : null).optional(),
  imageRequired: z.boolean().default(props.editMode ? props.template.image_required : false),
  icon: z.any()
    .optional()
    .refine(
      (file) => file === undefined || ACCEPTED_IMAGE_TYPES.includes(file?.type),
      ".jpg, .jpeg, .png and .webp files are accepted."
    )
    .refine((file) => file === undefined || file?.size <= MAX_FILE_SIZE, `Max file size is ${MAX_FILE_SIZE / 1000}KB`)
})


const formSchema = toTypedSchema(schema)

const isDataFields = ref(props.editMode ? !!props.template.data_fields : false)
const dataFields = ref(props.editMode ? JSON.parse(JSON.stringify(props.template.data_fields)) : [])

const addDataField = () => {
  dataFields.value.push({ key: '', type: 0, options: []})
}
const removeDataField = (index) => {
  dataFields.value.splice(index, 1)
}

const addOption = (field) => {
  field.options.push({ value: '', label: '' });
}
const removeOption = (field, index) => {
  field.options.splice(index, 1);
}

const onSubmit = async (values) => {
  isProcessing.value = true
  const payload = {
    project_id: props.projectId,
    name: values.name,
    color: values.color,
    geometry_type: values.geometry,
    shared: values.shared,
    geofence: values.geofence,
    geofence_events: values.geofenceEvents,
    data_fields: dataFields.value.length > 0 ? dataFields.value : null,
    image_required: values.imageRequired
  }

  console.log('payload', payload)


  if (props.editMode) {
    await store.dispatch('geo/updateReportTemplate', {
      project_id: props.projectId,
      template: { ...props.template, ...payload },
      icon: values.icon
    })
  } else {
    await store.dispatch('geo/createReportTemplate', {
      template: payload,
      icon: values.icon
    })
  }

  await store.dispatch('geo/fetchProjectReportTemplates', props.projectId)

  toast({
    title: `Template ${values.name} created`,
  })

  isProcessing.value = false
  close()
}

const selectedIcon = ref(null)
const selectedIconLink = computed(() => {
  if (!selectedIcon.value) return null
  return URL.createObjectURL(selectedIcon.value)
})
const setSelectedIcon = (event) => {
  selectedIcon.value = event.target.files[0]
}
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger class="w-full" as-child>
      <slot>
        <Button variant="ghost" class="w-full h-8 px-2 flex justify-start">{{ props.editMode ? 'Edit' : 'Create' }}</Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px] max-h-[80vh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle v-if="editMode">Edit template</DialogTitle>
        <DialogTitle v-else>Create template</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit" v-slot="{ getValues }">
        <div class="flex space-x-3">
          <div class="basis-5/6">
            <FormField v-slot="{ componentField }" name="name">
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input v-bind="componentField" />
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
          <div class="basis-1/6">
            <FormField v-slot="{ componentField }" name="color">
              <FormItem>
                <FormLabel>Color</FormLabel>
                <FormControl>
                  <div class="pt-1">
                    <label for="colorPicker" :style="`background-color: ${componentField.modelValue}`" class="flex w-8 h-8 rounded-full">
                      <input id="colorPicker" class="mr-1 w-full h-full invisible" type="color" :value="componentField.modelValue" v-bind="componentField" />
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
          </div>
        </div>

        <FormField v-slot="{ componentField }" name="geometry">
          <FormItem>
            <FormLabel>Geometry</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem v-for="choice in geometryChoices" :key="choice" :value="choice">{{ choice }}</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ value, handleChange }" name="shared">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Shared</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ value, handleChange }" name="imageRequired">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Image required</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-if="getValues().geometry === 'Polygon'" v-slot="{ value, handleChange }" name="geofence">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Geofence</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div>
          <FormField
            v-if="getValues().geofence"
            v-slot="{ componentField }"
            name="geofenceEvents"
          >
            <FormItem>
              <FormControl>
                <RadioGroup v-bind="componentField" class="flex space-x-3">
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="in" />
                    </FormControl>
                    <FormLabel class="font-normal">In</FormLabel>
                  </FormItem>
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="out" />
                    </FormControl>
                    <FormLabel class="font-normal">Out</FormLabel>
                  </FormItem>
                  <FormItem class="flex items-center space-y-0 gap-x-3">
                    <FormControl>
                      <RadioGroupItem value="both" />
                    </FormControl>
                    <FormLabel class="font-normal">Both</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>
            <FormMessage />
          </FormField>
        </div>

        <div class="flex items-center">
          <Switch :checked="isDataFields" @update:checked="() => isDataFields = !isDataFields" />
          <Label class="ml-2">Add data fields</Label>
        </div>

        <FormField v-if="isDataFields" v-slot="{ value }" name="data_fields">
          <FormItem>
            <FormLabel>Data Fields</FormLabel>
            <FormControl>
              <div v-for="(field, index) in dataFields" :key="index" class="flex flex-col space-y-2">
                <div class="flex flex-row space-x-2">
                  <div class="w-full">
                    <Input v-model="field.key" placeholder="Name" />
                  </div>
                  <div>
                    <Select v-model="field.type">
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem v-for="type in defaultTypes" :key="type" :value="type.value">{{ type.name }}</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <div>
                    <Button type="button" variant="ghost" @click="removeDataField(index)" v-if="dataFields.length > 0" class="px-1 text-red-400 hover:text-red-500">
                      <CircleMinus class="w-5 h-5" />
                    </Button>
                  </div>
                </div>

                <div v-if="field.type === 4" class="border border-gray-300 rounded p-2 space-y-1">
                  <FormLabel>Options</FormLabel>
                  <div v-for="(option, optionIndex) in field.options" :key="optionIndex" class="flex flex-row space-x-2">
                    <div class="w-full">
                      <Input v-model="field.options[optionIndex]" placeholder="Option" />
                    </div>
                    <div>
                      <Button type="button" variant="ghost" @click="removeOption(field, optionIndex)" class="px-1 text-red-400 hover:text-red-500">
                        <CircleMinus class="w-5 h-5" />
                      </Button>
                    </div>
                  </div>
                  <Button type="button" @click="field.options.push('')" variant="secondary" size="sm">+ Add Option</Button>
                </div>
              </div>

              <Button type="button" @click="addDataField" variant="secondary" size="sm" :disabled="dataFields.length > 30">+ Add Field</Button>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="icon">
          <FormItem>
            <FormLabel>Icon</FormLabel>
            <FormControl>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .webp"
                @change="setSelectedIcon"
                v-bind="componentField"
                class="text-sm"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <div v-if="selectedIcon">
          <img :src="selectedIconLink" alt="icon" width="30" height="30" />
        </div>
        <div v-else-if="props.editMode && props.template">
          <img v-if="props.template.s3_icon" :src="props.template.s3_icon" alt="icon" width="30" height="30" />
          <NotepadTextDashed v-else />
        </div>

        <DialogFooter>
          <div class="mt-4">
            <Button variant="ghost" type="button" @click="close">Close</Button>
            <Button type="submit" v-if="editMode" :disabled="isProcessing">
              <Spinner v-if="isProcessing" class="w-4 h-4 mr-2" />
              <span v-else>Save changes</span>
            </Button>
            <Button type="submit" v-else :disabled="isProcessing">
              <Spinner v-if="isProcessing" class="w-4 h-4 mr-2" />
              <span v-else>Create</span>
            </Button>
          </div>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
