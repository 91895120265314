<script setup>
import {ref, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toTypedSchema} from '@vee-validate/zod'
import * as z from 'zod'
import { cn } from '@/lib/utils'

import { Check, ChevronsUpDown } from 'lucide-vue-next'

import { Button } from '@/components/ui/button'
import { DialogConfirm } from '@/components/dialogs'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form"
import {Input} from "@/components/ui/input"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import { useCheckProjectAccess } from '@/composables/useCheckAccess'

const store = useStore()
const router = useRouter()
const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
})

const formData = ref(null)
const dialogConfirm = ref(null)
const isCountryOpen = ref(false)
const uiTypes = [
  {label: 'Default', value: 'Default'},
  {label: 'Fisher', value: 'Fisher'},
  {label: 'Worker Welfare', value: 'Worker Welfare'},
]

const filterCountries = (list, search) => list.filter(i => i.label.toLowerCase().includes(search.toLowerCase()))

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default(props.project.project_name),
  description: z.string().min(3).max(255).default(props.project.project_desc),
  ui: z.string().nullable().default(props.project.ui)
}))

const onSubmit = async (values) => {
  formData.value = values

  await store.dispatch('project/updateProject', {
    project_name: formData.value.name,
    project_desc: formData.value.description,
    project_tags: [],
    project_id: props.project.project_id,
    organization_id: props.project.organization_id,
    country: null,
    ui: formData.value.ui
  })
}

onBeforeMount(() => {
  if (!useCheckProjectAccess(props.project, 'project_settings', ['update'])) {
    router.push({name: 'ProjectHome', params: {id: props.project.project_id}})
  }
})
</script>

<template>
  <Form
    v-slot="{ setValues }"
    :validation-schema="formSchema"
    @submit="onSubmit"
    class="space-y-3"
  >
    <FormField v-slot="{ componentField }" name="name">
      <FormItem>
        <FormLabel>Name</FormLabel>
        <FormControl>
          <Input v-bind="componentField" />
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="description">
      <FormItem>
        <FormLabel>Description</FormLabel>
        <FormControl>
          <Input v-bind="componentField" />
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="ui">
      <FormItem>
        <FormLabel>Workflow</FormLabel>
        <FormControl>
          <Select v-bind="componentField">
            <SelectTrigger>
              <SelectValue placeholder="Select a type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem
                  v-for="(i, index) of uiTypes"
                  :key="index"
                  :value="i.value"
                >
                  {{i.label}}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </FormControl>
        <FormMessage />
      </FormItem>
    </FormField>

    <div class="flex justify-end">
      <Button>Save</Button>
    </div>
  </Form>
</template>
