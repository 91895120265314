<script setup>
import { ref, onMounted, computed } from 'vue'
import { HOST, API } from '@/utils/http'
import { useStore } from 'vuex'

import { LayerList } from '@/components/layers'
import { Button } from '@/components/ui/button'

const props = defineProps({
  projectId: Number,
})

const store = useStore()
const project = computed(() => store.getters['project/current'])

const features = ref(null)

onMounted(async () => {
  const response = await API({
    method: 'GET',
    url: `${HOST}/geo/features/${props.projectId}/tasks`
  })

  if (response.status !== 200) {
    features.value = null
    return
  }

  features.value = response.data.features
})
</script>

<template>
  <!-- <div class="flex justify-end mx-2 mb-1">
    <Button variant="secondary" size="sm">New Layer</Button>
  </div> -->
  <div>
    <div v-if="features?.length" v-for="feature in features" :key="feature">
      <LayerList :layers="feature.properties.tasks" :feature="feature" />
    </div>
    <div v-else class="text-center py-5 px-1">
      <h3 class="font-bold">No layers founds</h3>
      <p>Select a project or add new layers</p>
    </div>
  </div>
</template>
