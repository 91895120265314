<script setup lang="ts">
import {computed, onBeforeMount} from 'vue'
import {useRoute} from 'vue-router'
import { useStore } from 'vuex'

import SettingsForm from './SettingsForm.vue'
import {OrgAvatarForm, ApiKeysList, ApiKeysListCreate} from '@/pages/Organization/Settings'

const store = useStore()
const route = useRoute()

const orgId = route.params.org_id
const org = computed(() => store.getters['org/getCurrent'])
const apikeys = computed(() => store.getters['org/getApikeys'])
const apikeysLength = computed(() => apikeys.value.length)

onBeforeMount(async () => {
  await store.dispatch('org/fetchApikeys', orgId)
})
</script>

<template>
<div v-if="org">
  <div class="mb-1 flex items-center">
    <div class="basis-1/2">
      <h3 class="text-base font-bold">Settings</h3>
      <div class="mt-3">
        <SettingsForm :org="org" />
      </div>
    </div>
  </div>

  <div class="mt-6 mb-2 flex items-center">
    <div class="basis-1/2">
      <h3 class="text-base font-bold">Avatar</h3>
      <div class="mt-3">
        <OrgAvatarForm :org="org" />
      </div>
    </div>
  </div>

  <div class="mt-6 mb-2">
    <div class="flex items-center">
      <div class="basis-1/2">
        <h3 class="text-base font-bold mb-2">API Keys ({{apikeysLength}})</h3>
      </div>
      <div class="basis-1/2 flex justify-end">
        <ApiKeysListCreate :org="org" />
      </div>
    </div>
    <ApiKeysList v-if="apikeysLength" :org="org" :apikeys="apikeys" :key="apikeysLength" />
  </div>
</div>
</template>
