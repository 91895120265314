<script>
import { mapGetters } from 'vuex'
import { WS } from '@/services/ws'

const PROJECT_FEATURES_TOPIC = {
  commonName: 'projects.features',
  name(id) {
    return `${this.commonName}(${id})`
  }
}


export default {
  name: 'WsHandler',
  data() {
    return {
      cleanUpTimer: null,
      projectId: null,
      ws: null,
      topicToHandlerMap: {
        [PROJECT_FEATURES_TOPIC.commonName]: this.handleProjectEvent
      }
    }
  },
  computed: {
    ...mapGetters({
      projects: 'project/getProjects'
    })
  },
  watch: {
    projects (val, oldVal) {
      if (val.length && oldVal.length === 0) {
        for (let project of this.projects) {
          this.sub([PROJECT_FEATURES_TOPIC.name(project.project_id)])
        }
      }
    }
  },
  created() {
    this.connectWS()
  },
  beforeDestroy() {
    for (let project of this.projects) {
      this.unsub([PROJECT_FEATURES_TOPIC.name(project.project_id)])
    }
  },
  methods: {
    getProtocol() {
      if (location.protocol === 'https:') {
        return 'wss'
      } else
        return 'ws'
    },
    connectWS() {
      const url = `${this.getProtocol()}://${window.location.host}/api/3/ws`
      // const url = 'ws://localhost:8000/api/3/ws'
      this.ws = WS(url, this.onMsg, this.onOpen, this.onClose)
    },
    onMsg(topic, event, data) {
      if (topic) {
        const truncatedTopic = this.truncTopic(topic)
        const handler = this.topicToHandlerMap[truncatedTopic]
        if (handler) handler(topic, event, data)
      }
    },
    onClose() {
      clearTimeout(this.cleanUpTimer)
      this.cleanUpTimer = null

      console.log('WS closed')
      setTimeout(() => {
        console.log('WS reconnecting')
        this.connectWS()
      }, 100)
    },
    onOpen() {
      console.log('open')
      this.cleanUpTimer = setInterval(() => {
        this.$store.commit('geo/CLEANUP_BEACONS')
      }, 1000 * 60 * 2)
    },
    sub(topics) {
      topics.forEach(topic => {
        this.ws.subscribe(topic)
      })
    },
    unsub(topics) {
      topics.forEach(topic => {
        this.ws.unsubscribe(topic)
      })
    },
    truncTopic(topic) {
      return topic.split('(')[0]
    },
    convertBeacon(beacon) {
      const coords = [beacon.lone6, beacon.late6]
      let rel_ts = null
      if (beacon.rel_ts.toString().length === 10) {
        rel_ts = beacon.rel_ts * 1000
      } else if (beacon.rel_ts.toString().length === 13) {
        rel_ts = beacon.rel_ts
      }

      const props = {
        id: beacon.id,
        member_id: beacon.member_id,
        type: beacon.type,
        project_id: beacon.project_id,
        member_avatar_url: beacon.member_avatar_url,
        member_display_name: beacon.member_display_name,
        rel_ts: rel_ts,
      }
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: coords
        },
        properties: props
      }
    },
    async handleProjectEvent(topic, event, data) {
      switch (event) {
        case 'task':
          this.$store.commit('planet/UPDATE_ORDER_STATUS', data)
          break
        case 'beacon.next':
          const geoJson = this.convertBeacon(data.beacon)
          this.$store.commit('geo/NEXT_BEACON', geoJson)
          break
        case 'beacon.close':
          const memberId = data.beacon.member_id;
          this.$store.commit('geo/REMOVE_BEACON', memberId)
          break
        default:
          break
      }
    }
  },
  render() {
    return '';
  }
}
</script>
