export const getters = {
  getIsLoggedIn: state => {
    return state.isLoggedIn
  },
  isLoading: state => {
    return state.isLoading
  },
  getMember: state => {
    return state.user.member
  },
  getUser: state => {
    return state.user.info
  },
  getUserSettings: state => {
    return state.userSettings
  },
  getAppSettings: state => {
    return state.appSettings
  },
  getCurrentUser: state => {
    return state.user
  },
  getAlerts: state => {
    return state.alerts
  }
}
