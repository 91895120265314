<script setup lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'
import moment from 'moment-timezone'
// import {formatTime} from "@/utils/datetime";

import type {Member} from '@/types'
import {toTypedSchema} from '@vee-validate/zod'
import * as z from 'zod'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {Input} from '@/components/ui/input'
import {toast} from '@/components/ui/toast'

const store = useStore()

const props = defineProps<{
  member: Member
}>()

const settings = computed(() => store.getters['user/getUserSettings'])
const tzNames = moment.tz.names()
const tzChoices = tzNames.map((tz) => {
  return {
    value: tz,
    text: `${tz} ${moment.tz(tz).format('Z')}`
  }
})
const timeFormatChoices = [
  { value: '12', text: '12 hour' },
  { value: '24', text: '24 hour' }
]
const langChoices = [
  { value: 'en', text: 'English' },
  { value: 'es', text: 'Español' }
]

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default(props.member.display_name),
  tz: z.string().default(settings.value.tz),
  timeFormat: z.string().default(settings.value.timeFormat),
  language: z.string().default(settings.value.language),
}))

const onSubmit = async (values: any) => {
  // TODO: refactor to use single action
  await store.dispatch('user/updateLang', {language: values.language})
  await store.dispatch('user/updateTz', {tz: values.tz})
  await store.dispatch('user/updateTimeFormat', {timeFormat: values.timeFormat})
  await store.dispatch('user/update', {displayName: values.name})
  await store.dispatch('user/saveSettings')

  toast({
    title: `Saved`,
  })
}
</script>

<template>
<Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
  <FormField v-slot="{ componentField }" name="name">
    <FormItem>
      <FormLabel>Name</FormLabel>
      <FormControl>
        <Input v-bind="componentField" />
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>


  <FormField v-slot="{ componentField }" name="tz">
    <FormItem>
      <FormLabel>Timezone</FormLabel>
      <FormControl>
        <Select v-bind="componentField">
          <SelectTrigger>
            <SelectValue placeholder="Select a timezone" />
          </SelectTrigger>
          <SelectContent class="max-h-[300px]">
            <SelectGroup>
              <SelectItem
                v-for="(tz, index) of tzChoices"
                :key="index"
                :value="tz.value"
              >{{tz.text}}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ componentField }" name="timeFormat">
    <FormItem>
      <FormLabel>Time Format</FormLabel>
      <FormControl>
        <Select v-bind="componentField">
          <SelectTrigger>
            <SelectValue placeholder="Select a timeformat" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="(i, index) of timeFormatChoices"
                :key="index"
                :value="i.value"
              >{{i.text}}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <FormField v-slot="{ componentField }" name="language">
    <FormItem>
      <FormLabel>Language</FormLabel>
      <FormControl>
        <Select v-bind="componentField">
          <SelectTrigger>
            <SelectValue placeholder="Select a language" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem
                v-for="(i, index) of langChoices"
                :key="index"
                :value="i.value"
              >{{i.text}}</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </FormControl>
      <FormMessage />
    </FormItem>
  </FormField>

  <div class="flex justify-end pt-4">
    <Button type="submit">Save</Button>
  </div>
</Form>
</template>
