export const getters = {
  getCurrentFeature: state => {
    return state.currentFeature
  },

  getCurrentFeatureChilds: state => {
    return state.currentFeatureChilds
  },

  getFeatures: state => {
    return state.features
  },


  getFeedFeatures: state => {
    return state.feedFeatures
  },

  getBeacons: state => {
    return state.beacons
  },
  getPlanetFeaturesEnabled: state => {
    return state.planetFeaturesEnabled
  },

  getSelectedFeature: state => {
    return state.selected_feature
  },
  getSelectedTask: state => {
    return state.selected_task
  },

  getNotes: state => {
    return state.notes
  },

  getMap: state => {
    return state.map
  },
  getMapReady: state => {
    return state.mapReady
  },
  getDraw: state => {
    return state.draw
  },

  getTilesToken: state => {
    return state.tilesToken
  },
  getTilesSettings: state => {
    return state.tilesSettings
  },
  getIsBasemapAccess: state => {
    return state.tilesSettings?.find(b => b.name === 'Basemaps')?.enabled
  },
  getIsSkysatAccess: state => {
    return state.tilesSettings?.find(b => b.name === 'Skysat')?.enabled
  },
  getTilesMosaics: state => {
    return state.tilesMosaics
  },
  getFiles: state => {
    return state.files
  },
  getLayers: state => {
    return state.layers
  },

  getIsMapReady: state => {
    return state.mapReady
  },

  getProjectReportTemplates: state => {
    return state.reportTemplates
  },

  getIsFeatureCreated: state => {
    return state.isFeatureCreated
  },
  getTotalOnMap: state => {
    return state.totalOnMap
  }
}
