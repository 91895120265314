<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import {formatTime} from '@/utils/datetime'

import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { PlusCircle } from 'lucide-vue-next'
import { DatePicker } from 'v-calendar'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover'
import {Badge} from '@/components/ui/badge'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

const props = defineProps({
  feature: {
    type: Object,
    required: true
  }
})

const types = [
  {
    value: 'forest_carbon_diligence_30m',
    label: 'Forest Carbon Diligence 30m',
    ids: [
      { value: 'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30', label: 'Aboveground Carbon Density 30m (v1.0.0)' },
      { value: 'CANOPY_HEIGHT_v1.0.0_30', label: 'Canopy Height 30m (v1.0.0)' },
      { value: 'CANOPY_COVER_v1.0.0_30', label: 'Canopy Cover  30m (v1.0.0)' }
    ],
    available_range: [
      new Date(Date.UTC(2013, 0, 1, 0, 0, 0)),
      new Date(Date.UTC(2022, 0, 1, 0, 0, 0))
    ]
  },
  {
    value: 'forest_carbon_monitoring_3m',
    label: 'Forest Carbon Monitoring 3m',
    ids: [
      { value: 'ABOVEGROUND_CARBON_DENSITY_v1.0.0_3', label: 'Aboveground Carbon Density 3m (v1.0.0)' },
      { value: 'CANOPY_HEIGHT_v1.0.0_3', label: 'Canopy Height 3m (v1.0.0)' },
      { value: 'CANOPY_COVER_v1.0.0_3', label: 'Canopy Cover 3m (v1.0.0)' }
    ],
    available_range: [
      new Date(Date.UTC(2021, 0, 1, 0, 0, 0)),
      new Date(Date.UTC(2024, 6, 1, 0, 0, 0))
    ]
  }
]

const isOpen = ref(false)
const close = () => {
  isOpen.value = false
}

const selectedType = ref(types[0].value)

const selectedIds = computed(() => {
  return types.find(type => type.value === selectedType.value)?.ids || []
})

const selectedRange = computed(() => {
  return types.find(type => type.value === selectedType.value)?.available_range || []
})

const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255).default('FCD Layer'),
  layerType: z.string().default(selectedType.value),
  layerId: z.string().default(selectedIds.value[0].value),
  start_date: z.date().default(selectedRange.value[0]).refine(value => {
    return value >= selectedRange.value[0] && value <= selectedRange.value[1]
  }, 'Date must be within the available range'),
  end_date: z.date().default(selectedRange.value[1]).refine(value => {
    return value >= selectedRange.value[0] && value <= selectedRange.value[1]
  }, 'Date must be within the available range')
}))

const onSubmit = async (values) => {
  const payload = {
    project_id: props.feature.properties.project_id,
    feature_uuid: props.feature.properties.code,
    name: values.name,
    type: 1,
    start_date: values.start_date,
    end_date: values.end_date,
    source_type: values.layerType,
    source_id: values.layerId
  }

  await store.dispatch('planet/createLayer', payload)

  toast({
    title: 'Layer added',
    description: 'Wait for the layer to be processed',
  })

  close()
}
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <PlusCircle class="text-gray-500 w-4 h-4" />
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>New layer</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="layerType">
          <FormItem>
            <FormLabel>Source type</FormLabel>
            <FormControl>
              <Select v-bind="componentField" v-model="selectedType">
                <SelectTrigger>
                  <SelectValue placeholder="Source type" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(type, index) in types"
                      :key="index"
                      :value="type.value"
                    >
                      {{ type.label }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="layerId">
          <FormItem>
            <FormLabel>Source ID</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Source id" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(id, index) in selectedIds"
                      :key="index"
                      :value="id.value"
                    >
                      {{ id.label }}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <hr>

        <div class="text-sm">
          <h3>Available range</h3>
          <p>
            {{formatTime(selectedRange[0], settings.tz, 'D MMMM YYYY')}} - {{formatTime(selectedRange[1], settings.tz, 'D MMMM YYYY')}}
          </p>
        </div>

        <FormField v-slot="{ componentField }" name="start_date">
          <FormItem>
            <FormLabel>Start date</FormLabel>
            <Popover>
              <PopoverTrigger>
                <Badge variant="secondary">
                  {{ formatTime(componentField.modelValue, settings.tz, 'D MMMM YYYY') }}
                </Badge>
              </PopoverTrigger>
              <PopoverContent class="p-0 w-full">
                <DatePicker v-bind="componentField" :min-date="selectedRange[0]" :max-date="selectedRange[1]" />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="end_date" >
          <FormItem>
            <FormLabel>End date</FormLabel>
            <Popover>
              <PopoverTrigger>
                <Badge variant="secondary">
                  {{ formatTime(componentField.modelValue, settings.tz, 'D MMMM YYYY') }}
                </Badge>
              </PopoverTrigger>
              <PopoverContent class="p-0 w-full">
                <DatePicker v-bind="componentField" :min-date="selectedRange[0]" :max-date="selectedRange[1]" />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter class="mt-4">
          <Button variant="ghost" @click="close" type="button">Close</Button>
          <Button type="submit">Create</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
