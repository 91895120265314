export const mutations = {
  SET_USER(state, user) {
    state.info = user
  },
  SET_MEMBER(state, member) {
    state.member = member
  },
  SET_THEME_COLOR(state, isDark) {
    state.settings.themeDark = isDark
  },
  SET_LANGUAGE(state, language) {
    state.settings.language = language
  },
  SET_MAIN_MENU_DRAWER(state, mini) {
    state.settings.mini = mini
  },
  SET_PROJECTS_FILTERS(state, view) {
    state.settings.projects.filtersShown = view
  },

  SET_LOCATION(state, location) {
    if(location === null) {
      state.settings.location.lat = null
      state.settings.location.lng = null
    } else {
      state.settings.location.lat = location.coords.latitude
      state.settings.location.lng = location.coords.longitude
    }
  },
  SET_TZ(state, tz) {
    state.settings.tz = tz
  },
  SET_TIME_FORMAT(state, format) {
    state.settings.timeFormat = format
  },
  SET_LANG (state, language) {
    state.settings.language = language
  },
  SET_SETTINGS (state, settings) {
    state.settings = settings
  }
}
