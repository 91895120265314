<script setup lang="ts">
import {useStore} from 'vuex'
import {computed, h} from 'vue'

import {ColumnDef} from "@tanstack/vue-table"
import {DataTable} from '@/components/ui/data-table'
import {Org} from '@/types'
import {formatTime} from '@/utils/datetime.js'
import ApiKeysListDropdown from './ApiKeysListDropdown.vue'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

interface ApiKey {
  id: number
  uuid: string
  organization_id: number
  name: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
}

defineProps<{
  org: Org,
  apikeys: ApiKey[]
}>()

const columns: ColumnDef<ApiKey>[] = [
  {
    accessorKey: 'name',
    header: 'Name'
  },
  {
    accessorKey: 'created_at',
    header: 'Added',
    cell: ({row}) => {
      const date = formatTime(row.getValue('created_at'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  },
  {
    accessorKey: 'key',
    header: 'Key',
    cell: () => h('span', {class: 'text-gray-500'}, '************')
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const apikey = row.original
      return h('div', { class: 'relative' }, h(ApiKeysListDropdown, {
        apikey
      }))
    },
  }
]
</script>

<template>
  <DataTable :columns="columns" :data="apikeys" />
</template>
