<script setup>
import { computed } from 'vue'
import { Button } from '@/components/ui/button'
import { useRouter, useRoute } from 'vue-router'
import { ChevronRight } from 'lucide-vue-next'

const router = useRouter()
const route = useRoute()
const featureCode = computed(() => route.params.featureCode)

const props = defineProps({
  project: Object,
  currentProjectId: Number,
})

const isCurrent = computed(() => props.project.project_id === props.currentProjectId)
</script>

<template>
  <div class="flex items-center">
    <ChevronRight class="w-4 h-4 text-gray-400" />
    <Button
      :variant="isCurrent && !featureCode ? 'ghost' : 'link'"
      size="sm"
      :disabled="isCurrent && !featureCode"
      @click="router.push({ name: 'ProjectHome', params: { project_id: project.project_id } })"
      class="whitespace-nowrap"
    >
      {{ project.project_name }}
    </Button>
  </div>
</template>
