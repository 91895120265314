<script setup>
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { FilterBadge } from '@/components/filters'
import { Badge } from '@/components/ui/badge'

const store = useStore()
const ignoreKeys = ['start_date', 'end_date', 'center_lat', 'center_lng', 'zoom', 'south', 'north', 'east', 'west', 'organization_id', 'project_id']
const params = computed(() => {
  return store.getters['search/getParams'].filter(i => !ignoreKeys.includes(i.key))
})

const route = useRoute()

const resetFilters = () => {
  store.commit('search/RESET_PARAMS')
}
</script>

<template>
  <div class="flex flex-wrap" :key="params.length">
    <div v-for="(filter, index) in params" :key="index">
      <FilterBadge v-if="!Array.isArray(filter.value)" :filter="filter" closeable />
      <!-- separate array values on multiple badges -->
      <div v-else v-for="arrayFilterValue in filter.value" class="inline">
        <FilterBadge :filter="{key: filter.key, value: arrayFilterValue}" closeable />
      </div>
    </div>
    <Badge
      @click="resetFilters" 
      class="inline-flex items-center ml-1 mb-1 shadow bg-white text-gray-600 cursor-pointer"
      v-if="params.length > 0"
    >Reset</Badge>
  </div>
</template>
