import {API} from "@/utils/http";

export const createOrder = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `/tulum/tasks`,
      data: {
        project_id: payload.projectId,
        feature_uuid: payload.featureUuid,
        source: payload.source,
        item_type: payload.itemType,
        item_id: payload.itemId,
        asset_type: payload.assetType,
        account_code: payload.accountCode,
      }
    })

    commit('ADD_ORDER', response.data)

    commit('SET_ALERT', {type: 'success', text: `Order created. ID: ${response.data.id}`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't create order"}, {root: true})
  }
}

export const fetchLayers = async ({commit}, featureCode) => {
  try {
    const response = await API({
      method: 'GET',
      url: `/tulum/tasks/layers/${featureCode}`
    })

    commit('SET_LAYERS', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't fetch layers"}, {root: true})
  }
}

export const clearLayers = ({commit}) => {
  commit('SET_LAYERS', [])
}

export const createLayer = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `/tulum/tasks/layers`,
      data: payload
    })

    commit('ADD_LAYER', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't create layer"}, {root: true})
  }
}