import { API, HOST } from '@/utils/http'

export const fetchOrgs = async ({commit}) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/organization`
    })

    commit('SET_ORGS', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't fetch orgs"}, {root: true})
  }
}

export const fetchOrg = async ({commit}, orgId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/organization/${orgId}`
    })

    commit('SET_CURRENT', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't fetch org. ID: ${orgId}`}, {root: true})
  }
}

export const fetchOrgMembers = async ({commit}, orgId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/organization/${orgId}/members`
    })

    commit('SET_CURRENT_MEMBERS', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't fetch org members. ID: ${orgId}`}, {root: true})
  }
}

export const fetchOrgInvites = async ({commit}, orgId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/organization/${orgId}/invites`
    })

    commit('SET_CURRENT_INVITES', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't fetch org invites. ID: ${orgId}`}, {root: true})
  }
}

export const createOrg = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/organization`,
      data: {
        organization_name: payload.name,
      }
    })

    commit('ADD_ORG', response.data)
    commit('SET_ALERT', {type: 'success', text: 'Organization created'}, {root: true})
  } catch (e) {
    if (e.response.status === 400) {
      commit('SET_ALERT', {type: 'error', text: 'Organization with this name already exists'}, {root: true})
    } else {
      commit('SET_ALERT', {type: 'error', text: `Can't create organization`}, {root: true})
    }
  }
}

export const invite = async ({commit}, payload) => {
  const orgId = payload.organization_id

  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/organization/${orgId}/invite`,
      data: payload
    })

    commit('ADD_INVITE', response.data)
    commit('SET_ALERT', {type: 'success', text: `Invite sent to ${payload.email}`}, {root: true})
  } catch (e) {
    if (e.response.status === 409) {
      commit('SET_ALERT', {type: 'error', text: 'User already in organization'}, {root: true})
    } else if (e.response.status === 400) {
      commit('SET_ALERT', {type: 'error', text: `${e.response.data?.detail}`}, {root: true})
    } else {
      commit('SET_ALERT', {type: 'error', text: "Can't create invite. Try again."}, {root: true})
    }
  }
}


export const updateMember = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'PUT',
      url: `${HOST}/organization/${payload.orgId}/members/${payload.orgMemberId}`,
      data: {
        display_name: payload.displayName,
        role: payload.role,
        key_settings: payload.keySettings
      }
    })
    commit('UPDATE_MEMBER', response.data)
    commit('SET_ALERT', {type: 'success', text: 'Member updated'}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {
      type: 'error',
      text: `Can't update member. ${e.response.data?.detail}`
    }, {root: true})
  }
}

export const deleteMember = async ({commit}, payload) => {
  try {
    await API({
      method: 'DELETE',
      url: `${HOST}/organization/${payload.orgId}/members/${payload.orgMemberId}`
    })
    commit('REMOVE_MEMBER', payload.orgMemberId)
    commit('SET_ALERT', {type: 'success', text: 'Member deleted'}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {
      type: 'error',
      text: `Can't delete member. ${e.response.data?.detail}`
    }, {root: true})
  }
}

export const fetchApikeys = async ({commit}, orgId) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/organization/${orgId}/apikeys`
    })

    commit('SET_APIKEYS', response.data)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't get apikeys`}, {root: true})
  }
}

export const removeApiKey = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'DELETE',
      url: `${HOST}/organization/${payload.organization_id}/apikeys/${payload.uuid}`
    })

    commit('REMOVE_APIKEY', payload.uuid)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't delete apikey`}, {root: true})
  }
}

export const addApiKey = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/organization/${payload.orgId}/apikeys`,
      data: {
        organization_id: payload.orgId,
        name: payload.name,
        key: payload.apikey
      }
    })

    commit('ADD_APIKEY', response.data)
  } catch (e) {
    console.log('error', e)
  }
}

export const setApiKeyAccess = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/organization/${payload.orgId}/members/${payload.orgMemberId}/apikey`
    })

    commit('SET_ALERT', {type: 'success', text: 'Access updated'}, {root: true})
  } catch (e) {
    console.log(e)
  }
}

export const updateApiKeySettings = async ({commit}, payload) => {
  try {
    await API({
      method: 'PUT',
      url: `${HOST}/organization/${payload.orgId}/members/${payload.orgMemberId}/apikey`,
      data: {
        settings: payload.keySettings
      }
    })
    commit('SET_ALERT', {type: 'success', text: 'Access updated'}, {root: true})
  } catch (e) {
    console.log(e)
  }
}
