<script setup>
import { onMounted, computed } from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import * as firebaseui from 'firebaseui'
import {getAuth} from 'firebase/auth'
import firebase from 'firebase/compat/app'

import PasswordForm from '@/pages/Auth/PasswordForm.vue'
import 'firebaseui/dist/firebaseui.css'

const router = useRouter()
const store = useStore()
const user = computed(() => store.getters.getUser)
const member = computed(() => store.getters['user/getMember'])

const initFirebaseUI = () => {
  let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
  ui.start('#firebaseui-auth-container', {
    signInFlow: 'popup',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: { prompt: 'select_account'}
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // cleanup url params
        const url = new URL(window.location.href)
        url.searchParams.delete('mode')
        url.searchParams.delete('oobCode')
        window.history.replaceState({}, document.title, url.pathname)
      },
      signInFailure: async (error) => {
        console.warn("[login-window] ERROR" + error);
      },
    }
  })
}

onMounted(() => {
  initFirebaseUI()
})
</script>

<template>
  <div class="md:p-1">
    <div class="flex items-center">
      <div class="basis-2/3">
        <img src="@/assets/img/earth-art.png" alt="Earth" class="md:max-w-full">
      </div>
      <div v-show="!user" class="basis-1/3 flex flex-col items-center justify-center ml-4">
        <div class="mb-4 text-center">
          <h1 class="font-black text-xl md:text-3xl">Welcome to Earth</h1>
          <p class="text-gray-500">Sign in or create an account</p>
        </div>

        <div class="flex flex-col items-center justify-center">
          <div id="firebaseui-auth-container"></div>
          <password-form class="w-full max-w-[220px] flex justify-center"></password-form>
        </div>
      </div>
      <div v-show="user" class="basis-1/3 flex flex-col items-center justify-center">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style>
.firebaseui-card-content {
  padding: 0 !important;
}

.firebaseui-container {
  margin-top: 0 !important;
}
</style>