export const getters = {
  getUserSettings: state => {
    return state.settings
  },
  getUser: state => {
    return state.info
  },
  getMember: state => {
    return state.member
  }
}
