export let wsConn;
export function WS(url, msgCb, openCb, closeCb) {
    if (wsConn) {
        return wsConn;
    }
    const socket = new WebSocket(url);

    socket.onopen = (event) => {
        console.log('WS connection was open');
        openCb(event)
    };

    socket.onclose = (event) => {
        closeCb(event)
        if (event.wasClean) {
            console.log('WS connection closing was clean');
        } else {
            console.log('WS connection was dropped');
        }
        console.log(`Code: ${event.code}. Reason: ${event.reason ? event.reason : 'No reason'}`);
    };

    socket.onmessage = (event) => {
        const msg = JSON.parse(event.data);
        msgCb(msg.topic, msg.event, msg.data)
    };

    socket.onerror = (error) => {
        console.log(`Error ${error.message}`);
    };

    wsConn = {
        _send(msg) {
            this.waitForConnection(() => {
                socket.send(msg);
                if (typeof callback !== 'undefined') {
                    callback();
                }
            }, 500);
        },
        waitForConnection(callback, interval) {
            if (socket.readyState === 1) {
                callback();
            } else {
                // todo: implement backoff for interval here
                setTimeout(() => {
                    this.waitForConnection(callback, interval);
                }, interval);
            }
        },
        subscribe(topic) {
            if (!(topic && (typeof topic === 'string') ) || !topic.length > 0) return;

            this._send(JSON.stringify({
                type: 'action',
                action: 'sub',
                topic,
            }));
        },
        unsubscribe(topic) {
            if (!(topic && (typeof topic === 'string') ) || !topic.length > 0) return;

            this._send(JSON.stringify({
                type: 'action',
                action: 'unsub',
                topic,
            }));
        },
        publish(topic, event, data) {
            if (!(topic && (typeof topic === 'string') ) || !topic.length > 0) return;
            if (!(event && (typeof event === 'string') ) || !event.length > 0) return;
            if (!(data && (typeof topic === 'object'))) return;

            this._send(JSON.stringify({
                type: 'action',
                action: 'pub',
                event,
                data
            }));
        },
        rpc(name, args) {
            this._send(JSON.stringify({
                'type': 'action',
                'action': 'rpc',
                'name': name,
                'args': args
            }));
        }
    }
    return wsConn;
}
