<script setup>
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import {debounce} from 'lodash'
import {formatTime} from '@/utils/datetime'

import { Slider } from '@/components/ui/slider'
import {Label} from '@/components/ui/label'
import {Switch} from '@/components/ui/switch'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const map = computed(() => store.getters['geo/getMap'])

const props = defineProps({
  mosaics: {
    type: Array,
    required: true
  }
})

props.mosaics.reverse()

const mosaicsLength = computed(() => props.mosaics.length)
const lastDate = computed(() => formatTime(props.mosaics[mosaicsLength.value - 1].last_acquired, settings.value.tz, 'D MMMM YYYY'))

const sliderSelected = ref(0)
const selectedMosaic = computed(() => props.mosaics[sliderSelected.value])
const selectedMosaicDate = computed(() => formatTime(selectedMosaic.value.last_acquired, settings.value.tz, 'D MMMM YYYY'))

const isActive = ref(false)
const lastLayedId = ref(null)

const handleChange = (value) => {
  isActive.value = value

  if (value) {
    lastLayedId.value = `planet-${props.mosaics[0].name}-layer`
    map.value.setLayoutProperty(lastLayedId.value, 'visibility', 'visible')
  } else {
    map.value.setLayoutProperty(lastLayedId.value, 'visibility', 'none')
  }
}

const handleMosaicChange = debounce((value) => {
  map.value.setLayoutProperty(lastLayedId.value, 'visibility', 'none')
  lastLayedId.value = `planet-${props.mosaics[value].name}-layer`
  map.value.setLayoutProperty(lastLayedId.value, 'visibility', 'visible')
}, 500)
</script>

<template>
  <div class="flex p-0.5 items-center bg-white rounded-full max-w-fit pointer-events-auto">
    <Switch id="planetscope-basemap-switch" v-model:checked="isActive" @update:checked="handleChange" />
    <Label for="planetscope-basemap-switch" class="px-1.5">PlanetScope</Label>
  </div>
  <div v-if="isActive" class="bg-white rounded-lg shadow p-3 mt-1 mb-2 pointer-events-auto">
    <div class="flex items-center">
      <div class="text-gray-500 min-w-[120px] mr-3">{{ selectedMosaicDate }}</div>
      <Slider
        class="min-w-fit"
        :default-value="[sliderSelected]"
        :max="mosaicsLength - 1"
        :step="1"
        @update:model-value="handleMosaicChange"
      />
      <div class="text-gray-500 min-w-fit ml-3">{{ lastDate }}</div>
    </div>
  </div>
</template>
