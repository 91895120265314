<template>
  <div>
    <order-feature-item
      v-for="(feature, index) of features"
      :planet-feature="feature"
      :feature-code="code"
      :project-id="projectId"
      :key="index"
    />
  </div>
</template>

<script>
import {API} from "@/utils/http"
import OrderFeatureItem from "@/components/orders/OrderFeatureItem.vue"

export default {
  components: {
    OrderFeatureItem
  },
  props: {
    code: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      features: null,
      currentPage: 1,
      rowsPerPage: 10,
      rowsPerPageItems: [10, 20, 100, 200],
    }
  },
  created () {
    this.fetchPlanetFeatures()
  },
  methods: {
    async fetchPlanetFeatures () {
      const response = await API({
        method: 'GET',
        url: `/tulum/planet-search/${this.code}`,
        params: {
          page_size: this.rowsPerPage,
          project_id: this.projectId,
        }
      })

      this.features = response.data.features
      this.rowsPerPage = this.rowsPerPage + 10
    },
    paginationUpdated(opts) {
      if (this.page > opts.page || this.rowsPerPage > opts.rowsPerPage) {
        this.fetchPlanetFeatures()
        return
      }

      if (opts.page !== this.page) {
        this.currentPage = opts.page
        this.fetchPlanetFeatures()
      }

      if (opts.rowsPerPage !== this.rowsPerPage) {
        this.rowsPerPage = opts.rowsPerPage
        this.fetchPlanetFeatures()
      }
    },
  }
}
</script>
