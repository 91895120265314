<script setup lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import { PlusCircle } from 'lucide-vue-next'

import { Org, OrgMember } from '@/types'
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Switch} from "@/components/ui/switch";
import Label from "@/components/ui/label/Label.vue";

const store = useStore()

const props = defineProps<{
  org: Org,
  member: OrgMember
}>()

const isOpen = ref(false)
const defaultKeySettings = [
  {"name": "Skysat", "enabled": false},
  {"name": "Basemaps", "enabled": false},
  {"name": "Planetscope", "enabled": false}
]

const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  fullname: z.string().min(3).max(255).default(props.member.display_name),
  email: z.string().email().default(props.member.email),
  role: z.string().default('org_default').default(props.member.role),
  key_skysat: z.boolean().default(props.member.key_settings.length ? props.member.key_settings[0]?.enabled : false),
  key_basemaps: z.boolean().default(props.member.key_settings.length ? props.member.key_settings[1]?.enabled: false),
  key_planetscope: z.boolean().default(props.member.key_settings.length ? props.member.key_settings[2]?.enabled: false),
}))

const onSubmit = async (values: any) => {
  const payload = {
    displayName: values.fullname,
    role: values.role,
    orgId: props.org.organization_id,
    orgMemberId: props.member.organization_member_id,
    keySettings: [
      {"name": "Skysat", "enabled": values.key_skysat},
      {"name": "Basemaps", "enabled": values.key_basemaps},
      {"name": "Planetscope", "enabled": values.key_planetscope}
    ]
  }

  await store.dispatch('org/updateMember', payload)

  toast({
    title: `${values.email} edited`,
  })

  close()
}
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger class="w-full" as-child>
      <Button variant="ghost" class="w-full h-8 px-2 flex justify-start">Edit</Button>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Edit member</DialogTitle>
        <DialogDescription>Member details</DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="fullname">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="email">
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="role">
          <FormItem>
            <FormControl>
              <RadioGroup v-bind="componentField">
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="org_admin" />
                  </FormControl>
                  <FormLabel class="font-normal">Administator</FormLabel>
                </FormItem>
                <FormItem class="flex items-center space-y-0 gap-x-3">
                  <FormControl>
                    <RadioGroupItem value="org_default" />
                  </FormControl>
                  <FormLabel class="font-normal">Default</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
          </FormItem>
          <FormMessage />
        </FormField>

        <div class="mt-5">
          <h4 class="font-bold text-base">Planet Access</h4>
        </div>

        <FormField v-slot="{ value, handleChange }" name="key_skysat">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Skysat</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ value, handleChange }" name="key_basemaps">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">Basemaps</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ value, handleChange }" name="key_planetscope">
          <FormItem>
            <FormControl>
              <div class="flex items-center">
                <Switch
                  :checked="value"
                  @update:checked="handleChange"
                />
                <Label class="ml-2">PlanetScope</Label>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button variant="ghost" type="button" @click="close">Close</Button>
          <Button type="submit">Edit</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
