<script setup>
import {computed, onBeforeMount, ref} from 'vue'
import {Building2, FolderDot, MapPin, Tag} from 'lucide-vue-next'
import {Badge} from '@/components/ui/badge'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import {useStore} from 'vuex'
import { API, HOST } from '@/utils/http'

const store = useStore()
const projects = computed(() => store.getters['project/getProjects'])
const organizations = computed(() => store.getters['org/getOrgs'])

const member = ref(null)

const idToProject = (id) => {
  return projects.value.find(p => p.project_id === id)
}

const idToOrg = (id) => {
  return organizations.value.find(o => o.organization_id === id)
}

const props = defineProps({
  filter: {
    type: Object,
    required: true
  },
  closeable: {
    type: Boolean,
    default: false
  }
})

onBeforeMount(async () => {
  if (props.filter.key === 'member_ids') {
    const response = await API({
      method: 'GET',
      url: `${HOST}/member`,
      params: {
        member_id: props.filter.value
      }
    })

    member.value = response.data
  }
})

const removeFilter = (filter) => {
  store.commit('search/REMOVE_PARAM', filter)
}
</script>

<template>
<Badge
  :closeable="closeable"
  @close="() => removeFilter(filter)"
  class="inline-flex items-center mr-1 mb-1 pr-1 shadow"
  :class="{
    'bg-orange-400': filter.key === 'project_id',
    'bg-sky-500': filter.key === 'organization_id',
    'bg-emerald-500 pl-0 py-0': filter.key === 'member_ids',
    'bg-cyan-500': filter.key === 'tag_ids',
    'bg-indigo-500': filter.key === 'text'
  }"
>
  <FolderDot class="w-4 h-4" v-if="filter.key === 'project_id'" />
  <Building2 class="w-4 h-4" v-if="filter.key === 'organization_id'" />
  <Avatar class="w-6 h-6" v-if="filter.key === 'member_ids' && member">
    <AvatarImage v-if="member.avatar_url" :src="member.avatar_url" alt="member" />
    <AvatarFallback></AvatarFallback>
  </Avatar>
  <Tag class="w-4 h-4" v-if="filter.key === 'tag_ids'" />
  <MapPin class="w-4 h-4" v-if="filter.key === 'text'" />
  <span class="ml-1" v-if="filter.key == 'project_id'">{{idToProject(filter.value)?.project_name}}</span>
  <span class="ml-1" v-else-if="filter.key == 'organization_id'">{{idToOrg(filter.value)?.organization_name}}</span>
  <span class="ml-1" v-else-if="filter.key == 'member_ids' && member">{{member.display_name}}</span>
  <span class="ml-1" v-else>{{filter.value}}</span>
</Badge>
</template>
