<script setup lang="ts">
import {h, computed} from 'vue'
import {useStore} from 'vuex'
import {ColumnDef} from '@tanstack/vue-table'
import {ArrowUpDown, NotepadTextDashed} from 'lucide-vue-next'

import {formatTime} from '@/utils/datetime.js'

import {Project, ReportTemplate} from '@/types'
import {DataTable} from '@/components/ui/data-table'
import {Button} from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import TemplateDropdown from './TemplateDropdown.vue'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

const props = defineProps<{
  project: Project,
  templates: [ReportTemplate]
}>()

const columns: ColumnDef<ReportTemplate>[] = [
  {
    accessorKey: 'enabled',
    header: 'Enabled',
    cell: ({row}) => {
      return h(Switch, {
        'checked': row.getValue('enabled') as boolean,
        'onUpdate:checked': (value: boolean) => {
          const updatedTemplate = {...row.original, enabled: value}
          store.dispatch('geo/updateReportTemplate', {
            project_id: props.project.project_id,
            template: updatedTemplate,
          })
        },
      })
    },
  },
  {
    accessorKey: 's3_icon',
    header: 'Icon',
    cell: ({row}) => {
      if (row.getValue('s3_icon') === null) {
        return h(NotepadTextDashed, {class: 'w-7 h-7 text-gray-500'})
      }
      return h('img', {class: 'w-8 h-8 object-contain', src: row.getValue('s3_icon')})
    },
  },
  {
    accessorKey: 'name',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'h-3 w-3'})])
    }
  },
  {
    accessorKey: 'created_at',
    header: 'Created',
    cell: ({row}) => {
      const date = formatTime(row.getValue('created_at'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  },
  {
    accessorKey: 'color',
    header: '',
    cell: ({row}) => {
      return h('div', {class: 'w-4 h-4 rounded-full', style: `background-color: ${row.getValue('color')}`})
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const template = row.original
      return h('div', { class: 'relative' }, h(TemplateDropdown, {
        project_id: props.project.project_id,
        template: template,
      }))
    },
  }
]
</script>

<template>
  <DataTable :columns="columns" :data="templates" />
</template>
