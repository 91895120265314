<script setup>
import { computed } from 'vue'
// this component neened to import as child in FeatureListItem
import { FeatureListItem } from '@/components/features'

const props = defineProps({
  features: {
    type: Array,
    required: true,
  },
  isChild: {
    type: Boolean,
    default: false,
  }
})

const featuresToRender = computed(() => props.isChild ? props.features : props.features.filter(f => f.properties.parent_code === null))

</script>

<template>
  <FeatureListItem
    class="w-full"
    v-for="(feature, index) in featuresToRender"
    :feature="feature"
    :key="feature.properties.code"
  />
</template>
