<script setup>
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import {LogOut, User, FileSliders, LifeBuoy} from 'lucide-vue-next'
import { firebase } from '@/router'
import { API, HOST } from '@/utils/http'

import { Search } from '@/components/filters'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

const router = useRouter()
const route = useRoute()
const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const member = computed(() => store.getters['getMember'])
const orgs = computed(() => store.getters['org/getOrgs'])
const projects = computed(() => store.getters['project/getProjects'])
const selectedProject = computed(() => store.getters['project/current'])
const projectsByOrgs = computed(() => {
  const sortedProjects = orgs.value.map(org => {
    const orgProjects = projects.value.filter(i => i.organization_id === org.organization_id)
    return { org, orgProjects }
  }).filter(item => item.orgProjects.length > 0)
  return sortedProjects
})

const currentOrg = computed(() => {
  if (projectsByOrgs.value.length === 1) {
    return projectsByOrgs.value[0].org
  } else if (projectsByOrgs.value.length > 1 && selectedProject.value) {
    return orgs.value.find(org => org.organization_id === selectedProject.value.organization_id)
  } else if (projectsByOrgs.value.length > 1 && route.params?.org_id) {
    return orgs.value.find(org => org.organization_id === parseInt(route.params.org_id))
  } else {
    return null
  }
})

const initials = computed(() => {
  const name = member.value.display_name
  if (!name) {
    return '?'
  }
  const names = name.split(' ')
  const first = names[0].charAt(0)
  const last = names[names.length - 1].charAt(0)
  return first + last
})

const logOutUser = () => {
  store.dispatch('logout')
}

const isLoggedIn = computed(() => {
  return store.getters['getIsLoggedIn']
})

const resetView = () => {
  const defaultOptions = {
    center: [-21.874, 42.76],
    zoom: 0
  }
  map.value.setCenter(defaultOptions.center)
  map.value.setZoom(defaultOptions.zoom)

  router.push('/')
}

const getZendeskSsoUrl = async () => {
  try {
    const firebaseToken = await firebase.auth.currentUser.getIdToken(true)

    const response = await API({
      method: 'POST',
      url: `${HOST}/zendesk-auth`,
      data: {
        firebase_token: firebaseToken
      }
    })

    return response.data?.url || ''
  } catch (error) {
    console.error('Error fetching Zendesk SSO URL:', error);
  }
}

const zendeskUrl = ref(null)

onMounted(async () => {
  if (isLoggedIn.value) {
    zendeskUrl.value = await getZendeskSsoUrl()
  }
})
</script>

<template>
  <header class="h-full bg-white flex items-center px-2">
    <div class="basis-2/3 md:basis-1/2 md:ml-28 lg:ml-0">
      <div class="flex items-center">
        <div class="hidden md:flex md:mr-2" @click="resetView">
          <Avatar class="w-9 h-9 border border-gray-300 cursor-pointer hover:opacity-80 rounded-lg">
            <AvatarImage v-if="currentOrg && currentOrg.avatar_url" :src="currentOrg.avatar_url" :alt="currentOrg.organization_name" />
            <img v-else src="@/assets/img/main-logo.svg" alt="logo" style="height: 32px; min-width: 32px;" />
          </Avatar>
        </div>
        <div class="space-x-2 hidden md:flex" v-if="isLoggedIn">
          <div v-if="isLoggedIn">
            <Search />
          </div>
          <!-- <div>
            <project-selector></project-selector>
          </div> -->
        </div>
      </div>

    </div>

    <div v-if="isLoggedIn" class="basis-1/3 md:basis-1/2 flex items-center justify-end">
      <DropdownMenu>
        <DropdownMenuTrigger class="flex items-center">
          <div class="flex flex-col items-end">
            <span class="mr-2 text-sm font-bold" style="line-height: 1;">{{member.display_name}}</span>
            <span class="mr-2 text-xs text-gray-400">{{member.email}}</span>
          </div>
          <Avatar>
            <AvatarImage :src="member.avatar_url" :alt="member.display_name" />
            <AvatarFallback>{{initials}}</AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-56">
          <!-- <DropdownMenuLabel>{{ member.display_name }}</DropdownMenuLabel> -->
          <!-- <DropdownMenuSeparator /> -->
          <DropdownMenuItem @click="router.push('/user')">
            <User class="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem>
          <DropdownMenuItem v-if="member.is_super_user" @click="router.push('/admin')">
            <FileSliders class="mr-2 h-4 w-4" />
            <span>Admin Tools</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <a v-if="zendeskUrl" :href="zendeskUrl" target="_blank">
            <DropdownMenuItem>
              <LifeBuoy class="mr-2 h-4 w-4" />
              <span>Help Centre</span>
            </DropdownMenuItem>
          </a>
          <DropdownMenuSeparator />
          <DropdownMenuItem @click="logOutUser">
            <LogOut class="mr-2 h-4 w-4" />
            <span>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  </header>
</template>
