<script setup>
// legacy form, only for earth admins to create 'master projects'.

import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/toast'
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select"
import {PlusCircle} from 'lucide-vue-next'

const router = useRouter()
const store = useStore()
const projects = computed(() => store.getters['project/getProjects'])
const org = computed(() => store.getters['org/getOrg'])
const orgs = computed(() => store.getters['org/getOrgs'])

const member = computed(() => store.getters['user/getMember'])
const currentProject = computed(() => store.getters['project/current'])
const subProjectsOnly = computed(() => currentProject.value?.current_member?.role === 'project_admin' && !member.value.is_super_user)


const isOpen = ref(false)

const open = () => {
  isOpen.value = true
}

const close = () => {
  isOpen.value = false
}

const workflowChoices = [
  { label: 'Default', value: 'Default' },
  { label: 'Fisher', value: 'Fisher' },
  { label: 'Worker Welfare', value: 'Worker Welfare' }
]


const formSchema = toTypedSchema(z.object({
  name: z.string().min(3).max(255),
  description: z.string().min(3).max(255).optional(),
  orgId: z.string().default(currentProject.value ? currentProject.value.organization_id.toString() : null),
  workflow: z.string().optional().default('Default'),
}))

const onSubmit = async (values) => {
  if (!values.parentId && subProjectsOnly.value) {
    toast({title: 'Select parent project'})
    return
  }

  await store.dispatch('project/createProjectRequest', {
    parentId: values.parentId,
    name: values.name,
    description: values.description,
    orgId: values.orgId,
    country: values.country,
    ui: values.workflow,
  })

  await store.dispatch('project/fetchProjects')

  const newProject = projects.value.find(p => p.project_name === values.name)
  if (!newProject) {
    return
  }

  router.push({ name: 'ProjectHome', params: { project_id: newProject.project_id } })

  close()
}

const filterCountries = (list, search) => list.filter(i => i.label.toLowerCase().includes(search.toLowerCase()))

defineExpose({
  open
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button class="px-2" variant="ghost">
          <PlusCircle class="text-gray-500 w-4 h-4" />
        </Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>Create root project</DialogTitle>
        <DialogDescription>
          <!-- <div v-if="subProjectsOnly">
            Parent project: {{ currentProject.project_name }}
          </div> -->
          <!-- <div v-if="currentProject">
            project_id: {{ currentProject.project_id }} <br>
            org_id: {{ currentProject.organization_id }} <br>
            country: {{ currentProject.country }} <br>
          </div>
          subProjectsOnly: {{ subProjectsOnly }} <br>
          is_super_user: {{ member.is_super_user }} <br> -->
        </DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" v-slot="{ setValues }" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="name">
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="description">
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Input v-bind="componentField" />
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ value, componentField }" name="orgId">
          <FormItem>
            <FormLabel>Organization {{ org }}</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Select a organization" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(p, index) of orgs"
                      :key="index"
                      :value="`${p.organization_id}`"
                    >
                      {{p.organization_name}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="workflow">
          <FormItem>
            <FormLabel>Workflow</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Select a workflow" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(w, index) of workflowChoices"
                      :key="index"
                      :value="w.value"
                    >
                      {{w.label}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <FormField v-slot="{ componentField }" name="parentId" v-if="!subProjectsOnly">
          <FormItem>
            <FormLabel>Parent Project (Optional)</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Select a project" />
                </SelectTrigger>
                <SelectContent class="max-h-[300px]">
                  <SelectGroup>
                    <SelectItem
                      v-for="(p, index) of projects"
                      :key="index"
                      :value="`${p.project_id}`"
                    >
                      {{p.project_name}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button variant="ghost" @click="close">Close</Button>
          <Button type="submit">Save</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
