<script setup lang="ts">
import {computed, ref} from 'vue'
import ImagePreview from './ImagePreview.vue'
import type { Image } from './types'

import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel'

import {
  Dialog,
  DialogContent
} from '@/components/ui/dialog'

const props = defineProps<{
  images: {
    thumbnails: Image[],
    fullsizes: Image[]
  }
}>()

const isOnlyFullsize = computed(() => {
  return props.images.thumbnails.length === 0 && props.images.fullsizes.length > 0
})

const thumbnails = computed(() => {
  return isOnlyFullsize.value ? props.images.fullsizes : props.images.thumbnails
})

const isOpen = ref(false)
</script>

<template>
  <div class="flex flex-wrap -m-1">
    <ImagePreview
      class="m-1"
      v-for="(image, index) of thumbnails"
      :image="image"
      :key="index"
      @click="isOpen = true"
    />
  </div>

  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogContent>
      <Carousel>
        <CarouselContent>
          <CarouselItem  v-for="(image, index) of images.fullsizes" :key="index">
            <img :src="image.url" alt="gallery image">
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </DialogContent>
  </Dialog>
</template>
