import { computed } from 'vue'

export function useCheckProjectAccess(project, obj, acts) {
  if (!project?.project_id) return

  const userAccessMap = computed(() => project?.current_member?.access_map)
  if (!userAccessMap.value) return false
  const userActs = userAccessMap.value[obj]

  return acts.every((act) => {
    return userActs.includes(act)
  })
}

export function useCheckOrgAccess(org, obj, acts) {
  if (!org?.organization_id) return

  if (org.current_member.super_user) return true

  const userAccessMap = computed(() => org?.current_member?.access_map)
  if (!userAccessMap.value) return false
  const userActs = userAccessMap.value[obj]

  return acts.every((act) => {
    return userActs.includes(act)
  })
}