<script setup>
import {computed, onBeforeUnmount, onMounted, watch, ref} from 'vue'
import {useStore} from 'vuex'
import {bbox} from '@turf/turf'
import * as z from 'zod'

import {Trash2, Eye, FileWarning, Pencil, Save, Split} from 'lucide-vue-next'
import {Button} from '@/components/ui/button'
import {FormFeature} from '@/components/forms'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

const geometryToDrawmode = {
  Point: 'draw_point',
  LineString: 'draw_line',
  Polygon: 'draw_polygon'
}

const store = useStore()
const map = computed(() => store.getters['geo/getMap'])
const draw = computed(() => store.getters['geo/getDraw'])

const props = defineProps({
  feature: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})
// if geometry is missing, add a default point
if (!props.feature.geometry?.type) {
  props.feature.geometry = {
    type: 'Point',
    coordinates: [0, 0]
  }
}

const showForm = ref(props.index < 10)
const geometryErrors = ref(null)
const isCoordinatesValid = computed(() => {
  const geometryType = props.feature.geometry?.type
  const coordinates = props.feature.geometry?.coordinates

  if (geometryType !== 'Point') {
    return true
  }

  return (
    !geometryErrors.value &&
    Array.isArray(coordinates) &&
    coordinates.length > 1 &&
    coordinates[0] !== null &&
    coordinates[1] !== null
  )
})

const schema = z.object({
  latitude: z
    .number({ invalid_type_error: "Latitude must be a number." })
    .min(-90, "Latitude must be greater than or equal to -90.")
    .max(90, "Latitude must be less than or equal to 90."),
  longitude: z
    .number({ invalid_type_error: "Longitude must be a number." })
    .min(-180, "Longitude must be greater than or equal to -180.")
    .max(180, "Longitude must be less than or equal to 180."),
})

const emit = defineEmits(['removeFeature', 'startEditing', 'stopEditing', 'updateFeature', 'split'])

const removeFeature = (feature) => {
  emit('removeFeature', feature)
}

const splitGeometryCollection = (feature) => {
  emit('split', feature)
}

const startDraw = () => {
  draw.value.changeMode(geometryToDrawmode['Point'])
  emit('startEditing', props.feature)
}

const focusFeature = (feature) => {
  const bounds = bbox(feature)
  map.value.fitBounds(bounds, { padding: 20 })
}

const stopEditing = () => {
  draw.value.deleteAll()
  emit('stopEditing', props.feature)
}

const startEditing = (feature) => {
  focusFeature(feature)

  draw.value.changeMode(geometryToDrawmode[feature.geometry.type])
  draw.value.set({
    type: 'FeatureCollection',
    features: [feature]
  })
  emit('startEditing', feature)
}

const onDrawCreate = async (e) => {
  if (!props.feature.properties.isEditing) return
  props.feature.geometry = e.features[0].geometry
}

const onDrawUpdate = async (e) => {
  if (!props.feature.properties.isEditing) return
  const data = draw.value.getAll()
  props.feature.geometry = data.features[0].geometry
}

const updateFeature = (feature) => {
  props.feature.properties = feature.properties
  emit('updateFeature', props.feature)
}

const validateSchema = () => {
  console.log('validateSchema', props.feature)
  draw.value.set({
    type: 'FeatureCollection',
    features: [props.feature]
  })
  try {
    schema.parse({
      latitude: props.feature.geometry.coordinates[1],
      longitude: props.feature.geometry.coordinates[0]
    })
    geometryErrors.value = null
  } catch (error) {
    geometryErrors.value = error.errors
  }
}

watch(() => props.feature.properties.isEditing, (isEditing) => {
  console.log('isEditing', isEditing)
  if (!isEditing) {
    stopEditing()
  }
})

onMounted(() => {
  map.value.on('draw.create', onDrawCreate)
  map.value.on('draw.update', onDrawUpdate)
})

onBeforeUnmount(() => {
  map.value.off('draw.create', onDrawCreate)
  map.value.off('draw.update', onDrawUpdate)

  if (props.feature.properties.isEditing) {
    stopEditing()
  }
})

</script>

<template>
<div
  :key="feature.properties.local_uuid"
  class="border border-gray-200 rounded-lg mb-2"
>
  <div class="p-2">
    <div class="flex items-start justify-between">
      <div class="pr-3">
        <h3 class="font-bold">Shape №{{ index + 1 }} <span v-if="feature.properties.isEditing" class="bg-yellow-300 px-1 rounded-sm">Editing</span></h3>
      </div>
      <div class="flex items-center space-x-1">
        <Button @click="removeFeature(feature)" size="sm" variant="ghost" class="w-7 h-7 p-0">
          <Trash2 class="text-red-500 w-4 h-4" />
        </Button>
        <Button v-if="feature.geometry" @click="focusFeature(feature)" size="sm" variant="outline">
          <Eye class="w-4 h-4" />
          <span class="ml-1">Preview</span>
        </Button>
      </div>
    </div>

    <div class="mt-2">
      <ul>
        <li>File: {{ feature.properties.fileName ? feature.properties.fileName : 'unknown file' }}</li>
        <li v-if="feature.geometry">Type: {{ feature.geometry.type }}</li>
      </ul>
    </div>

    <div class="mt-3">
      <Button
        v-if="feature.geometry && isCoordinatesValid && !feature.properties.isEditing"
        @click="() => startEditing(feature)"
        size="sm"
        variant="outline"
      >
        <div class="flex items-center">
          <Pencil class="w-4 h-4 text-gray-600" />
          <span class="ml-1">Edit Geometry</span>
        </div>
      </Button>

      <Button
        v-else-if="feature.geometry && feature.properties.isEditing"
        @click="() => stopEditing(feature)"
        size="sm"
      >
        <div class="flex items-center">
          <Save class="w-4 h-4 text-white" />
          <span class="ml-1">Save Geometry</span>
        </div>
      </Button>

      <div class="mt-2" v-if="feature.geometry && feature.geometry.type === 'GeometryCollection'">
        <Button
          size="sm"
          variant="outline"
          @click="() => splitGeometryCollection(feature)"
        >
          <div class="flex items-center">
            <Split class="w-4 h-4 text-gray-600" />
            <span class="ml-1">Split Geometry Collection</span>
          </div>
        </Button>
        <p class="text-gray-500 mt-1 text-xs">
          Geometry collection contains multiple geometries ({{ feature.geometry.geometries.length }}).
          You can split it into separate shapes and upload them individually.
        </p>
      </div>
    </div>

    <div class="mt-3" v-if="feature.geometry.type === 'Point'">
      <div class="flex items-center space-x-2">
        <div>
          <Label>Longitude</Label>
          <Input v-model="feature.geometry.coordinates[0]" @input="validateSchema" type="number" step="0.0000001" />
        </div>
        <div>
          <Label>Latitude</Label>
          <Input v-model="feature.geometry.coordinates[1]" @input="validateSchema" type="number" step="0.0000001" />
        </div>
      </div>
      <div v-if="geometryErrors" class="text-red-500 text-xs">
        <ul>
          <li v-for="error in geometryErrors">{{ error.message }}</li>
        </ul>
      </div>
    </div>

    <Button
      v-if="!showForm"
      @click="() => showForm = true"
      size="sm"
      variant="outline"
    >
      <div class="flex items-center">
        <Pencil class="w-4 h-4 text-gray-600" />
        <span class="ml-1">Edit Data and Upload</span>
      </div>
    </Button>
  </div>

  <div class="p-2 border-t border-gray-200" :class="{'opacity-30 pointer-events-none' : !isCoordinatesValid }">
    <FormFeature
      v-if="feature.geometry && showForm"
      :predefined="true"
      :feature="feature"
      hide-close
      @update:created="removeFeature"
      @update:feature="updateFeature"
    />
  </div>
</div>
</template>
