<!--TODO: Get rid of this component and firebaseui-->
<script setup>
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
</script>

<template>
	<div>
		<div v-if="selected" id="signin-container">
			<div v-if="selected == 'password'">
					<Input
						v-model="signInEmail"
						label="Email"
            class="mb-3"
					></Input>
					<Input
						v-model="signInPassword"
						label="Password"
						type="password"
            class="mb-3"
					></Input>
        <div class="mt-3">
          <Button variant="ghost" @click="returnToSignInList">back</Button>
          <Button variant="ghost" @click="signInWithPassword">Login</Button>
        </div>
			</div>
		</div>
		<div v-if="!selected" id="signin-list">
			<Button variant="ghost" @click="passwordSignIn">Sign in with password</Button>
<!--			<Button @click="signInWithApple">Sign in with Apple</Button>-->
		</div>
	</div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, signInWithPopup, OAuthProvider } from 'firebase/auth';

export default {
  data: () => ({
    selected: null,
    authType: 'sign-in',
    showEmailSentMessage: false,
    signInEmail: null,
    signInName: null,
    signInPassword: null,
    emailValidRules: [
      value => !!value || 'Required.',
      value => (value || '').length <= 80 || 'Max 80 characters',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    ]
  }),
  watch: {
    selected: {
      immediate: true,
      handler(selected, oldSelected) {
        let fui = document.querySelector('.firebaseui-idp-list');
        let bfui = document.querySelector('.v-card__text');

        if (fui && bfui) {
          if (!selected) {
            fui.style.display = 'block';
            bfui.style.height = 'auto';
          }

          if (selected == 'password') {
            fui.style.display = 'none';
            bfui.style.height = '310px';
          }
        }
      }
    }
  },

  mounted() {
    var fb_list = null;

    setInterval(() => {
      if (fb_list == null) {
        fb_list = document.querySelector('.firebaseui-container');
      }

      let fb_cont = document.querySelector('.firebaseui-card-header');

      if (fb_list) {
        fb_list.style.marginTop = '70px';
      }

      if (fb_cont) {
        let bg = document.querySelector('.auth-diag');
        if (bg != null) {
          bg.style.display = 'none';
        }

      } else {
        let bg = document.querySelector('.auth-diag');
        if (bg != null) {
          bg.style.display = 'block';
        }
      }
    }, 100);

    this.$nextTick(() => {
      var fb_user = localStorage.getItem('firebase_user');

      if (fb_user) {
        this.authType = 'sign-in';
      } else {
        this.authType = 'sign-up';
      }
    });
  },
  methods: {
    passwordSignIn() {
      this.selected = 'password';
    },
    returnToSignInList() {
      this.selected = null;
      this.showEmailSentMessage = false;
      this.authType = 'sign-in';
    },
    signInWithApple() {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({ locale: 'en' });
      const auth = getAuth();

      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result)
          const accessToken = credential.accessToken
          const idToken = credential.idToken
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
        });
    },
    signInWithPassword() {
      const auth = getAuth()
      signInWithEmailAndPassword(auth, this.signInEmail, this.signInPassword)
        .then((userCredential) => {
          this.$router.push('/')
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  }
}
</script>
