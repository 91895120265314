export const state = {
  layout: 'simple',
  pending: false,
  isLoggedIn: false,
  isLoading: true,
  apiAuth: false,
  appSettings: {
    colors: {
      primary: '#347322'
    },
    loader: {
      type: 'orbit',
      color: '#347322',
      size: 62
    }
  },
  alerts: []
}
