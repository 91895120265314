<script setup>
import {ref, computed, onMounted} from 'vue'
import {useStore} from 'vuex'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {Badge} from '@/components/ui/badge'
import {Button} from '@/components/ui/button'

import { DatePicker } from 'v-calendar'
import { useScreens } from 'vue-screen-utils'
import 'v-calendar/style.css'

import {formatTime} from '@/utils/datetime'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const params = computed(() => store.getters['search/getParams'])

const isOpen = ref(false)
const datePicker = ref(null)
const selectedRange = ref(null)
const today = new Date(new Date().setHours(23, 59, 59, 999))
// const monthAgo = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
const isSameYear = (date1, date2) => date1.getFullYear() === date2.getFullYear()

const range = computed({
  get: () => {
    return {
      start: params.value.find(param => param.key === 'start_date')?.value ? new Date(params.value.find(param => param.key === 'start_date').value) : null,
      end: params.value.find(param => param.key === 'end_date')?.value ? new Date(params.value.find(param => param.key === 'end_date').value) : null
    }
  },
  set: (value) => {
    if (value?.start && value?.end) {
      store.commit('search/ADD_PARAM', {key: 'start_date', value: value.start.toISOString()})
      store.commit('search/ADD_PARAM', {key: 'end_date', value: value.end.toISOString()})
    } else {
      store.commit('search/REMOVE_PARAM', {key: 'start_date'})
      store.commit('search/REMOVE_PARAM', {key: 'end_date'})
    }
  },
  immediate: true
})

const startDate = computed(() => formatTime(range.value.start, settings.value.tz, isSameYear(range.value.start, range.value.end) ? 'D MMMM' : 'D MMMM YYYY'))
const endDate = computed(() => formatTime(range.value.end, settings.value.tz, 'D MMMM YYYY'))

const setRange = (rangeString) => {
  switch (rangeString) {
    case '24':
      selectedRange.value = '24'
      range.value = {
        start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1),
        end: today
      }

      break
    case 'week':
      selectedRange.value = 'week'
      range.value = {
        start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7),
        end: today
      }
      break
    case 'month':
      selectedRange.value = 'month'
      range.value = {
        start: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
        end: today
      }
      break
    case 'year':
      selectedRange.value = 'year'
      range.value = {
        start: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
        end: today
      }
      break
    case 'reset':
      store.commit('search/REMOVE_PARAM', {key: 'start_date'})
      store.commit('search/REMOVE_PARAM', {key: 'end_date'})
      selectedRange.value = null
      datePicker.value.move(new Date())
      break
  }

  isOpen.value = false
}

const { mapCurrent } = useScreens({
  xs: '0px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
});
const columns = mapCurrent({ lg: 2 }, 1)
const expanded = mapCurrent({ lg: false }, true)
const disabledDates = ref([{ start: today}])
</script>

<template>
  <Popover :open="isOpen" @update:open="isOpen = $event">
    <PopoverTrigger>
      <Badge class="shadow-md">
        <span v-if="range.start && range.end">{{startDate}} - {{endDate}}</span>
        <span v-else>Filter by Date</span>
      </Badge>
    </PopoverTrigger>
    <PopoverContent class="p-0 w-full">
      <div class="p-1 flex flex-wrap space-x-1">
        <Button @click="setRange('24')" variant="outline" size="sm" :class="{'text-primary': selectedRange === '24'}">24h</Button>
        <Button @click="setRange('week')" variant="outline" size="sm" :class="{'text-primary': selectedRange === 'week'}">Week</Button>
        <Button @click="setRange('month')" variant="outline" size="sm" :class="{'text-primary': selectedRange === 'month'}">Month</Button>
        <Button @click="setRange('year')" variant="outline" size="sm" :class="{'text-primary': selectedRange === 'year'}">Year</Button>
        <Button @click="setRange('reset')" variant="outline" size="sm" class="text-gray-500">Reset</Button>
      </div>

      <div class="p-1">
        <DatePicker
          class="font-sans"
          ref="datePicker"
          borderless
          trim-weeks
          v-model.range="range"
          :columns="columns"
          :step="1"
          :initial-page-position="-1"
          :expanded="expanded"
          :disabled-dates="disabledDates"
        />
      </div>
    </PopoverContent>
  </Popover>
</template>

<style lang="postcss">
.vc-container {
  --vc-font-family: 'Nunito', sans-serif;
}
</style>
