<script setup lang="ts">
import { h, computed } from 'vue'
import { useStore } from 'vuex'
import type { ColumnDef } from '@tanstack/vue-table'
import {formatTime} from '@/utils/datetime.js'

import { ArrowUpDown, ChevronDown } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { DataTable } from '@/components/ui/data-table'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

interface Invite {
  id: string,
  member_id: number,
  project_id: number,
  email: string,
  fullname: string,
  role_name: string,
  is_accepted: boolean,
  create_dttm: string,
  update_dttm: string,
  accept_dttm: string | null,
}

const props = defineProps<{
  invites: Invite[]
}>()

const columns: ColumnDef<Invite>[] = [
  {
    accessorKey: 'fullname',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Name', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
  },
  {
    accessorKey: 'email',
    header: ({column}) => {
      return h(Button, {
        variant: 'ghost',
        size: 'sm',
        onClick: () => column.toggleSorting(column.getIsSorted() === 'asc'),
      }, () => ['Email', h(ArrowUpDown, {class: 'ml-1 h-3 w-3'})])
    },
    cell: ({row}) => h('div', {class: 'lowercase'}, row.getValue('email')),
  },
  {
    accessorKey: 'role_name',
    header: 'Role',
    cell: ({row}) => {
      return h('div', {class: 'capitalize text-gray-500'}, row.getValue('role_name'))
    },
  },
  {
    accessorKey: 'create_dttm',
    header: 'Invited',
    cell: ({row}) => {
      const date = formatTime(row.getValue('create_dttm'), settings.value.tz, 'D MMMM YYYY')
      return h('div', {class: 'text-gray-500 whitespace-nowrap'}, date)
    },
  },
]
</script>

<template>
  <DataTable :columns="columns" :data="invites" />
</template>
