import firebase from 'firebase/compat/app'
import { getAuth } from "firebase/auth";

export const config = {
  apiKey: "AIzaSyAZIKY8nW8F-0Sd27jk8NNPaM9nANyT2gA",
  authDomain: "earthpbc-explorer.firebaseapp.com",
  databaseURL: "https://earthpbc-explorer.firebaseio.com",
  projectId: "earthpbc-explorer",
  storageBucket: "earthpbc-explorer.appspot.com",
  messagingSenderId: "702641111945",
  appId: "1:702641111945:web:b4901b51900b3b9f5f9758",
  measurementId: "G-770E0BMBY1"
}

firebase.initializeApp(config)

export default {
  auth: getAuth(),
}
