<script setup>
import {onBeforeMount, computed} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'

import {Switch} from '@/components/ui/switch'
import {Label} from '@/components/ui/label'

const store = useStore()
const route = useRoute()

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', route.params.project_id)
})
</script>


<template>
  <ul class="space-y-3">
    <li v-for="(c, index) in components" class="flex items-center">
      <Switch
        :id="`c-${index}`"
        :key="index"
        :checked="c.enabled"
        @update:checked="toggleComponent(c.name, c.enabled)"
      />
      <Label class="capitalize ml-2" :id="`c-${index}`">{{c.label}}</Label>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      project: 'project/current',
      projectComponents: 'project/getComponents',
      allComponents: 'project/getAllComponents'
    }),
    projectId () {
      return parseInt(this.$route.params.project_id)
    },
    components () {
      const projectComponents = this.projectComponents ? this.projectComponents.map(c => c.name) : []
      return this.allComponents ? this.allComponents.map(c => {
        return {
          name: c.name,
          label: c.name.replace(/-/g, ' '),
          enabled: projectComponents.includes(c.name)
        }
      }) : []
    }
	},
  data () {
    return {
      valid: false,
    }
  },
  async created () {
    await this.$store.dispatch('project/fetchAllComponents')
  },
  methods: {
    toggleComponent(name, enabled) {
      if (enabled) {
        this.$store.dispatch('project/removeComponent', {projectId: this.projectId, name: name})
      } else {
        this.$store.dispatch('project/addComponent', {projectId: this.projectId, name: name})
      }
    }
  }
}
</script>
