import moment from 'moment'
import 'moment-timezone'

export const secondsDiff = (a, b) => {
  const now = moment(a)
  const end = moment(b)
  return now.diff(end, 'seconds');
}

export const formatTime = (time, tz, timeFormat) => {
  return moment.utc(time).tz(tz).format(timeFormat)
}

export const formatTimeFromNow = (time, tz) => {
  return moment.utc(time).tz(tz).fromNow()
}
