<script setup lang="ts">
import {onBeforeMount, ref, computed} from 'vue'
import {useStore} from 'vuex'
import {toTypedSchema} from "@vee-validate/zod";
import * as z from "zod";

import {PlusCircle} from 'lucide-vue-next'
import {
  Dialog,
  DialogContent,
  DialogDescription, DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form'
import {Button} from '@/components/ui/button'
import {toast} from "@/components/ui/toast";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";

const props = defineProps<{
  order: any
}>()

const store = useStore()
const accounts = computed(() => store.getters['billing/getAccounts'])
const org = computed(() => store.getters['org/getCurrent'])

const isOpen = ref(false)
const close = () => {
  isOpen.value = false
}

const formSchema = toTypedSchema(z.object({
  account: z.string()
}))

const onSubmit = async (values: any) => {
const payload = {
    ...props.order,
    accountCode: values.account
  }
  await store.dispatch('planet/createOrder', payload)

  toast({
    title: `Order added`,
  })

  close()
}

onBeforeMount(() => {
  store.dispatch('billing/fetchAccounts', {orgId: org.value.organization_id})
})
</script>

<template>
  <Dialog :open="isOpen" @update:open="isOpen = $event">
    <DialogTrigger>
      <slot>
        <Button size="sm">Order</Button>
      </slot>
    </DialogTrigger>
    <DialogContent class="max-w-[350px]">
      <DialogHeader>
        <DialogTitle>New order</DialogTitle>
        <DialogDescription></DialogDescription>
      </DialogHeader>

      <Form class="space-y-3" :validation-schema="formSchema" @submit="onSubmit">
        <FormField v-slot="{ componentField }" name="account">
          <FormItem>
            <FormLabel>Organization</FormLabel>
            <FormControl>
              <Select v-bind="componentField">
                <SelectTrigger>
                  <SelectValue placeholder="Acccount" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem
                      v-for="(acc, index) of accounts"
                      :key="index"
                      :value="`${acc.code}`"
                    >
                      {{acc.code}}
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        </FormField>

        <DialogFooter>
          <Button variant="ghost" @click="close">Close</Button>
          <Button type="submit">Order</Button>
        </DialogFooter>
      </Form>
    </DialogContent>
  </Dialog>
</template>
