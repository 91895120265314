<script setup>
import { computed, onBeforeMount, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { FormProject } from '@/components/forms'

const store = useStore()
const route = useRoute()

const projectId = computed(() => parseInt(route.params.project_id))
const project = computed(() => store.getters['project/current'])
const members = computed(() => store.getters['project/getMembers'])
const isLoading = ref(true)

onBeforeMount(async () => {
  await store.dispatch('project/fetchProject', projectId.value)
  await store.dispatch('project/fetchMembers', projectId.value)
  isLoading.value = false
})

watch(() => projectId.value, async (value) => {
  isLoading.value = true
  await store.dispatch('project/fetchProject', value)
  await store.dispatch('project/fetchMembers', value)
  isLoading.value = false
})
</script>

<template>
  <div class="p-2">
    <div v-if="!isLoading && project && members" :key="members">
      <FormProject :project="project" :key="project.project_id" :members="members" />
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>
