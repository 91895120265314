<script setup>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {Avatar, AvatarFallback, AvatarImage} from '@/components/ui/avatar'
import {formatTime} from '@/utils/datetime'
import {ArrowDown, ArrowUp} from 'lucide-vue-next'

const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])

defineProps({
  events: {
    type: Array,
    required: true
  }
})
</script>

<template>
  <div class="white shadow rounded-lg">
    <div v-for="event in events" :key="event.id" class="border-b border-gray-200 last:border-b-0">
      <div class="flex items-center justify-between p-2">
        <ArrowDown v-if="event.event === 'out'" class="w-4 h-4 text-red-500" />
        <ArrowUp v-if="event.event === 'in'" class="w-4 h-4 text-green-500" />
        <div class="flex items-center">
          <Avatar class="w-5 h-5 mr-1">
            <AvatarImage v-if="event.member.avatar_url" :src="event.member.avatar_url" :alt="event.member.display_name" />
            <AvatarFallback></AvatarFallback>
          </Avatar>
          <span>{{ event.member.display_name }}</span>
        </div>
        <div class="text-gray-500">
          {{ formatTime(event.created_at, settings.tz, 'D MMMM YYYY HH:mm') }}
        </div>
      </div>
    </div>
  </div>
</template>