<script setup lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'

import {formatTime} from '@/utils/datetime.js'
import { MoreHorizontal } from 'lucide-vue-next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'


const store = useStore()
const props = defineProps<{
  apikey: any
}>()

const remove = (ev: any) => {
  store.dispatch('org/removeApiKey', props.apikey)
}
</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <span class="sr-only">Open</span>
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuItem @click="remove" class="text-red-500">Delete</DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
