import axios from 'axios'

export const HOST = '/api/3'
export const HOST_TULUM = '/tulum'

export const imProcApi = axios.create({
  baseURL: '/im_process/'
})

export const planetSearchApi = axios.create({
  baseURL: '/planet_search/'
})


// API V3
export const API = axios.create({})

function getLocalAccessToken() {
  return localStorage.getItem('accessToken')
}

function getLocalRefreshToken() {
  return window.localStorage.getItem('refreshToken')
}
function refreshToken() {
  return API.post(`${HOST}/token/refresh/`, {
    refresh_token: getLocalRefreshToken(),
  })
}

API.interceptors.request.use(
  async config => {
    const token = getLocalAccessToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config;
  },
  error => Promise.reject(error)
)

API.interceptors.response.use(
  response => response,
  async error => {
    let originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const rs = await refreshToken()
        const { access_token } = rs.data
        window.localStorage.setItem('accessToken', access_token)
        originalRequest.headers.Authorization = `Bearer ${access_token}`

        return API(originalRequest)
      } catch (_error) {
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data)
        }

        return Promise.reject(_error)
      }
    }

    return Promise.reject(error)
  }
)
