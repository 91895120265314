<script setup>
import { Button } from '@/components/ui/button'
</script>

<template>
  <div>
    <div class="mb-3">
      <Button @click="search" variant="outline">Search</Button>
    </div>
<!--    TODO: refactor to get data in this component and drop by props instead, list component must only render data-->
    <order-features-list v-if="isSearch" :code="featureCode" :projectId="projectId" />
  </div>
</template>

<script>
import OrderFeaturesList from "@/components/orders/OrderFeaturesList.vue"

export default {
  components: {
    OrderFeaturesList
  },
  props: {
    feature: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isSearch: false
    }
  },
  computed: {
    featureCode () {
      return this.feature.properties.code
    },
    projectId () {
      return this.feature.properties.project_id
    }
  },
  methods: {
    async search () {
      // TODO: fix this hack
      this.isSearch = false
      this.isSearch = true
    }
  }
}
</script>
