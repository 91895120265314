import { API } from "@/utils/http"
import axios from "axios";

const HOST = '/api/3'

// Project Members
export const fetchMembers = async ({commit}, projectId) => {
  try {
    const members = await API({
      method: 'GET',
      url: `${HOST}/project/${projectId}/members`
    }).then(r => r.data)

    commit('SET_MEMBERS', members)
  } catch (e) {
    throw new Error(e)
  }
}

export const fetchAllMembers = async ({commit}) => {
  try {
    const members = await API({
      method: 'GET',
      url: `${HOST}/project/members`
    }).then(r => r.data)

    commit('SET_ALL_MEMBERS', members)
  } catch (e) {
    throw new Error(e)
  }
}

export const deleteMember = async ({commit}, payload) => {
  try {
    await API({
      method: 'DELETE',
      url: `${HOST}/project/${payload.projectId}/member/${payload.memberId}`,
    })

    commit('REMOVE_MEMBER', payload.memberId)
    commit('SET_ALERT', {type: 'success', text: 'Member deleted'}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't delete member"}, {root: true})
  }
}

export const updateMember = async ({commit}, payload) => {
  try {
    const memberID = payload.member_id
    const projectID = payload.project_id

    const response = await API({
      method: 'PATCH',
      url: `${HOST}/project/${projectID}/member/${memberID}`,
      data: {
        member_id: memberID,
        display_name: `${payload.name}`,
        email: payload.email,
        role_name: payload.role_name,
        manage_members: payload.manage_members
      }
    })

    const updated_member = response.data
    commit('UPDATE_MEMBER', updated_member)

    commit('SET_ALERT', {type: 'success', text: 'Member updated'}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't update member"}, {root: true})
  }
}

// Invites
export const fetchInvites = async ({commit}, projectId) => {
  try {
    const invites = await API({
      method: 'GET',
      url: `${HOST}/project/${projectId}/invites`
    }).then(r => r.data)

    commit('SET_INVITES', invites)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't fetch invites"}, {root: true})
  }
}

export const addInvite = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/invite`,
      data: {
        member_id: payload.member_id,
        project_id: payload.project_id,
        email: payload.email,
        fullname: payload.name,
        role_name: payload.role_name
      }
    })
    commit('ADD_INVITE', response.data)
    commit('SET_ALERT', {type: 'success', text: `Invite sent to ${payload.email}`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'warning', text: 'Can\'t send invite, try again'}, {root: true})
  }
}

export const deleteInvite = async ({ commit }, invite) => {
    try {
      await API({
          method: 'DELETE',
          url: `${HOST}/invite/${invite.id}`
      })

      commit('REMOVE_INVITE', invite.id)
      commit('SET_ALERT', {type: 'success', text: 'Invite removed'}, {root: true})
    } catch (e) {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t remove invite'}, {root: true})
    }
}

export const fetchInvitesForMember = async({commit}) => {
  try {
    const invites = await API({
      method: 'GET',
      url: `${HOST}/invite`
    }).then(r => r.data)

    commit('SET_MEMBER_INVITES', invites)
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: 'Can\'t fetch invites'}, {root: true})
  }
}

// Non working code till mobile app will implement this feature.
export const acceptInvite = async ({commit, dispatch}, id) => {
  try {
    await API({
      method: 'PUT',
      url: `${HOST}/invite/${id}`
    }).then(r => r.data)

    commit('REMOVE_MEMBER_INVITE', id)
    dispatch('fetchProjects')
    commit('SET_ALERT', {type: 'success', text: 'Invite accepted'}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: 'Can\'t accept invite, try again.'}, {root: true})
  }
}

// Project
export const fetchProjects = async ({ commit, dispatch, rootState }, payload) => {
  const orgId = rootState.org.current?.organization_id
  const params = {}

  if (orgId) {
    params.org_id = orgId
  }

  if (payload?.country) {
    params.country = payload.country
  }

  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project`,
      params: {
        ...params,
        nested: true
      }
    })

    const projects = response.data
    commit('SET_PROJECTS', projects)

    try {
      const response = await API({
        method: 'GET',
        url: `${HOST}/project`,
        params: params
      })

      commit('SET_FLATED_PROJECTS', response.data)
    } catch (e) {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t fetch flated projects'}, {root: true})
    }
  } catch (e) {
    console.error(e)
    commit('SET_ALERT', {type: 'error', text: 'Can\'t fetch projects'}, {root: true})
  }
}

export const fetchProject = async ({rootState, commit, dispatch}, projectID) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project/${projectID}`,
    })

    commit('SET_CURRENT', response.data.project)
    commit('SET_CURRENT_MEMBER', response.data.current_member)
    commit('SET_MEMBERS_COUNT', response.data.members_count)
    commit('SET_COMPONENTS', response.data.components)
    commit('SET_PLANET_FEATURES', JSON.parse(response.data.project.planet_features))
  } catch (e) {
    if (!e.response) {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t fetch project'}, {root: true})
      return
    }
    if (e.response.status === 403) {
      commit('SET_ALERT', {type: 'error', text: 'You are not member of this project'}, {root: true})
    } else if (e.response.status === 404) {
      commit('SET_ALERT', {type: 'error', text: 'Project not found'}, {root: true})
    } else {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t fetch project'}, {root: true})
    }
  }
}

export const updateProject = async ({commit, dispatch}, project) => {
  const reqData = {...project}
  try {
    const response = await API({
      method: 'PATCH',
      url: `${HOST}/project/${project.project_id}`,
      data: {
        project_name: reqData.name,
        project_desc: reqData.description,
        organization_id: reqData.orgId,
        country: reqData.country || null,
        parent_id: parseInt(reqData.parentId) || null,
        ui: reqData.ui || null,
        place_details: reqData.placeDetails || null,
      }
    })

    if (reqData.geojson) {
      try {
        if (reqData.paired_feature_code) {
          await dispatch('geo/deleteFeature', reqData.paired_feature_code, {root: true})
        }

        await dispatch('geo/saveFeature', {
          project_id: response.data.project_id,
          feature: {
            type: 'Feature',
            properties: {
              name: reqData.name,
              description: reqData.description,
              shared: true,
              member_id: reqData.member.member_id,
              created_origin: Date.now(),
              type: reqData.geojson.type,
              subtype: 'report', // deprecated, need to remove,
              paired_project_id: response.data.project_id,
            },
            geometry: reqData.geojson
          }
        }, {root: true})
      } catch (e) {
        console.log(e)
      }
    } else {
      if (response.data.paired_feature_code) {
        await dispatch('geo/deleteFeature', response.data.paired_feature_code, {root: true})
      }
    }

    commit('DELETE_PROJECT', response.data)
    commit('ADD_PROJECT', response.data)

    commit('SET_ALERT', {type: 'success', text: `Project ${response.data.project_name} updated.`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: 'Can\'t update project. Try again.'}, {root: true})
  }
}

export const unlistProject = async ({commit}, project) => {
  commit('DELETE_PROJECT', project)
}


export const deleteProject = async ({commit}, project) => {
  try {
    await API({
      method: 'DELETE',
      url: `${HOST}/project/${project.project_id}`,
    }).then(r => r.data)

    commit('DELETE_PROJECT', project)
    commit('SET_ALERT', {type: 'success', text: `Project ${project.project_name} removed`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: 'Can\'t remove project. Try again.'}, {root: true})
  }
}

export const createProjectRequest = async ({commit, dispatch}, projectData) => {
  const reqData = {...projectData}
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/project`,
      data: {
        project_name: reqData.name,
        project_desc: reqData.description,
        organization_id: reqData.orgId,
        country: reqData.country || null,
        parent_id: reqData.parentId || null,
        ui: reqData.ui || null,
        place_details: reqData.placeDetails || null,
      }
    })

    if (reqData.geojson) {
      try {
        await dispatch('geo/saveFeature', {
          project_id: response.data.project_id,
          feature: {
            type: 'Feature',
            properties: {
              name: reqData.name,
              description: reqData.description,
              shared: true,
              member_id: reqData.member.member_id,
              created_origin: Date.now(),
              type: reqData.geojson.type,
              subtype: 'report', // deprecated, need to remove,
              paired_project_id: response.data.project_id,
            },
            geometry: reqData.geojson
          }
        }, {root: true})
      } catch (e) {
        console.log(e)
      }
    }

    commit('ADD_PROJECT', response.data)
    commit('SET_ALERT', {type: 'success', text: `${reqData.name} created`}, {root: true})
    return response.data.project_id
  } catch (e) {
    if (!e.response) {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t create project. Try again.'}, {root: true})
    }
    if (e.response.status === 403) {
      commit('SET_ALERT', {type: 'error', text: 'Not enough permissions'}, {root: true})
    } else if (e.response.status === 400) {
      commit('SET_ALERT', {type: 'error', text: `${e.response.data.detail}`}, {root: true})
    } else {
      commit('SET_ALERT', {type: 'error', text: 'Can\'t create project. Try again.'}, {root: true})
    }
  }
}

export const activateProjectForm = async ({commit}, payload) => {
  commit('SET_SHOW_PROJECT_FORM', true)
  commit('SET_PROJECT_FORM_CONTEXT', payload)
}

export const deactivateProjectForm = async ({commit}) => {
  commit('SET_SHOW_PROJECT_FORM', false)
  commit('SET_PROJECT_FORM_CONTEXT', null)
}

// Project Groups
export const createProjectGroup = async ({commit}, data) => {
  const request = {...data}
  try {
    const group = await API({
      method: 'POST',
      url: `${HOST}/project-group/`,
      data: {
        name: request.name,
        country: request.country,
        organization_id: request.org
      }
    }).then(r => r.data)

    commit('ADD_GROUP', group)
    commit('SET_ALERT', {type: 'success', text: `Project group ${request.name} created`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't load project group"}, {root: true})
  }
}

// Project Tags

export const fetchAllTags = async ({commit}) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project/tag`
    })
    commit('SET_ALL_TAGS', response.data)
  } catch (e) {
    throw new Error(e)
  }
}

export const fetchProjectTags = async ({commit}, projectID) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/project/${projectID}/tag`
    })
    commit('SET_PROJECT_TAGS', response.data)
  } catch (e) {
    throw new Error(e)
  }
}

export const removeProjectTag = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'DELETE',
      url: `${HOST}/project/${payload.projectId}/tag/${payload.tagId}`,
    })
    commit('REMOVE_TAG', payload.tagId)
    commit('SET_ALERT', {type: 'success', text: `Tag removed`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: "Can't remove tag"}, {root: true})
  }
}

export const createProjectTag = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/project/${payload.projectId}/tag`,
      data: {
        project_id: payload.projectId,
        tag_type: payload.tag.tag_type,
        name: payload.tag.name,
        color: payload.tag.color,
        units: payload.tag.unit,
        object_subtype: payload.tag.object_subtype,
        object_type: payload.tag.object_type
      }
    })

    const tag = response.data
    commit('ADD_TAG', tag)
    commit('SET_ALERT', {type: 'success', text: `Tag added`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't add tag`}, {root: true})
  }
}

export const editProjectTag = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'PATCH',
      url: `${HOST}/project/${payload.projectId}/tag/${payload.tag.tag_id}`,
      data: {
        project_id: payload.projectId,
        tag_type: payload.tag.tag_type,
        name: payload.tag.name,
        color: payload.tag.color,
        units: payload.tag.unit,
        object_subtype: payload.tag.object_subtype,
        object_type: payload.tag.object_type
      }
    })

    const tag = response.data
    commit('EDIT_TAG', tag)
    commit('SET_ALERT', {type: 'success', text: `Tag added`}, {root: true})
  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't add tag`}, {root: true})
  }
}

export const fetchAllComponents = async ({commit}) => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST}/components`
    })

    commit('SET_ALL_COMPONENTS', response.data)
  } catch (e) {
    console.log(e)
  }
}

export const addComponent = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'POST',
      url: `${HOST}/project/${payload.projectId}/components/${payload.name}`
    })

    const components = response.data
    commit('SET_COMPONENTS', components)

  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't add component`}, {root: true})
  }
}

export const removeComponent = async ({commit}, payload) => {
  try {
    const response = await API({
      method: 'DELETE',
      url: `${HOST}/project/${payload.projectId}/components/${payload.name}`
    })

    const components = response.data
    commit('SET_COMPONENTS', components)

  } catch (e) {
    commit('SET_ALERT', {type: 'error', text: `Can't remove component`}, {root: true})
  }
}
