<script setup lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'

import {formatTime} from '@/utils/datetime.js'
import { MoreHorizontal } from 'lucide-vue-next'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { useCheckOrgAccess } from '@/composables/useCheckAccess'

import type { OrgMember } from '@/types'
import MembersListEditDialog from './MembersListEditDialog.vue'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const org = computed(() => store.getters['org/getCurrent'])
const props = defineProps<{
  member: OrgMember
}>()

const createdAt = computed(() => formatTime(props.member.create_dttm, settings.value.tz, 'D MMMM YYYY'))

const remove = async (ev: any) => {
  await store.dispatch('org/deleteMember', {
    orgId: org.value.organization_id,
    orgMemberId: props.member.organization_member_id
  })
}

</script>

<template>
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <span class="sr-only">Open</span>
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuItem>Member since: {{createdAt}}</DropdownMenuItem>
      <DropdownMenuSeparator />
      <MembersListEditDialog
        v-if="useCheckOrgAccess(org, 'org_member', ['update'])"
        :member="member"
        :org="org">
      </MembersListEditDialog>
      <DropdownMenuItem
        v-if="useCheckOrgAccess(org, 'org_member', ['delete'])"
        @click="remove"
        class="text-red-500">Delete</DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
