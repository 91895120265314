<script setup>
import { onMounted, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  map: {
    type: Object,
    required: true
  }
})

const store = useStore()
const isLoggedIn = computed(() => store.getters.getIsLoggedIn)
const layersToken = computed(() => store.getters['geo/getTilesToken'])
const layers = computed(() => store.getters['geo/getLayers'])

onMounted(async () => {
  const protocol = location.protocol === 'https:' ? 'https' : 'http'
  const host = `${protocol}://${window.location.host}`
  const baseSources = {
    'osm-tiles-source': {
      'type': 'raster',
      'tiles': ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
      'tileSize': 256,
      'attribution':
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    },
    'osmtopo-tiles-source': {
      'type': 'raster',
      'tiles': ['https://tile.opentopomap.org/{z}/{x}/{y}.png'],
      'tileSize': 256,
      'attribution':
        'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
        '<a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">' +
        'OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    },
    'mapbox-default-source': {
      'type': 'vector',
      'url': 'mapbox://mapbox.mapbox-streets-v8'
    }
  }

  const baseLayers = [
    {
      'id': 'osm-tiles-layer',
      'type': 'raster',
      'source': 'osm-tiles-source',
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    },
    {
      'id': 'osmtopo-tiles-layer',
      'type': 'raster',
      'source': 'osmtopo-tiles-source',
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    },
  ]

  if (isLoggedIn.value) {
    await store.dispatch('geo/fetchLayersToken')
    await store.dispatch('geo/fetchLayers')
  }

  // set planet tiles
  layers.value?.globals.forEach(layer => {
    baseSources[`planet-${layer.name}-source`] = {
      'type': 'raster',
      'tiles': [`${host}/tulum/tiles/1/${layer.name}/{z}/{x}/{y}.png?token=${layersToken.value}`],
      'tileSize': 256,
      'attribution': '&copy; <a href="https://www.planet.com/">Planet</a>'
    }

    baseLayers.push({
      'id': `planet-${layer.name}-layer`,
      'type': 'raster',
      'source': `planet-${layer.name}-source`,
      'minzoom': 0,
      'maxzoom': 18,
      'layout': {
        'visibility': 'none'
      }
    })
  })

  for (let source in baseSources) {
    if (!props.map.getSource(source)) {
      props.map.addSource(source, baseSources[source])
    }
  }

  for (let layer of baseLayers) {
    if (!props.map.getLayer(layer.id)) {
      props.map.addLayer(layer)
    }
  }
})
</script>

<template>

</template>
