<script setup lang="ts">
import { ref, computed } from 'vue'

import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

import { MoreHorizontal } from 'lucide-vue-next'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { DialogEditMember } from '@/components/dialogs'
import { useCheckProjectAccess } from '@/composables/useCheckAccess'

import type { Member } from '@/types'

const store = useStore()
const router = useRouter()
const route = useRoute()
const projectId = computed(() => route.params.project_id)
const project = computed(() => store.getters['project/current'])
const currentMember = computed(() => store.getters['user/getMember'])

const props = defineProps<{
  member: Member
}>()
const isCurrentUser = computed(() => currentMember.value.member_id === props.member.member_id)

const dialogEditMember = ref(null as any)

const edit = (ev: any) => {
  dialogEditMember.value.open(props.member, true)
}

const remove = async () => {
  await store.dispatch('project/deleteMember', {
    projectId: projectId.value,
    memberId: props.member.project_member_id
  })

  if (isCurrentUser) {
    store.dispatch('project/unlistProject', project)
    router.push({ name: 'Dashboard' })
  }
}
</script>

<template>
  <DialogEditMember ref="dialogEditMember" :member="props.member" editMode />
  <DropdownMenu>
    <DropdownMenuTrigger as-child>
      <Button variant="ghost" class="w-8 h-8 p-0">
        <span class="sr-only">Open menu</span>
        <MoreHorizontal class="w-4 h-4" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end">
      <DropdownMenuLabel>Actions</DropdownMenuLabel>
      <DropdownMenuItem @click="edit">Edit</DropdownMenuItem>

      <DropdownMenuItem
          v-if="useCheckProjectAccess(project, 'project_member', ['delete']) || isCurrentUser"
          @click="remove" class="text-red-700">{{ isCurrentUser ? 'Leave' : 'Delete' }}</DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>
