<script setup lang="ts">
import {computed, onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import {useRoute} from 'vue-router'

import MembersList from './MembersList.vue'
import InvitesList from './InvitesList.vue'
import MembersListInviteDialog from './MembersListInviteDialog.vue'
import { useCheckOrgAccess } from '@/composables/useCheckAccess'

const store = useStore()
const route = useRoute()

const orgId = route.params.org_id
const org = computed(() => store.getters['org/getCurrent'])
const members = computed(() => store.getters['org/getCurrentMembers'])
const invites = computed(() => store.getters['org/getCurrentInvites'])

const membersLength = computed(() => members.value.length)
const invitesLength = computed(() => invites.value.length)

onBeforeMount(async () => {
  await store.dispatch('org/fetchOrgMembers', orgId)
  await store.dispatch('org/fetchOrgInvites', orgId)
})
</script>

<template>
  <div v-if="org">
    <div class="mb-1 flex items-center">
      <div class="basis-1/2">
        <h3 class="text-base font-bold">Members ({{membersLength}})</h3>
      </div>
      <div class="basis-1/2 flex justify-end" v-if="useCheckOrgAccess(org, 'org_member', ['create'])">
        <MembersListInviteDialog :org="org" />
      </div>
    </div>

    <MembersList v-if="membersLength" :members="members" :org="org" :key="members" />

    <div class="mt-6 mb-2 flex items-center">
      <div class="basis-1/2">
        <h3 class="text-base font-bold">Pending invites ({{invitesLength}})</h3>
      </div>
    </div>

    <InvitesList v-if="invitesLength" :invites="invites" :org="org" :key="invites" />
  </div>
</template>
