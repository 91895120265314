<script setup lang="ts">
import { h } from 'vue'
import { DataTable } from '@/components/ui/data-table'
import type { ColumnDef } from '@tanstack/vue-table'
import { ReportData } from '@/types'
import {Check} from 'lucide-vue-next'

const props = defineProps<{
  data: ReportData[]
}>()

const columns: ColumnDef<ReportData>[] = [
  {
    accessorKey: 'key',
    header: 'Label'
  },
  {
    accessorKey: 'value',
    header: 'Value',
    cell: ({row}) => {
      if (row.original.type === 2) {
        return h('div', {class: 'capitalize text-gray-500'}, !!row.getValue('value') ? h(Check, {class: 'w-4 h-4'}) : '')
      }

      return h('div', {class: 'capitalize text-gray-500'}, row.getValue('value'))
    },
  },
  // {
  //   accessorKey: 'type',
  //   header: 'Type',
  //   cell: ({row}) => {
  //     return h('div', {class: 'capitalize text-gray-500'}, row.getValue('type'))
  //   },
  // },
]

</script>

<template>
  <DataTable :columns="columns" :data="data" />
</template>
