<script setup>
import {computed, onMounted, ref} from 'vue'
import {useStore} from 'vuex'
import {formatTime} from "@/utils/datetime"
import {API, HOST_TULUM} from '@/utils/http'

import {bbox} from '@turf/turf'
import {CheckCircle2, XCircle} from 'lucide-vue-next'
import {Trash2, Eye, EyeClosed, ChartSpline, Info} from 'lucide-vue-next'

import { LayerChart, LayerGeometry } from '@/components/layers'
import {Spinner} from '@/components/ui/spinner'
import {Switch} from '@/components/ui/switch'
import {Button} from '@/components/ui/button'
import {DialogConfirm} from '@/components/dialogs'


const store = useStore()
const settings = computed(() => store.getters['user/getUserSettings'])
const map = computed(() => store.getters['geo/getMap'])

const showGeometry = ref(false)
const showChart = ref(false)
const showInfo = ref(false)
const results = ref([])
const activeResult = ref(null)


const props = defineProps({
  layer: {
    type: Object,
    required: true
  },
  feature: {
    type: Object,
    required: true
  }
})

const protocol = location.protocol === 'https:' ? 'https' : 'http'
const host = `${protocol}://${window.location.host}`

const statusToLabel = {
  '-9': 'Preparing Data (Failed)',
  '-8': 'Cleaning Up (Failed)',
  '-7': 'Uploading Tiles (Failed)',
  '-6': 'Generating Tiles (Failed)',
  '-5': 'Merging Quads (Failed)',
  '-4': 'Uploading Data (Failed)',
  '-3': 'Delivering Data (Failed)',
  '-2': 'Ordering Data (Failed)',
  '-1': 'Starting (Failed)',
  '0': 'Done',
  '1': 'Starting',
  '2': 'Ordering Data',
  '3': 'Delivering Data',
  '4': 'Uploading Data',
  '5': 'Merging Quads',
  '6': 'Generating Tiles',
  '7': 'Uploading Tiles',
  '8': 'Cleaning Up',
  '9': 'Preparing Data'
}


const typeToLabel = {
  'forest_carbon_diligence_30m': 'Forest Carbon Diligence 30m',
  'forest_carbon_diligence_3m': 'Forest Carbon Diligence 3m'
}

const idToSource = {
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_30': 'Aboveground Carbon Density 30m (v1.0.0)',
  'CANOPY_HEIGHT_v1.0.0_30': 'Canopy Height 30m (v1.0.0)',
  'CANOPY_COVER_v1.0.0_30': 'Canopy Cover  30m (v1.0.0)',
  'ABOVEGROUND_CARBON_DENSITY_v1.0.0_3': 'Aboveground Carbon Density 3m (v1.0.0)',
  'CANOPY_HEIGHT_v1.0.0_3': 'Canopy Height 3m (v1.0.0)',
  'CANOPY_COVER_v1.0.0_3': 'Canopy Cover  3m (v1.0.0)'
}

const isTileActive = ref(false)
const tileLayer = {
  status: false,
  name: 'Skysat Demo',
  map: null // add mapbox tile layer
}

const fetchLayerImages = async () => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}/subscription/images`
    })
    return response.data
  } catch (e) {
    console.error(e)
  }
}

const toggleLayer = async () => {
  const images = await fetchLayerImages()
}

const deleteLayer = async () => {
  try {
    const response = await API({
      method: 'DELETE',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}`
    })

    store.commit('planet/DELETE_LAYER', props.layer.id)
  } catch (e) {
    console.error(e)
  }
}

const fetchResults = async () => {
  try {
    const response = await API({
      method: 'GET',
      url: `${HOST_TULUM}/tasks/layers/${props.layer.uuid}/subscription/result`
    })
    return response.data.reverse()
  } catch (e) {
    console.error(e)
  }
}

const toggleInfo = () => {
  showInfo.value = !showInfo.value
}

const toggleChart = () => {
  showChart.value = !showChart.value

  if (showChart.value) {
    activeResult.value = results.value[0]
  }
}

const toggleGeometry = () => {
  showGeometry.value = !showGeometry.value

  if (showGeometry.value) {
    const bounds = bbox(props.feature)
    map.value.fitBounds(bounds, {
      padding: 100,
      duration: 100
    })
  }
}

onMounted(async () => {
  if (props.layer.status !== 0) return
  results.value = await fetchResults()
  if (!results.value?.length) return
  results.value = results.value.map((i, index) => {
    return {
      ...i,
      s3_url: `https://planet-basemaps.s3.us-west-2.amazonaws.com/layer-${i.id}.png`
    }
  })
  activeResult.value = results.value[0]
})
</script>

<template>
  <div class="py-1 px-2 border-b border-gray-200">
    <div class="flex justify-between items-center">
      <div>
        <h3 class="font-bold">{{layer.name}}</h3>
        <!-- <h3 class="font-bold">{{idToSource[layer.source_id]}}</h3> -->
        <!-- <p class="text-gray-500 mb-2">{{typeToLabel[layer.source_type]}}</p> -->
      </div>
      <div class="h-6">
        <Button size="sm" variant="ghost" class="w-6 h-6 p-1" @click="toggleInfo">
          <Info :class="{'text-primary': showInfo, 'text-gray-500': !showInfo}" />
        </Button>
        <Button :disabled="!results?.length" size="sm" variant="ghost" class="w-6 h-6 p-1" @click="toggleChart">
          <ChartSpline :class="{'text-primary': showChart, 'text-gray-500': !showChart}" />
        </Button>
        <Button :disabled="!results?.length" size="sm" variant="ghost" class="w-6 h-6 p-1" @click="toggleGeometry">
          <EyeClosed class="text-gray-500" v-if="!showGeometry" />
          <Eye class="text-primary" v-else />
        </Button>
        <DialogConfirm
          ref="dialogDeleteConfirm"
          @confirm="deleteLayer"
          title="Delete layer"
          description="Are you sure you want to delete this layer?"
        >
          <Button size="sm" variant="ghost" class="w-6 h-6 p-1">
            <Trash2 class="text-red-400" />
          </Button>
        </DialogConfirm>
      </div>
    </div>

    <div>
      <div v-if="showInfo" class="border border-gray-200 rounded-sm py-1 px-2 my-1">
        <p class="text-gray-500">{{idToSource[layer.source_id]}}</p>
        <div class="flex items-center my-1">
          <div class="mr-2">
            <CheckCircle2 v-if="layer.status === 0" class="text-green-600 w-5 h-5" />
            <XCircle v-else-if="parseInt(layer.status) < 0" class="text-red-400 w-5 h-5" />
            <Spinner v-else class="w-5 h-5" />
          </div>
          <h4>{{ statusToLabel[layer.status] }}</h4>
        </div>

        <p class="text-gray-500 mb-0">{{formatTime(layer.created_at, settings.tz, 'D MMMM YYYY HH:mm')}}</p>
      </div>

      <div v-if="showGeometry" class="border border-gray-200 rounded-sm py-1 px-2 my-1">
        <LayerGeometry
          v-if="layer.status === 0 && results.length"
          :feature="feature"
          :key="feature.properties.code"
          :results="results"
          :activeResult="activeResult"
        />
      </div>

      <div v-if="showChart" class="border border-gray-200 rounded-sm my-1">
        <LayerChart
          v-if="layer.status === 0 && results.length"
          :layer="layer"
          :feature="feature"
          :key="feature.properties.code"
          :results="results"
          @update:activeResult="(value) => { if (activeResult !== value) activeResult = value }"
        />
      </div>
    </div>
    <!-- <div class="text-gray-500">Layer ID:{{ layer.uuid }}</div>
    <div class="text-gray-500">Action ID:{{ layer.action_id }}</div> -->
  </div>
</template>
